import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { WarningRadioButton } from '@shared/icons'

const useStyles = tss
  .withName('InactiveTemplateBanner')
  .create(({ theme }) => ({
    container: {
      border: `solid 1px ${theme.palette['power-orange']}`,
      padding: '20px',
      display: 'flex',
      backgroundColor: 'white',
      borderRadius: '6px',
      marginBottom: '24px',
      alignItems: 'center',
    },
    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      marginRight: '16px',
    },
    title: {
      fontFamily: 'Inter',
      fontSize: '16px',
      fontWeight: '600',
      lineHeight: '24px',
      color: theme.palette['neutral-700'],
    },
    description: {
      fontFamily: 'Inter',
      fontSize: '14px',
      lineHeight: '20px',
      color: theme.palette['neutral-700'],
    },
  }))

export const InactiveTemplateBanner: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>
        <WarningRadioButton sx={{ height: '32px', width: '32px' }} />
      </Box>
      <Box>
        <Typography className={classes.title}>
          {t('partner-portal.application-templates.this-template-is-inactive')}
        </Typography>
        <Typography className={classes.description}>
          {t(
            'partner-portal.application-templates.this-templates-are-inactive'
          )}
        </Typography>
      </Box>
    </Box>
  )
}
