import { get } from 'lodash'
import { FC, useCallback, useEffect, useState } from 'react'
import { Controller, Path, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, SelectChangeEvent } from '@mui/material'

import { api, ApplicationTemplate, Equipment } from '@shared/api/src'
import {
  CheckboxGroup,
  Input,
  Loading,
  RadioButtons,
  SelectComponent as Select,
  SelectOption,
} from '@shared/components'
import { PubSubEvent, usePub } from '@shared/hooks'

import { ArrayFieldDirty, getFieldsOptions } from './constants/fieldsOptions'
import { buildSchema } from './utils/buildSchema'
import { canDisplayField } from './utils/canDisplayField'

const useStyles = tss.withName('ProductForm').create(({ theme }) => ({
  formContainer: {
    height: '83vh',
    overflow: 'auto',
    paddingBottom: '100px',
  },
  form: {
    display: 'grid',
    gap: '24px 0',
    padding: '24px',
  },
  additionalSettingsCheckboxGroup: {
    paddingBottom: '36px',
  },
}))

export type SelectedModel = {
  label: string
  value: number
  fe_platform_codes: number[]
  conditional_fields: string[]
  integrated_system_values: {
    label: string
    value: number
  }[]
}

export const PRODUCT_FORM_MODEL_CHANGE = new Event(
  'PRODUCT_FORM_MODEL_CHANGE'
) as PubSubEvent<SelectedModel | null>

export const CUSTOM_DIRTY_CHANGE = new Event(
  'CUSTOM_DIRTY_CHANGE'
) as PubSubEvent<ArrayFieldDirty>

export interface ProductFormProps {
  template: ApplicationTemplate
  currentFrontend?: Equipment['fe_platform_code']
  isEdit?: boolean
}

export const ProductForm: FC<ProductFormProps> = ({
  template,
  currentFrontend,
  isEdit = false,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  const [productTypeOptions, setProductTypeOptions] = useState<
    SelectOption<string>[]
  >([])
  const [modelOptions, setModelOptions] = useState<SelectOption<string>[]>([])

  const [frontEndPlatforms, setFrontEndPlatforms] = useState<
    SelectOption<string>[]
  >([])

  // Temporary way to understand when some fields are handled by an array of strings are dirty.
  // During the edit process, we consider that all are dirty by default.
  // Will be used to trigger errors, in order to make sure the user has selected a value for each of this fields.
  // If we use group of checkboxes in the future to handle this, we can remove this approach.
  const [customDirty, setCustomDirty] = useState<ArrayFieldDirty>({
    fluidpaySettings: {
      receiptPrinting: isEdit,
      signatureCapture: isEdit,
      mobilePawn: isEdit,
      debitOnlyPawn: isEdit,
    },
    additionalSettings: {
      fortisGateway: isEdit,
    },
  })

  const [allModels, setAllModels] = useState<
    Map<number, SelectOption<string>[]>
  >(new Map())

  const [isLoading, setIsLoading] = useState<boolean>(false)

  const [selectedModel, setSelectedModel] = useState<SelectedModel>()

  const {
    control,
    watch,
    setValue,
    setError,
    clearErrors,
    formState: { errors },
  } = useFormContext<Equipment>()

  const formData = watch() as Partial<Equipment>

  const publish = usePub()

  const displayFields = canDisplayField(
    formData as Equipment,
    selectedModel?.conditional_fields as string[],
    template
  )

  const schema = buildSchema(t, selectedModel, template)

  const initialSetup = async () => {
    try {
      setIsLoading(true)

      const [platforms, productTypes] = await Promise.all([
        api.service('platforms').find({
          query: {
            agent_id: template?.agent_id,
          },
        }),
        api.service('templates').getAvailableEquipment(template?.template_id),
      ])

      // This approach allows us to build the options only once.
      const allModelsMap = new Map<number, SelectOption<string>[]>()

      const availableProductTypes = productTypes
        .map((type) => {
          let productModels: SelectOption<string>[] = []
          type.models.forEach((model) => {
            if (template?.application_type_code !== 2) {
              const hasAvailablePlatform = model.fe_platform_codes.some(
                (code) =>
                  platforms.some(
                    (platform) => platform.platform_code === code
                  ) && currentFrontend
                    ? currentFrontend === code
                    : true
              )
              if (!hasAvailablePlatform) {
                return
              }
            }
            const modelOption = {
              label: model.name,
              value: model.model_id,
              fe_platform_codes: model.fe_platform_codes,
              conditional_fields: model.conditional_fields,
              integrated_system_values: model.integrated_system_values
                .sort((a, b) => a.sort_order - b.sort_order)
                .map((system) => ({
                  label: system.description,
                  value: system.value,
                })),
            }
            productModels.push(modelOption)
          })
          if (productModels.length > 0) {
            productModels = productModels.sort((a, b) =>
              a.label.toString().localeCompare(b.label.toString())
            )
            allModelsMap.set(type.equipment_type_id, productModels)
            return {
              label: type.description,
              value: type.equipment_type_id,
              sortOrder: type.sort_order,
            }
          }
        })
        .filter(Boolean)
        .sort((a, b) => a.sortOrder - b.sortOrder)

      const platformOptions = platforms
        .map((platform: { platform_code: number; description: string }) => ({
          label: platform.description,
          value: String(platform.platform_code),
        }))
        .sort((a, b) => a.label.localeCompare(b.label))

      setFrontEndPlatforms(platformOptions)
      setAllModels(allModelsMap)
      setProductTypeOptions(availableProductTypes)
    } catch (error) {
      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (!template?.template_id) {
      return
    }
    initialSetup()
  }, [template?.template_id])

  useEffect(() => {
    const productType = formData.equipment_type_id
    if (!productType || allModels.size === 0) {
      return
    }

    const models = allModels.get(productType)

    if (models.findIndex((model) => model.value === formData.model_id) === -1) {
      setSelectedModel(undefined)
      setValue('model_id', null)
      setError('model_id', undefined)
    }

    setModelOptions(models || [])
  }, [formData.equipment_type_id, allModels])

  useEffect(() => {
    if (modelOptions.length === 0) {
      return
    }
    const model = modelOptions.find(
      (model) => model.value === formData.model_id
    ) as SelectedModel

    if (model) {
      setSelectedModel(model)
      clearValues(model)
      publish(PRODUCT_FORM_MODEL_CHANGE, model)
      clearErrors()
    }

    if (currentFrontend && model?.fe_platform_codes.includes(currentFrontend)) {
      setValue('fe_platform_code', currentFrontend)
    } else if (
      formData.fe_platform_code &&
      !model?.fe_platform_codes.includes(formData.fe_platform_code)
    ) {
      setValue('fe_platform_code', null)
    }
  }, [formData.model_id, currentFrontend, modelOptions])

  const clearValues = (model: SelectedModel = selectedModel) => {
    const display = canDisplayField(
      formData as Equipment,
      model?.conditional_fields
    )

    const allKeys = []
    Object.keys(schema.fields).forEach((key) => {
      if (key.includes('conditional_settings')) {
        allKeys.push(
          ...Object.keys(schema.fields[key].fields).map((f) => `${key}.${f}`)
        )
      } else {
        allKeys.push(key)
      }
    })

    allKeys.forEach((key) => {
      if (!get(display, key)) {
        setValue(key as Path<Equipment>, undefined)
        if (errors[key] !== undefined)
          setError(key as Path<Equipment>, undefined)
      }
    })
  }

  const onChangeField = useCallback(
    (
      field: Path<Equipment>,
      value:
        | Exclude<Equipment[keyof Equipment], 'conditional_settings'>
        | Equipment['conditional_settings'][keyof Equipment['conditional_settings']]
    ) => {
      setValue(field, value, { shouldDirty: true, shouldValidate: true })
      if (errors[field] !== undefined) setError(field, undefined)

      clearValues()
    },
    [displayFields]
  )

  const clearRadioButtons = () => {
    setValue('supplier', undefined)
    setValue('ownership_code', undefined)
  }

  useEffect(() => {
    // Dirty fields needs to be published to the EditProduct and AddProduct in order to be used by the yup validator.
    publish(CUSTOM_DIRTY_CHANGE, customDirty)
  }, [customDirty])

  const {
    integratedSystemsSelectOptions,
    cardTypesAcceptedCheckboxItems,
    additionalSettingsCheckboxItems,
    productProviderRadioButtons,
    ownershipTypeRadioButtons,
    settingUpFortisGatewayRadioButtons,
    orderingFpayBdogEquipmentRadioButtons,
    isBravoPOSSetupRadioButtons,
    receiptPrintingFromBravoRadioButtons,
    signatureCaptureOnTerminalRadioButtons,
    mobilePawnAndOrBuyRadioButtons,
    debitOnlyOnLoansInMobilePawnRadioButtons,
    mobileNormalPawnTransactionTypesRadioButtons,
    integratedSetupRadioButtons,
    setupTypeRadioButtons,
  } = getFieldsOptions({
    t,
    onChangeField,
    formData,
    customDirty,
    setCustomDirty,
  })

  const defaultCustomDirty = () =>
    setCustomDirty({
      fluidpaySettings: {
        mobilePawn: false,
        signatureCapture: false,
        receiptPrinting: false,
        debitOnlyPawn: false,
      },
      additionalSettings: {
        fortisGateway: false,
      },
    })

  if (isLoading) {
    return (
      <Loading
        style={{
          maxHeight: '200px',
        }}
      />
    )
  }

  const filteredFrontEndPlatforms = frontEndPlatforms.filter(
    (platform) =>
      (selectedModel?.fe_platform_codes as number[])?.includes(
        parseInt(platform.value as string)
      ) && (currentFrontend ? currentFrontend !== platform.value : true)
  )

  return (
    <Box className={classes.formContainer}>
      <form className={classes.form}>
        {displayFields.equipment_type_id && (
          <Controller
            name="equipment_type_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(event: SelectChangeEvent) => {
                  field.onChange(event)
                  defaultCustomDirty()
                  clearRadioButtons()
                }}
                testId="product-type-selector"
                label={t('partner-portal.application-templates.product-type')}
                placeholder={t(
                  'partner-portal.application-templates.select-product-type'
                )}
                options={productTypeOptions}
                error={Boolean(errors.equipment_type_id?.message)}
                helperText={errors.equipment_type_id?.message}
                required
              />
            )}
          />
        )}

        {displayFields.model_id && (
          <Controller
            name="model_id"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                onChange={(event: SelectChangeEvent) => {
                  field.onChange(event)
                  defaultCustomDirty()
                  clearRadioButtons()
                }}
                testId="product-model-selector"
                label={t('partner-portal.application-templates.model')}
                placeholder={t(
                  'partner-portal.application-templates.select-product-model'
                )}
                options={modelOptions}
                error={Boolean(errors.model_id?.message)}
                helperText={errors.model_id?.message}
                required
              />
            )}
          />
        )}

        {displayFields.fe_platform_code && (
          <Controller
            name="fe_platform_code"
            control={control}
            render={({ field }) => (
              <Select
                {...field}
                testId="frontend-platform-selector"
                label={t(
                  'partner-portal.application-templates.front-end-platform'
                )}
                onChange={(event: SelectChangeEvent) => {
                  onChangeField('fe_platform_code', event.target.value)
                }}
                placeholder={t(
                  'partner-portal.application-templates.select-front-end-platform'
                )}
                options={filteredFrontEndPlatforms}
                error={Boolean(errors.fe_platform_code?.message)}
                helperText={
                  !!currentFrontend
                    ? t(
                        'partner-portal.application-templates.frontend-platform-set-by-default'
                      )
                    : errors.fe_platform_code?.message
                }
                required
                disabled={!!currentFrontend}
              />
            )}
          />
        )}

        {displayFields.conditional_settings?.var_pos_system && (
          <Controller
            name="conditional_settings.var_pos_system"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <Input
                {...field}
                testId="pos-system-name-input"
                label={t('partner-portal.application-templates.pos-system')}
                placeholder={t(
                  'partner-portal.application-templates.enter-pos-system-name'
                )}
                error={Boolean(
                  errors.conditional_settings?.var_pos_system?.message
                )}
                helperText={
                  errors.conditional_settings?.var_pos_system?.message
                }
              />
            )}
          />
        )}

        {displayFields.conditional_settings?.software && (
          <Controller
            name="conditional_settings.software"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <Input
                {...field}
                testId="software-name-input"
                label={t('partner-portal.application-templates.software')}
                placeholder={t(
                  'partner-portal.application-templates.enter-software-name'
                )}
                error={Boolean(errors.conditional_settings?.software?.message)}
                helperText={errors.conditional_settings?.software?.message}
              />
            )}
          />
        )}

        {displayFields.conditional_settings?.var_pos_name && (
          <Controller
            name="conditional_settings.var_pos_name"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <Input
                {...field}
                testId="equipment-name-input"
                label={t('partner-portal.application-templates.product-name')}
                onChange={(event) => {
                  onChangeField(
                    'conditional_settings.var_pos_name',
                    event.target.value
                  )
                }}
                placeholder={t(
                  'partner-portal.application-templates.enter-product-name'
                )}
                required
                error={Boolean(
                  errors.conditional_settings?.var_pos_name?.message
                )}
                helperText={errors.conditional_settings?.var_pos_name?.message}
              />
            )}
          />
        )}

        {displayFields.supplier && (
          <RadioButtons
            testId="product-provider-radio-buttons"
            label={t('partner-portal.application-templates.product-provider')}
            buttons={productProviderRadioButtons}
            error={Boolean(errors.supplier?.message)}
            helperText={errors.supplier?.message}
            required
          />
        )}

        {displayFields.ownership_code && (
          <RadioButtons
            testId="ownership-type-radio-buttons"
            label={t('partner-portal.application-templates.ownership-type')}
            buttons={ownershipTypeRadioButtons}
            error={Boolean(errors.ownership_code?.message)}
            helperText={errors.ownership_code?.message}
            required
          />
        )}

        {
          // Temporary fix for this models, logic not included in displayFields since it's not considered an independant field.
        }
        {displayFields.conditional_settings?.additional_settings &&
          selectedModel.label !== 'Zeamster' &&
          selectedModel.label !== 'Fortis Gateway' && (
            <RadioButtons
              testId="setting-up-fortis-gateway-radio-buttons"
              label={t(
                'partner-portal.application-templates.setting-up-fortis-gateway'
              )}
              buttons={settingUpFortisGatewayRadioButtons}
              required
              error={
                Boolean(errors.conditional_settings?.additional_settings) &&
                !customDirty.additionalSettings.fortisGateway
              }
              helperText={t(
                'partner-portal.application-templates.you-must-select-if-setting-up-fortis-gateway'
              )}
            />
          )}

        {displayFields.conditional_settings?.is_integrated && (
          <RadioButtons
            testId="integrated-setup-radio-buttons"
            label={t('partner-portal.application-templates.integrated-setup')}
            buttons={integratedSetupRadioButtons}
            error={Boolean(errors.conditional_settings?.is_integrated?.message)}
            helperText={errors.conditional_settings?.is_integrated?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.integrated_system && (
          <Controller
            name="conditional_settings.integrated_system"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <Select
                {...field}
                testId="integrated-system-selector"
                label={t(
                  'partner-portal.application-templates.integrated-system'
                )}
                onChange={(event: SelectChangeEvent) => {
                  onChangeField(
                    'conditional_settings.integrated_system',
                    event.target.value
                  )
                }}
                placeholder={t(
                  'partner-portal.application-templates.select-integrated-system'
                )}
                options={selectedModel.integrated_system_values}
                error={Boolean(
                  errors.conditional_settings?.integrated_system?.message
                )}
                helperText={
                  errors.conditional_settings?.integrated_system?.message
                }
                required
              />
            )}
          />
        )}

        {
          // Temporary removed for Payment Cloud, we should have a different approach from the API for this.
        }
        {displayFields.conditional_settings?.additional_settings &&
          selectedModel.label !== 'Payment Cloud' && (
            <Controller
              name="conditional_settings.additional_settings"
              control={control}
              shouldUnregister
              render={({ field }) => (
                <CheckboxGroup<any>
                  testId="additional-settings-checkbox-group"
                  label={t(
                    'partner-portal.application-templates.additional-settings'
                  )}
                  field={field}
                  items={additionalSettingsCheckboxItems}
                  checkboxGroupClassName={
                    classes.additionalSettingsCheckboxGroup
                  }
                  checkboxStyle={{ flex: '50%' }}
                  onChange={(additionalSettings) =>
                    onChangeField(
                      'conditional_settings.additional_settings',
                      additionalSettings
                    )
                  }
                  error={Boolean(
                    errors.conditional_settings?.additional_settings?.message
                  )}
                  helperText={
                    errors.conditional_settings?.additional_settings?.message
                  }
                />
              )}
            />
          )}

        {displayFields.conditional_settings?.is_ordering && (
          <RadioButtons
            testId="ordering-fluidpay-bluedog-equipment-radio-buttons"
            label={t(
              'partner-portal.application-templates.ordering-fluidpay-bluedog-product'
            )}
            buttons={orderingFpayBdogEquipmentRadioButtons}
            error={Boolean(errors.conditional_settings?.is_ordering?.message)}
            helperText={errors.conditional_settings?.is_ordering?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.is_bravo_pos && (
          <RadioButtons
            testId="is-bravo-pos-setup-radio-buttons"
            label={t(
              'partner-portal.application-templates.is-this-a-bravo-post-setup'
            )}
            buttons={isBravoPOSSetupRadioButtons}
            error={Boolean(errors.conditional_settings?.is_bravo_pos?.message)}
            helperText={errors.conditional_settings?.is_bravo_pos?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.fluidpay_settings && (
          <RadioButtons
            testId="receipt-printing-from-bravo-radio-buttons"
            label={t(
              'partner-portal.application-templates.receipt-printing-from-bravo-with-signature-line'
            )}
            buttons={receiptPrintingFromBravoRadioButtons}
            error={
              Boolean(
                errors.conditional_settings?.fluidpay_settings?.message
              ) && !customDirty.fluidpaySettings.receiptPrinting
            }
            helperText={errors.conditional_settings?.fluidpay_settings?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.fluidpay_settings && (
          <Box>
            <RadioButtons
              testId="signature-capture-on-terminal-radio-buttons"
              label={t(
                'partner-portal.application-templates.signature-capture-on-terminal'
              )}
              bottomDescription={t(
                'partner-portal.application-templates.signature-does-not-print-to-receipt'
              )}
              buttons={signatureCaptureOnTerminalRadioButtons}
              error={
                Boolean(
                  errors.conditional_settings?.fluidpay_settings?.message
                ) && !customDirty.fluidpaySettings.signatureCapture
              }
              helperText={
                errors.conditional_settings?.fluidpay_settings?.message
              }
              required
            />
          </Box>
        )}

        {displayFields.conditional_settings?.fluidpay_settings && (
          <RadioButtons
            testId="mobilepawn-and-or-buy-radio-buttons"
            label={t(
              'partner-portal.application-templates.mobile-pawn-and-or-buy'
            )}
            buttons={mobilePawnAndOrBuyRadioButtons}
            error={
              Boolean(
                errors.conditional_settings?.fluidpay_settings?.message
              ) && !customDirty.fluidpaySettings.mobilePawn
            }
            helperText={errors.conditional_settings?.fluidpay_settings?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.fluidpay_settings && (
          <RadioButtons
            testId="debit-only-on-loans-in-mobilepawn-radio-buttons"
            label={t(
              'partner-portal.application-templates.debit-only-on-loans-in-mobilepawn'
            )}
            buttons={debitOnlyOnLoansInMobilePawnRadioButtons}
            error={
              Boolean(
                errors.conditional_settings?.fluidpay_settings?.message
              ) && !customDirty.fluidpaySettings.debitOnlyPawn
            }
            helperText={errors.conditional_settings?.fluidpay_settings?.message}
            required
          />
        )}

        {displayFields.conditional_settings?.transaction_type_code && (
          <RadioButtons
            testId="mobile-normal-pawn-transaction-types-radio-buttons"
            label={t(
              'partner-portal.application-templates.transaction-types-accepted-for-mobile-pawn-and-normal-pawn'
            )}
            buttons={mobileNormalPawnTransactionTypesRadioButtons}
            error={Boolean(
              errors.conditional_settings?.transaction_type_code?.message
            )}
            helperText={
              errors.conditional_settings?.transaction_type_code?.message
            }
            required
          />
        )}

        {displayFields.conditional_settings?.accepted_cards && (
          <Controller
            name="conditional_settings.accepted_cards"
            control={control}
            shouldUnregister
            render={({ field }) => (
              <CheckboxGroup<any>
                testId="card-types-accepted-checkbox-group"
                label={t(
                  'partner-portal.application-templates.card-types-accepted'
                )}
                field={field}
                items={cardTypesAcceptedCheckboxItems}
                checkboxGroupClassName={classes.additionalSettingsCheckboxGroup}
                checkboxStyle={{ flex: '50%' }}
                error={Boolean(
                  errors.conditional_settings?.accepted_cards?.message
                )}
                onChange={(acceptedCards) =>
                  onChangeField(
                    'conditional_settings.accepted_cards',
                    acceptedCards
                  )
                }
                helperText={
                  errors.conditional_settings?.accepted_cards?.message
                }
              />
            )}
          />
        )}

        {displayFields.conditional_settings?.simple_swipe_setup_type_code && (
          <RadioButtons
            testId="setup-type-radio-buttons"
            label={t('partner-portal.application-templates.setup-type')}
            buttons={setupTypeRadioButtons}
            error={Boolean(
              errors.conditional_settings?.simple_swipe_setup_type_code?.message
            )}
            helperText={
              errors.conditional_settings?.simple_swipe_setup_type_code?.message
            }
            required
          />
        )}
      </form>
    </Box>
  )
}
