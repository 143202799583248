import { SelectOption } from '@shared/components'

export enum InputTypes {
  PERCENT = 1,
  DOLLARS = 2,
  PASSTHROUGH = 3,
}

export interface Input {
  index: number
  label: string
  type: number
  value: number | string | undefined
  defaultValue?: number | string | undefined
  options?: SelectOption<string>[]
  terms_options?: SelectOption<string>[]
  editable: boolean
  precision?: number
  item_id: number
  item_term?: number
  overallIndex: number
}

export interface PricingPlanGroup {
  group_name: string
  group_id: number
  group_sort_order: number
  inputs: Input[]
}
