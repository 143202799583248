import SvgIcon from '@mui/material/SvgIcon'

const CubeIcon = ({ size = 32, color = '#D64123', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M28 21.3318V10.6678C28 9.71578 27.4907 8.83578 26.664 8.36378L17.32 3.01311C16.5027 2.54511 15.4987 2.54511 14.6813 3.01311L5.336 8.36378C4.50933 8.83711 4 9.71578 4 10.6678V21.3304C4 22.2824 4.50933 23.1624 5.336 23.6344L14.68 28.9851C15.4973 29.4531 16.5013 29.4531 17.3187 28.9851L26.6627 23.6344C27.4907 23.1624 28 22.2838 28 21.3318V21.3318Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0001 16L4.38672 22.7067"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 16.0001L27.6133 9.29346"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0007 15.9998V2.6665"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0007 16V29.3333"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0001 16.0001L4.38672 9.29346"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16 16L27.6133 22.7067"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default CubeIcon
