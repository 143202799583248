import SvgIcon from '@mui/material/SvgIcon'

const EditProductIcon = ({ size = 16, color = '#374151', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        style={{ width: `${size}px`, height: `${size}px` }}
      >
        <path
          d="M14 8.71166V12.6483C14 13.395 13.3993 14.0003 12.658 14.0003H3.342C2.60067 14.0003 2 13.395 2 12.6483V4.01899C2 3.27233 2.60067 2.66699 3.342 2.66699H8"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.21875 11.4482L7.70608 10.8262C7.82342 10.7968 7.93008 10.7362 8.01608 10.6508L13.6094 5.05749C14.1301 4.53683 14.1301 3.69283 13.6094 3.17216L13.4947 3.05749C12.9741 2.53683 12.1301 2.53683 11.6094 3.05749L6.01608 8.65083C5.93075 8.73616 5.87008 8.84349 5.84075 8.96083L5.21875 11.4482"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.5547 4.11328L12.5547 6.11328"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.69918 10.8279C7.70851 10.7573 7.72051 10.6873 7.72051 10.6139C7.72051 9.69327 6.97451 8.94727 6.05384 8.94727C5.98051 8.94727 5.91051 8.95993 5.83984 8.9686"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default EditProductIcon
