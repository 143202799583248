import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Box, Typography } from '@mui/material'

import { Equipment, Platform } from '@shared/api'
import { Button } from '@shared/components'

import { DeleteProductIcon, EditProductIcon } from '@/assets/icons'
import { getProductTypeIcon } from '@/utils/application-templates/getProductTypeIcon'
import { mapEquipmentType } from '@/utils/mapping'

const useStyles = tss.withName('ProductItem').create(({ theme }) => ({
  modelName: {
    color: '#374151',
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: '4px',
  },
  error: {
    fontFamily: 'Inter',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    marginBottom: '4px',
    color: theme.palette['negative'],
  },
  label: {
    color: '#374151',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '16px',
    marginRight: '4px',
  },
  value: {
    color: '#374151',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  listItem: {
    display: 'flex',
    padding: '12px 20px',
    justifyContent: 'space-between',
    alignItems: 'center',
    borderRadius: '6px',
    border: '1px solid #E5E7EB',
    background: '#FFFFFF',
    marginBottom: '8px',
    height: 'auto',
  },
  iconContainer: {
    display: 'flex',
    padding: '8px',
    alignItems: 'center',
    gap: '8px',
    borderRadius: '4px',
    background: '#D6412319',
  },
  detailsContainer: {
    flexGrow: 1,
    marginLeft: '16px',
  },
  bullet: {
    margin: '0 8px',
    color: '#9CA3AF',
    fontFamily: 'Inter',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
  },
  infoRow: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  actionsContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
  },
  smallButton: {
    width: '32px !important',
    height: '32px !important',
    padding: '0 !important',
    minWidth: 'unset !important',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
}))

interface ProductItemProps {
  product: Equipment
  onDelete: (product: Equipment) => void
  onEdit: (product: Equipment) => void
  frontendPlatform?: Platform
  backendPlatform?: Platform
  errors?: { [key: string]: string }
}

export const ProductItem = ({
  product,
  onDelete,
  onEdit,
  frontendPlatform,
  backendPlatform,
  errors,
}: ProductItemProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box key={product.equipment_id} className={classes.listItem}>
      <Box className={classes.iconContainer}>
        {getProductTypeIcon(product.equipment_type_id)}
      </Box>
      <Box className={classes.detailsContainer}>
        <Box className={classes.infoRow} gap="8px">
          <Typography className={classes.modelName}>
            {product.model_name}
          </Typography>
          {errors && (
            <Typography className={classes.error}>
              ({t('partner-portal.application-templates.missing-data')})
            </Typography>
          )}
        </Box>
        <Box className={classes.infoRow}>
          <Typography className={classes.label}>
            {t('partner-portal.application-templates.product-type')}:
          </Typography>
          <Typography className={classes.value}>
            {mapEquipmentType(product.equipment_type_id)}
          </Typography>
          <Typography className={classes.bullet}>•</Typography>
          <Typography className={classes.label}>
            {t('partner-portal.application-templates.front-end-platform')}:
          </Typography>
          <Typography className={classes.value}>
            {frontendPlatform?.description}
          </Typography>
          <Typography className={classes.bullet}>•</Typography>
          <Typography className={classes.label}>
            {t('partner-portal.application-templates.back-end-platform')}:
          </Typography>
          <Typography className={classes.value}>
            {backendPlatform?.description}
          </Typography>
        </Box>
      </Box>

      <Box className={classes.actionsContainer}>
        <Button
          icon={<EditProductIcon />}
          onClick={() => onEdit(product)}
          color="secondary"
          className={classes.smallButton}
          testId="edit-product-button"
        />
        <Button
          icon={<DeleteProductIcon />}
          onClick={() => onDelete(product)}
          color="secondary"
          className={classes.smallButton}
          testId="delete-product-button"
        />
      </Box>
    </Box>
  )
}
