import SvgIcon from '@mui/material/SvgIcon'

export const Copy = (props) => {
  return (
    <SvgIcon {...props}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M10.666 12H11.9993C12.7357 12 13.3327 11.403 13.3327 10.6667V3.33333C13.3327 2.59695 12.7357 2 11.9993 2H5.99935C5.26297 2 4.66602 2.59695 4.66602 3.33333V4.66667"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2389 6.31393L9.01908 5.09413C8.74559 4.82064 8.37465 4.66699 7.98788 4.66699H3.99935C3.26297 4.66699 2.66602 5.26395 2.66602 6.00033V12.667C2.66602 13.4034 3.26297 14.0003 3.99935 14.0003H9.33268C10.0691 14.0003 10.666 13.4034 10.666 12.667V7.34513C10.666 6.95835 10.5124 6.58742 10.2389 6.31393Z"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.666 7.66699H8.66602C8.11373 7.66699 7.66602 7.21928 7.66602 6.66699V4.66699"
          stroke="#374151"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}
