export const mapEquipmentType = (
  equipmentTypeId: number | undefined
): string => {
  switch (equipmentTypeId) {
    case 1:
      return 'Terminal'
    case 2:
      return 'Gateway'
    case 4:
      return 'PinPad'
    case 5:
      return 'Card Reader'
    case 16:
      return 'Software'
    default:
      return 'Unknown Type'
  }
}
