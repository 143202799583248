import { FC } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Grid, Typography } from '@mui/material'

import {
  Checkbox,
  CurrencyInput,
  FieldGroupContainer,
  FieldGroupRow,
  InputIntegerOnly,
} from '@shared/components'

import { getCCPaymentAndVelocityTotalPercent } from '@/utils/application-templates/getCCPaymentAndVelocityTotalPercent'

import { CCPaymentAndVelocityData } from '../cc-ach-payment-and-velocity-form/CCACHPaymentAndVelocityForm'

const useStyles = tss.withName('CCPaymentAndVelocityFields').create(() => ({
  fieldGroupContainer: {
    padding: '1em 1em .2em 1em',
    background: 'white',
    borderRadius: '.5em',
  },
}))

interface CCPaymentAndVelocityFieldsProps {
  isInvalidTotalPercent: boolean
}

export const CCPaymentAndVelocityFields: FC<
  CCPaymentAndVelocityFieldsProps
> = ({ isInvalidTotalPercent }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()

  const {
    control,
    watch,
    formState: { errors },
  } = useFormContext<CCPaymentAndVelocityData>()

  const { swiped_percent, keyed_percent, ecommerce_percent } = watch()

  return (
    <>
      <FieldGroupContainer
        title={t('partner-portal.application-templates.cc-payment-volume')}
        className={classes.fieldGroupContainer}
        style={{ padding: '1em 1em 1px 1em !important' }}
      >
        <Typography variant="body2" sx={{ margin: '-16px 0 24px 0' }}>
          {t(
            'partner-portal.application-details.provide-the-best-possible-estimates-of-your-expected-cc-activity'
          )}
        </Typography>

        <FieldGroupRow spacing={4}>
          <Grid item xs={8} md={3} marginBottom={2}>
            <Controller
              control={control}
              name="swiped_percent"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="swiped-percent-input"
                  label={t(
                    'partner-portal.application-templates.card-present-swiped'
                  )}
                  placeholder="0"
                  endAdornmentText={'%'}
                  error={!!errors.swiped_percent}
                  helperText={errors.swiped_percent?.message as string}
                />
              )}
            />
          </Grid>

          <Grid item xs={8} md={3} marginBottom={2}>
            <Controller
              control={control}
              name="keyed_percent"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="keyed-percent-input"
                  label={t(
                    'partner-portal.application-templates.card-not-present-keyed'
                  )}
                  placeholder="0"
                  endAdornmentText={'%'}
                  error={!!errors.keyed_percent}
                  helperText={errors.keyed_percent?.message as string}
                />
              )}
            />
          </Grid>

          <Grid item xs={8} md={3} marginBottom={2}>
            <Controller
              control={control}
              name="ecommerce_percent"
              render={({ field }) => (
                <InputIntegerOnly
                  {...field}
                  testId="ecommerce-percent-input"
                  label={t('partner-portal.application-templates.ecommerce')}
                  placeholder="0"
                  endAdornmentText={'%'}
                  error={!!errors.ecommerce_percent}
                  helperText={errors.ecommerce_percent?.message as string}
                />
              )}
            />
          </Grid>

          <Grid item xs={8} md={3} marginBottom={2}>
            <InputIntegerOnly
              testId="total-percent-input"
              label={t('partner-portal.application-templates.total')}
              endAdornmentText={'%'}
              value={getCCPaymentAndVelocityTotalPercent({
                swiped_percent,
                keyed_percent,
                ecommerce_percent,
              })}
              error={isInvalidTotalPercent}
              helperText={t(
                'partner-portal.application-templates.total-amount-must-be-100%'
              )}
              disabled
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>

      <FieldGroupContainer
        title={t('partner-portal.application-templates.cc-payment-values')}
        className={classes.fieldGroupContainer}
      >
        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="cc_monthly_volume"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="cc-monthly-volume-input"
                  label={t(
                    'partner-portal.application-templates.monthly-card-volume'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.cc_monthly_volume}
                  helperText={
                    errors.cc_monthly_volume
                      ? (errors.cc_monthly_volume?.message as string)
                      : t(
                          'partner-portal.application-templates.whole-dollar-amount-only'
                        )
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="cc_high_ticket"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="cc-high-ticket-input"
                  label={t(
                    'partner-portal.application-templates.high-transaction-amount'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.cc_high_ticket}
                  helperText={
                    errors.cc_high_ticket
                      ? (errors.cc_high_ticket?.message as string)
                      : t(
                          'partner-portal.application-templates.whole-dollar-amount-only'
                        )
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>

        <FieldGroupRow spacing={4}>
          <Grid item xs={12} md={6} marginBottom={2}>
            <Controller
              control={control}
              name="cc_average_ticket"
              render={({ field }) => (
                <CurrencyInput
                  {...field}
                  testId="cc-average-ticket-input"
                  label={t(
                    'partner-portal.application-templates.average-transaction-amount'
                  )}
                  currency={'USD'}
                  showDollarSign
                  allowDecimal={false}
                  error={!!errors.cc_average_ticket}
                  helperText={
                    errors.cc_average_ticket
                      ? (errors.cc_average_ticket?.message as string)
                      : t(
                          'partner-portal.application-templates.whole-dollar-amount-only'
                        )
                  }
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>

      <FieldGroupContainer
        title={t(
          'partner-portal.application-templates.select-below-if-setting-up-with-american-express-direct-discover-direct-and-or-pin-debit'
        )}
        className={classes.fieldGroupContainer}
      >
        <FieldGroupRow spacing={2}>
          <Grid item sx={{ marginRight: '3em' }}>
            <Controller
              name="addon_amex_direct"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-amex-direct-checkbox"
                  label={t('partner-portal.application-templates.amex-direct')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="addon_gateway"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-gateway-checkbox"
                  label={t('partner-portal.application-templates.gateway')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>

          <Grid item sx={{ marginRight: '3em' }}>
            <Controller
              name="addon_discover_direct"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-discover-direct-checkbox"
                  label={t(
                    'partner-portal.application-templates.discover-direct'
                  )}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="addon_clover"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-clover-checkbox"
                  label={t('partner-portal.application-templates.clover')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>

          <Grid item sx={{ marginRight: '3em' }}>
            <Controller
              name="addon_pin_debit"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-pin-debit-checkbox"
                  label={t('partner-portal.application-templates.pin-debit')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="addon_insights"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-insights-checkbox"
                  label={t('partner-portal.application-templates.insights')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Controller
              name="addon_wireless"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-wireless-checkbox"
                  label={t('partner-portal.application-templates.wireless')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />

            <Controller
              name="addon_payeezy"
              control={control}
              render={({ field }) => (
                <Checkbox
                  testId="addon-payeezy-checkbox"
                  label={t('partner-portal.application-templates.payeezy')}
                  checked={!!field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Grid>
        </FieldGroupRow>
      </FieldGroupContainer>
    </>
  )
}
