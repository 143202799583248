export const nullifyEmptyStrings = <T>(input: T): T => {
  try {
    return JSON.parse(JSON.stringify(input), (_, value) => {
      if (typeof value === 'string' && value.trim() === '') {
        return null
      } else {
        return value
      }
    })
  } catch (error) {
    console.error('Error nullifying empty strings:', error)
    return input
  }
}
