import { FC, useCallback } from 'react'
import {
  Controller,
  ControllerRenderProps,
  useFormContext,
  useFormState,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import { Grid } from '@mui/material'

import {
  InputAmount,
  InputPercentage,
  SelectComponent,
} from '@shared/components'
import { SelectOption } from '@shared/types'
import { currency } from '@shared/utils'

import { Input as InputInterface, InputTypes } from '../../pricing-plan/types'
import { formatFieldName } from '../../pricing-plan/utils'
import { formatFieldNameTerm } from '../../pricing-plan/utils/formatFieldName'

export interface PricingPlanFieldProps {
  groupInput: InputInterface
  testId: string
}

export const PricingPlanField: FC<PricingPlanFieldProps> = ({
  groupInput,
  testId,
}) => {
  const { errors } = useFormState({
    name: [
      formatFieldName(groupInput.item_id),
      formatFieldNameTerm(groupInput.item_id),
    ],
    exact: true,
  })

  const { t } = useTranslation()

  const passthroughOptions = [
    {
      label: t('partner-portal.application-templates.do-not-pass'),
      value: '0',
    },
    {
      label: t('partner-portal.application-templates.passthrough'),
      value: '1',
    },
  ]

  const Input = useCallback(
    ({ field }: { field: ControllerRenderProps }) => {
      let dropdownOptions: SelectOption<string>[] = []
      if (groupInput.type === InputTypes.DOLLARS) {
        if (groupInput.options?.length > 0) {
          dropdownOptions = groupInput.options.map((option) => {
            const valueFormatted = currency(option.value as number)
            return {
              label: `${valueFormatted} ${
                option.label ? `- ${option.label}` : ''
              }`,
              value: option.value.toString(),
            }
          }) as SelectOption<string>[]
        } else {
          return (
            <InputAmount
              {...field}
              style={{ width: '100%' }}
              label={groupInput.label}
              placeholder="0.00"
              disabled={!groupInput.editable}
              testId={testId}
              error={!!errors[formatFieldName(groupInput.item_id)]}
              helperText={
                errors[formatFieldName(groupInput.item_id)]?.message as string
              }
              precision={groupInput.precision}
            />
          )
        }
      }

      if (groupInput.type === InputTypes.PERCENT) {
        if (groupInput.options?.length > 0) {
          dropdownOptions = groupInput.options.map((option) => ({
            label: `${option.value}% ${
              option.label ? `- ${option.label}` : ''
            }`,
            value: option.value.toString(),
          })) as SelectOption<string>[]
        } else {
          return (
            <InputPercentage
              {...field}
              label={groupInput.label}
              disabled={!groupInput.editable}
              decimalLimit={groupInput.precision}
              testId={testId}
              error={!!errors[formatFieldName(groupInput.item_id)]}
              helperText={
                errors[formatFieldName(groupInput.item_id)]?.message as string
              }
            />
          )
        }
      }

      if (
        dropdownOptions.length > 0 ||
        groupInput.type === InputTypes.PASSTHROUGH
      ) {
        return (
          <SelectComponent
            {...field}
            label={groupInput.label}
            options={
              dropdownOptions.length > 0 ? dropdownOptions : passthroughOptions
            }
            disabled={!groupInput.editable}
            style={{
              height: '44px',
            }}
            testId={testId}
            error={!!errors[formatFieldName(groupInput.item_id)]}
            helperText={
              errors[formatFieldName(groupInput.item_id)]?.message as string
            }
          />
        )
      }

      return null
    },
    [errors, groupInput]
  )

  const hasTermInput = groupInput.terms_options?.length > 0

  return (
    <>
      <Grid item xs={hasTermInput ? 6 : 12} md={hasTermInput ? 3 : 6}>
        <Controller
          name={formatFieldName(groupInput.item_id)}
          render={(field) => <Input {...field} />}
        />
      </Grid>
      {hasTermInput && (
        <Grid item xs={6} md={3}>
          <Controller
            name={formatFieldNameTerm(groupInput.item_id)}
            render={({ field }) => (
              <SelectComponent
                {...field}
                label={t('partner-portal.application-templates.term')}
                options={groupInput.terms_options}
                disabled={!groupInput.editable}
                style={{
                  height: '44px',
                }}
                testId={`${testId}-term`}
                error={!!errors[formatFieldNameTerm(groupInput.item_id)]}
                helperText={
                  errors[formatFieldNameTerm(groupInput.item_id)]
                    ?.message as string
                }
              />
            )}
          />
        </Grid>
      )}
    </>
  )
}
