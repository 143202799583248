import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@shared/components'

interface DoneButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isLoading: boolean
  isDisabled: boolean
}

export const DoneButton: FC<DoneButtonProps> = ({
  onClick,
  isLoading,
  isDisabled,
}) => {
  const { t } = useTranslation()

  return (
    <Button
      testId="done-button"
      label={t('common.done')}
      onClick={onClick}
      isLoading={isLoading}
      disabled={isDisabled}
    />
  )
}
