import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { ActionModal } from '@shared/components'

interface WarningEditTemplateProps {
  isOpen: boolean
  onConfirm: () => void
  onClose: () => void
}

const useStyles = tss.withName('WarningEditTemplate').create(() => ({
  modal: {
    minWidth: '400px',
  },
}))

export const WarningEditTemplate: FC<WarningEditTemplateProps> = ({
  isOpen,
  onConfirm,
  onClose,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <ActionModal
      title={t(
        'partner-portal.application-templates.are-you-sure-you-want-to-make-changes-to-this-template'
      )}
      open={isOpen}
      onClose={onClose}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
        },
        {
          label: t('common.yes'),
          onClick: () => {
            onConfirm()
            onClose()
          },
        },
      ]}
      className={classes.modal}
    >
      {t(
        'partner-portal.application-templates.if-saved-template-will-become-inactive'
      )}
    </ActionModal>
  )
}
