import { noop } from 'lodash'
import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { ApplicationTemplate } from '@shared/api'
import { ActionModal, Stepper } from '@shared/components'
import { PubSubEvent, useSub } from '@shared/hooks'
import { WizardStepperUtility } from '@shared/utils'

import { getWizardSteps } from '@/utils/application-templates/getWizardSteps'

import { AgentStep } from '../../enums'

interface ApplicationTemplateStepperProps {
  activeStep: AgentStep
}

const useStyles = tss.withName('ApplicationTemplateStepper').create(() => ({
  buttonContainer: {
    width: '100%',
  },
}))

export const APPLICATION_TEMPLATE_CHANGE_EVENT = new Event(
  'APPLICATION_TEMPLATE_CHANGE'
) as PubSubEvent<ApplicationTemplate | null>

type ModalProps = {
  open: boolean
  title: string
  content: string
  navigateTo?: string
}

export const ApplicationTemplateStepper: FC<
  ApplicationTemplateStepperProps
> = ({ activeStep }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [modalProps, setModalProps] = useState<ModalProps>({
    open: false,
    title: '',
    content: '',
  })

  const stepperSteps = WizardStepperUtility.generateStepperSteps(
    getWizardSteps({ t })
  )

  const validSteps = stepperSteps
    .map((step) => step.number)
    .concat([AgentStep.COMPLETED, AgentStep.CHANGING_PRODUCTS])

  useSub<typeof APPLICATION_TEMPLATE_CHANGE_EVENT>(
    APPLICATION_TEMPLATE_CHANGE_EVENT.type,
    ({ data: template }) => setApplicationTemplate(template)
  )

  const activeStepIndex = stepperSteps.findIndex(
    (stepperStep) => stepperStep.number === activeStep
  )

  useEffect(() => {
    if (!applicationTemplate) {
      return
    }

    const previousStep = stepperSteps[activeStepIndex - 1]

    const isInvalidStep =
      !validSteps.includes(applicationTemplate.agent_steps) &&
      applicationTemplate.agent_steps > AgentStep.TEMPLATE_INFO

    if (
      applicationTemplate.agent_steps === null ||
      applicationTemplate.agent_steps === undefined ||
      isInvalidStep
    ) {
      setModalProps({
        open: true,
        title: t('partner-portal.application-templates.invalid-template'),
        content: t(
          'partner-portal.application-templates.invalid-template-description'
        ),
        navigateTo: '/partner/application-templates',
      })
      return
    }

    if (
      applicationTemplate.agent_steps === AgentStep.COMPLETED &&
      !applicationTemplate.is_legacy
    ) {
      return
    }

    if (
      applicationTemplate.agent_steps < AgentStep.TEMPLATE_INFO &&
      applicationTemplate.is_legacy &&
      activeStep !== AgentStep.TEMPLATE_INFO
    ) {
      setModalProps({
        open: true,
        title: t('partner-portal.application-templates.invalid-step'),
        content: t('partner-portal.application-templates.migration-needed'),
        navigateTo: `/partner/application-templates/${applicationTemplate.template_id}/template-info`,
      })
      return
    }

    if (previousStep && previousStep.number > applicationTemplate.agent_steps) {
      setModalProps({
        open: true,
        title: t('partner-portal.application-templates.invalid-step'),
        content: t(
          'partner-portal.application-templates.not-setup-for-this-page-yet'
        ),
      })
    }
  }, [applicationTemplate])

  return (
    <>
      <ActionModal
        title={modalProps.title}
        guidingId="invalid-step"
        open={modalProps.open}
        buttons={[
          {
            label: t('common.ok'),
            onClick: () => {
              setModalProps({ ...modalProps, open: false })
              if (modalProps.navigateTo) {
                navigate(modalProps.navigateTo)
              } else {
                navigate(-1)
              }
            },
            containerClassName: classes.buttonContainer,
          },
        ]}
      >
        {modalProps.content}
      </ActionModal>

      <Stepper
        testId="new-template-stepper"
        steps={stepperSteps}
        activeStep={activeStepIndex}
        onChange={noop}
      />
    </>
  )
}
