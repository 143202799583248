import SvgIcon from '@mui/material/SvgIcon'

const DeleteProductIcon = ({ size = 16, color = '#374151', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.7605 14.0003H5.2405C4.5425 14.0003 3.9625 13.4617 3.9105 12.765L3.3125 4.66699H12.6685L12.0905 12.7617C12.0405 13.4597 11.4598 14.0003 10.7605 14.0003V14.0003Z"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M8.0013 7.33301V11.333"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2.66797 4.66634H13.3346"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M11.3346 4.66667L10.6593 2.86533C10.464 2.34467 9.96663 2 9.41064 2H6.59197C6.03597 2 5.53864 2.34467 5.3433 2.86533L4.66797 4.66667"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.2853 7.33301L9.99859 11.333"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.71344 7.33301L6.0001 11.333"
          stroke={color}
          strokeWidth="1.2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default DeleteProductIcon
