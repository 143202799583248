import { FC, useEffect, useState } from 'react'
import { FormState, useFormState } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useBlocker } from 'react-router-dom'

import { ActionModal } from '../../modals/action-modal/ActionModal'

export interface UnsavedWarningProps {
  customBlocker?: {
    triggerEvent: string
    action: () => void
  }
}

// Simulate properties and methods from react-router-dom blocker
const manualBlocker = (
  action: () => void,
  reset: () => void,
  shouldBlock = false
) => {
  return {
    state: shouldBlock ? 'blocked' : 'idle',
    proceed: action,
    reset: reset,
  }
}

// This component should be inside the FormProvider component.
// It will show a modal when the user tries to navigate away from the page with unsaved changes.
// In order for this to work well. You need to have correctly specified the defaultValues in the useForm hook.
// This also needs to be used inside a RouterProvider component.
export const UnsavedWarning = ({ customBlocker }: UnsavedWarningProps) => {
  const { t } = useTranslation()
  const formState = useFormState()

  const [isCustomTriggered, setIsCustomTriggered] = useState(false)

  const shouldBlock = formState.isDirty && !formState.isSubmitting
  // The condition to block the navigation is when the form is dirty and not submitting. This is because submissions usually navigate away from the page.
  const navBlocker = useBlocker(shouldBlock)
  const customBlock = customBlocker
    ? manualBlocker(
        customBlocker.action,
        () => setIsCustomTriggered(false),
        shouldBlock && isCustomTriggered
      )
    : null

  // Custom blocker takes precedence over the default blocker since context can not be used conditionally.
  const blocker = customBlock || navBlocker

  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (blocker.state === 'blocked') {
      setIsOpen(true)
    }
  }, [blocker])

  const handleConfirm = () => {
    setIsOpen(false)

    blocker.proceed()
  }

  const handleCancel = () => {
    setIsOpen(false)
    blocker.reset()
  }

  // Custom blocker should work based on events. Main component will call the event instead of the action, and this component will decide whether to block or not.
  useEffect(() => {
    if (!customBlocker) {
      return
    }

    const trigger = () => {
      if (shouldBlock) {
        setIsCustomTriggered(true)
      } else {
        customBlocker.action()
      }
    }

    window.addEventListener(customBlocker.triggerEvent, trigger)

    return () => {
      window.removeEventListener(customBlocker.triggerEvent, trigger)
    }
  }, [customBlocker, shouldBlock])

  return (
    <ActionModal
      open={isOpen}
      onClose={handleCancel}
      title={t('common.unsaved-data')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: handleCancel,
        },
        {
          label: t('common.discard-changes'),
          onClick: handleConfirm,
        },
      ]}
    >
      {t('common.unsaved-data-description')}
    </ActionModal>
  )
}
