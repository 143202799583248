import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { ActionModal } from '@shared/components'

interface ConfirmChangePricingPlanProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConfirmChangePricingPlan: FC<ConfirmChangePricingPlanProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      open={open}
      title={t('partner-portal.application-templates.confirm-plan-change')}
      onClose={onClose}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
        },
        {
          label: t('common.yes'),
          onClick: onConfirm,
          color: 'primary',
        },
      ]}
    >
      {t('partner-portal.application-templates.confirm-change-pricing-plan')}
    </ActionModal>
  )
}
