import { FC } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import CloseIcon from '@mui/icons-material/Close'
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@mui/material'

import { ApplicationTemplate } from '@shared/api'
import { Button, Input } from '@shared/components'
import { useNotification } from '@shared/hooks'
import { Copy } from '@shared/icons'

const useStyles = tss.withName('ViewLinks').create(({ theme }) => ({
  root: { height: 'auto' },
  paper: {
    position: 'absolute',
    top: '70px',
    left: '50%',
    transform: `translate(-50%, 0)`,
    width: '750px',
    maxWidth: '750px',
    borderRadius: '6px',
  },
  container: { padding: '20px' },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  contentContainer: {
    padding: 0,
    margin: '24px 0 24px 0',
    maxHeight: '468px',
  },
  title: {
    padding: 0,
    fontFamily: 'Inter',
    fontSize: '18px',
    fontWeight: '600',
    lineHeight: '28px',
    color: theme.palette['neutral-900'],
  },
  button: { height: '44px' },
}))

interface ViewLinksProps {
  open: boolean
  onClose: () => void
  template: ApplicationTemplate
}

export const ViewLinks: FC<ViewLinksProps> = ({ open, onClose, template }) => {
  const { classes } = useStyles()

  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const copyValue = (value: string) => {
    navigator.clipboard.writeText(value).then(() =>
      setNotification({
        label: t('partner-portal.application-templates.link-copied'),
        type: 'success',
      })
    )
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ container: classes.root, paper: classes.paper }}
      data-testid="view-links-modal"
    >
      <Box className={classes.container}>
        <Box className={classes.titleContainer}>
          <DialogTitle className={classes.title} data-testid="view-links-title">
            {t(
              'partner-portal.application-templates.application-template-links'
            )}
          </DialogTitle>

          <IconButton
            aria-label="close"
            onClick={onClose}
            data-guiding-id="view-links-close"
          >
            <CloseIcon />
          </IconButton>
        </Box>

        <Divider
          style={{
            margin: '16px -20px',
          }}
        />

        <DialogContent
          className={classes.contentContainer}
          data-testid="view-links-content"
        >
          <Grid
            container
            alignItems={'flex-end'}
            justifyContent={'space-between'}
          >
            <Grid item width={'75%'}>
              <Input
                label={t(
                  'partner-portal.application-templates.full-page-clear-app'
                )}
                onChange={null}
                disabled
                value={template?.merchant_app_link}
                testId="merchant-app-link-input"
              />
            </Grid>

            <Grid item>
              <Button
                label={t('common.test')}
                color="secondary"
                className={classes.button}
                testId="merchant-app-link-test-button"
                onClick={() =>
                  window.open(template?.merchant_app_link, '_blank')
                }
              />
            </Grid>

            <Grid item>
              <Button
                color="secondary"
                className={classes.button}
                icon={<Copy />}
                testId="merchant-app-link-copy-button"
                onClick={() => copyValue(template?.merchant_app_link)}
              />
            </Grid>
          </Grid>

          <Grid
            container
            mt={3}
            alignItems={'flex-end'}
            justifyContent={'space-between'}
          >
            <Grid item width={'75%'}>
              <Input
                label={t(
                  'partner-portal.application-templates.iframeable-clear-app'
                )}
                onChange={null}
                disabled
                value={template?.merchant_app_link_iframe}
                testId="merchant-app-link-iframe-input"
              />
            </Grid>

            <Grid item>
              <Button
                label={t('common.test')}
                color="secondary"
                className={classes.button}
                testId="merchant-app-link-iframe-test-button"
                onClick={() =>
                  window.open(template?.merchant_app_link_iframe, '_blank')
                }
              />
            </Grid>

            <Grid item>
              <Button
                color="secondary"
                className={classes.button}
                icon={<Copy />}
                testId="merchant-app-link-iframe-copy-button"
                onClick={() => copyValue(template?.merchant_app_link_iframe)}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Box>
    </Dialog>
  )
}
