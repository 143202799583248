import SvgIcon from '@mui/material/SvgIcon'

const TerminalIcon = ({ size = 32, color = '#D64123', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M11.9993 8.6665H7.99935C7.26335 8.6665 6.66602 8.06917 6.66602 7.33317V5.99984C6.66602 5.26384 7.26335 4.6665 7.99935 4.6665H11.9993C12.7353 4.6665 13.3327 5.26384 13.3327 5.99984V7.33317C13.3327 8.06917 12.7353 8.6665 11.9993 8.6665Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10.0007 8.6665V11.9998"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.334 11.9998V6.6665H22.6673V11.9998"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M27.974 22.6667H4.02734"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M27.92 22.348L25.5853 13.0093C25.4373 12.416 24.904 12 24.292 12H7.708C7.096 12 6.56267 12.416 6.41467 13.0093L4.08 22.348C4.02667 22.56 4 22.7773 4 22.9947V26.6667C4 27.4027 4.59733 28 5.33333 28H26.6667C27.4027 28 28 27.4027 28 26.6667V22.9947C28 22.7773 27.9733 22.56 27.92 22.348Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M20.666 16.0002H21.9993"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M10 16.0002H11.3333"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.334 16.0002H16.6673"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18 18.6667H19.3333"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.666 18.6667H13.9993"
          stroke={color}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default TerminalIcon
