import { TFunction } from 'i18next'

import { WizardStep } from '@shared/utils'

import { AgentStep } from '@/pages/application-templates/enums'

type GetWizardStepsData = {
  t: TFunction
  // The MUI Stepper doesn't care about urls, so this is optional for it.
  templateId?: string
}

export const getWizardSteps = ({
  t,
  templateId,
}: GetWizardStepsData): WizardStep[] => [
  {
    label: t('partner-portal.application-templates.template-info'),
    number: AgentStep.TEMPLATE_INFO,
    url: templateId
      ? `/partner/application-templates/new${templateId}`
      : undefined,
  },
  {
    label: t('partner-portal.application-templates.payment-velocity'),
    number: AgentStep.PAYMENT_AND_VELOCITY,
    url: templateId
      ? `/partner/application-templates/${templateId}/payment-and-velocity`
      : undefined,
  },
  {
    label: t('partner-portal.application-templates.products'),
    number: AgentStep.FINISH_PRODUCTS,
    url: templateId
      ? `/partner/application-templates/${templateId}/products`
      : undefined,
  },
  {
    label: t('partner-portal.application-templates.pricing-plan'),
    number: AgentStep.PRICING_PLAN,
    url: templateId
      ? `/partner/application-templates/${templateId}/pricing-plan`
      : undefined,
  },
]
