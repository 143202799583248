import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { noop } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Typography } from '@mui/material'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { api, ApplicationTemplate } from '@shared/api'
import { HasPermission, UserNotAllowed } from '@shared/components'
import Button from '@shared/components/buttons/Button'
import ButtonBar, {
  ButtonBarEnd,
} from '@shared/components/buttons/button-bar/ButtonBar'
import Status from '@shared/components/status/base/Status'
import { useEnforceLogin } from '@shared/hooks'
import { useFtpPortalHubCommunication } from '@shared/hooks/useFtpPortalHubCommunication'
import { NoTokensImported } from '@shared/icons'
import { EnumServiceName } from '@shared/types'
import { formatDatetime, DataSource } from '@shared/utils'

import { mapAppTypeCode, mapPlatformCode } from '@/utils/mapping'

import { DeleteApplicationTemplate } from '../components/delete-application-template/DeleteApplicationTemplate'
import { ViewLinks } from '../components/view-links/ViewLinks'
import { ApplicationTypeCode, CCPlatformCode } from '../enums'

const useStyles = tss.withName('ApplicationTemplates').create(({ theme }) => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
  emptyGridContainer: {
    padding: '72px 24px',
    background: '#FFF',
    borderRadius: '6px',
  },
  emptyGridIconContainer: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    marginBottom: '12px',
  },
  emptyGridIcon: {
    height: '62px',
    width: '62px',
  },
  emptyGridText: {
    textAlign: 'center',
    color: theme.palette['neutral-500'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '20px',
  },
  emptyGridButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '32px',
  },
}))

export const ApplicationTemplates: FC = () => {
  const { t } = useTranslation()
  const gridRef = useRef<AgGridReact>(null)
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const navigate = useNavigate()

  const [hasTemplatesData, setHasTemplatesData] = useState<boolean>(true)

  const [selectedTemplate, setSelectedTemplate] = useState<Pick<
    ApplicationTemplate,
    'template_id' | 'merchant_app_link' | 'merchant_app_link_iframe'
  > | null>(null)

  const [isViewLinksOpen, setIsViewLinksOpen] = useState<boolean>(false)
  const [isDeleteTemplateOpen, setIsDeleteTemplateOpen] =
    useState<boolean>(false)

  const datasource = useMemo(() => {
    return new DataSource('templates', {
      filterVariant: 'filterBy',
    })
  }, [])

  const defaultColDef: ColDef<ApplicationTemplate, any> = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const commonTextColumnFilterProps: ColDef<ApplicationTemplate> = {
    floatingFilter: true,
    filter: 'agTextColumnFilter',
    filterParams: {
      defaultOption: 'contains',
      filterOptions: ['contains'],
      maxNumConditions: 1,
    },
    sortable: true,
  }

  const commonDateColumnFilterProps: ColDef<ApplicationTemplate> = {
    floatingFilter: true,
    filter: DateRangeFilter,
    filterParams: {
      type: 'past',
      showTimePicker: true,
      forceOnlyCustom: true,
    },
    sortable: true,
  }

  const platformCodes = [
    { value: CCPlatformCode.PAYFAC_FIS, label: 'Payfac FIS' },
    { value: CCPlatformCode.PAYFAC_FIS_CORE, label: 'Payfac FIS Core' },
    { value: CCPlatformCode.PAYFAC_FISERV, label: 'Payfac FISERV' },
    { value: CCPlatformCode.WHOLESALE_FDR_NORTH, label: 'Wholesale FDR North' },
    { value: CCPlatformCode.WHOLESALE_FDR_OMAHA, label: 'Wholesale FDR Omaha' },
  ]

  const columnDefs: ColDef<ApplicationTemplate>[] = useMemo(
    () => [
      {
        field: 'template_name',
        headerName: t('common.name'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'status_code',
        headerName: t('partner-portal.application-templates.status'),
        cellRenderer: ({ value }) => {
          const text = value === 1 ? t('common.active') : t('common.inactive')

          const backgroundColor = value === 1 ? 'teal' : 'yellow'

          return <Status text={text} backgroundColor={backgroundColor} />
        },
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [1, 0],
          valueFormatter: (params) =>
            params.value ? t('common.active') : t('common.inactive'),
        },
        sortable: true,
      },
      {
        field: 'pricing_plan_name',
        headerName: t('partner-portal.application-templates.pricing-plan'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'modified_ts',
        headerName: t('partner-portal.application-templates.last-edit'),
        valueGetter: (params) =>
          params.data?.modified_ts
            ? formatDatetime(params.data.modified_ts, user?.tz)
            : '-',
        ...commonDateColumnFilterProps,
      },
      {
        field: 'application_type_code',
        headerName: t('partner-portal.application-templates.type'),
        valueFormatter: (params) => mapAppTypeCode(params.value) ?? '-',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: [
            ApplicationTypeCode.CC,
            ApplicationTypeCode.ACH,
            ApplicationTypeCode['ACH-CC'],
          ],
          valueFormatter: (params) => mapAppTypeCode(params.value),
        },
        sortable: true,
      },
      {
        field: 'platform_code',
        headerName: t('partner-portal.application-templates.platform'),
        valueFormatter: (params) => mapPlatformCode(params.value) ?? '-',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: platformCodes.map(({ value }) => value),
          valueFormatter: (params) => {
            const option = platformCodes.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
          comparator: (a, b) => {
            const optionA = platformCodes.find(
              (option) => option.value === Number(a)
            )

            const optionB = platformCodes.find(
              (option) => option.value === Number(b)
            )

            return optionA.label.localeCompare(optionB.label)
          },
        },
        sortable: true,
      },
      {
        field: 'office_name',
        headerName: t('partner-portal.application-templates.office'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
      {
        field: 'agent_name',
        headerName: t('partner-portal.application-templates.external-agent'),
        valueFormatter: (params) => params.value || '-',
        ...commonTextColumnFilterProps,
      },
    ],
    [user]
  )

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.templates'))

    checkData()
  }, [])

  const checkData = () => {
    api
      .service('templates')
      .find()
      .then((data) => setHasTemplatesData(data.length > 0))
  }

  const refreshGrid = () => {
    gridRef.current!.api.refreshServerSide({ purge: true })
  }

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const threeDotsOptions = useCallback(
    (templateData: ApplicationTemplate) => [
      {
        label: t('partner-portal.application-templates.view-links'),
        enabled: true,
        action: () => {
          setIsViewLinksOpen(true)
          setSelectedTemplate(templateData)
        },
      },
      // Temporarily removed. We will bring it back in a following release.
      // {
      //   label: t('partner-portal.application-templates.duplicate'),
      //   enabled: true,
      //   action: noop,
      // },
      {
        label: t('partner-portal.application-templates.edit'),
        enabled: true,
        action: () =>
          navigate(
            `/partner/application-templates/${templateData.template_id}/template-info`
          ),
      },
      {
        label: t('partner-portal.application-templates.delete'),
        enabled: true,
        action: () => {
          setIsDeleteTemplateOpen(true)
          setSelectedTemplate(templateData)
        },
      },
    ],
    []
  )

  return (
    <HasPermission
      permission="v1.partner.applications.get" // TODO: is this the correct permission?
      unauthorizedComponent={<UserNotAllowed />}
    >
      <>
        {!hasTemplatesData && (
          <Box className={classes.emptyGridContainer}>
            <Box className={classes.emptyGridIconContainer}>
              <NoTokensImported className={classes.emptyGridIcon} />
            </Box>
            <Typography className={classes.emptyGridText}>
              {t(
                'partner-portal.application-templates.you-currently-dont-have-any-templates-created'
              )}
            </Typography>
            <Typography className={classes.emptyGridText}>
              {t(
                'partner-portal.application-templates.after-adding-a-template-it-will-appear-here'
              )}
            </Typography>
            <Box className={classes.emptyGridButtonContainer}>
              <Button
                testId="create-template-button"
                label={t(
                  'partner-portal.application-templates.create-template'
                )}
                onClick={() => navigate('/partner/application-templates/new')}
                guidingId="applicationtemplates-createtemplate"
              />
            </Box>
          </Box>
        )}

        {hasTemplatesData && (
          <>
            <ViewLinks
              open={isViewLinksOpen}
              onClose={() => {
                setIsViewLinksOpen(false)
                setSelectedTemplate(null)
              }}
              template={selectedTemplate}
            />

            <DeleteApplicationTemplate
              open={isDeleteTemplateOpen}
              applicationTemplate={selectedTemplate}
              onSuccess={refreshGrid}
              onClose={() => {
                setIsDeleteTemplateOpen(false)
                setSelectedTemplate(null)
              }}
            />

            <PaginationTable<ApplicationTemplate>
              getRowId={({ data }) => data.template_id}
              rowModelType="serverSide"
              defaultColDef={defaultColDef}
              columnDefs={columnDefs}
              serverSideDatasource={datasource}
              serviceName={EnumServiceName.ApplicationTemplates}
              guidingId="application-templates"
              getGridRef={getGridRef}
              onRowClicked={({ data }) =>
                navigate(
                  `/partner/application-templates/${data.template_id}/template-info`
                )
              }
              threeDotsOptions={threeDotsOptions}
            />

            <AppBar className={classes.appBar}>
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  <Button
                    testId="new-template-button"
                    label={t(
                      'partner-portal.application-templates.new-template'
                    )}
                    onClick={() =>
                      navigate('/partner/application-templates/new')
                    }
                  />
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </>
        )}
      </>
    </HasPermission>
  )
}
