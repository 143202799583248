import { WizardStepName } from './wizardStepperUtility'

type WizardInvalidStepErrorData = {
  step: number
  stepName: WizardStepName
  validSteps: number[]
}

export class WizardStepperUtilityError extends Error {
  constructor(message: string) {
    super(`${WizardStepperUtilityError.name}: ${message}`)
  }
}

export class WizardStepsCantBeEmptyError extends WizardStepperUtilityError {
  constructor() {
    super('"wizardSteps" can\'t be empty')
  }
}

export class WizardInvalidStepError extends WizardStepperUtilityError {
  constructor(data: WizardInvalidStepErrorData) {
    const { step, stepName, validSteps } = data

    super(
      `'"${step}" isn\'t a valid "${stepName}" value, it has to be one of: ${validSteps.join(
        ', '
      )}'`
    )
  }
}
