import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import { ValidationError } from 'yup'

import ErrorOutlineRounded from '@mui/icons-material/ErrorOutlineRounded'
import {
  AppBar,
  Backdrop,
  Box,
  Drawer,
  Grid,
  Portal,
  Typography,
} from '@mui/material'

import {
  api,
  ApplicationTemplate,
  AvailableEquipment,
  Equipment,
  Platform,
} from '@shared/api'
import {
  Button,
  ButtonBar,
  ButtonBarEnd,
  CustomMessage,
  Loading,
  PageLayoutContainer,
  PageLayoutContainerMain,
} from '@shared/components'
import appTheme from '@shared/design'
import {
  useFtpPortalHubCommunication,
  useNotification,
  usePub,
} from '@shared/hooks'
import { Plus } from '@shared/icons'
import { WizardStepperButton, WizardStepperUtility } from '@shared/utils'

import { getWizardSteps } from '@/utils/application-templates/getWizardSteps'

import { AddProduct } from '../components/add-product/AddProduct'
import {
  APPLICATION_TEMPLATE_CHANGE_EVENT,
  ApplicationTemplateStepper,
} from '../components/application-template-stepper/ApplicationTemplateStepper'
import { ContinueButton } from '../components/buttons/ContinueButton'
import { QuitButton } from '../components/buttons/QuitButton'
import { SaveAndContinueButton } from '../components/buttons/SaveAndContinueButton'
import { SaveAndQuitButton } from '../components/buttons/SaveAndQuitButton'
import DeleteProductModal from '../components/delete-product-modal/DeleteProductModal'
import { EditProduct } from '../components/edit-product/EditProduct'
import { InactiveTemplateBanner } from '../components/inactive-template-banner/InactiveTemplateBanner'
import { SelectedModel } from '../components/product-form/ProductForm'
import { buildSchema } from '../components/product-form/utils/buildSchema'
import { ProductItem } from '../components/product-item/ProductItem'
import { WarningEditTemplate } from '../components/warning-edit-template/WarningEditTemplate'
import { excludedWizardStepperButtons } from '../constants/excludedWizardStepperButtons'
import { AgentStep } from '../enums'

const useStyles = tss.withName('Products').create(() => ({
  container: {
    background: 'unset',
    paddingRight: 0,
  },
  stepperContainer: {
    padding: '24px',
  },
  pageLayoutMainContainer: {
    background: 'white',
    padding: '24px',
    borderRadius: '6px',
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
  addProductButton: {
    marginRight: '16px',
  },
}))

const CURRENT_WIZARD_STEP = AgentStep.FINISH_PRODUCTS

const Products: FC = () => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { templateId } = useParams()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const navigate = useNavigate()

  const publish = usePub()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [isErrorDisplay, setIsErrorDisplay] = useState(false)
  const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
  const [selectedProduct, setSelectedProduct] = useState<Equipment>(null)
  const [warningData, setWarningData] = useState<{
    isOpen: boolean
    onConfirm: () => void
  }>({
    isOpen: false,
    onConfirm: () => {},
  })

  const [products, setProducts] = useState<Equipment[]>([])
  const [productsErrors, setProductsErrors] = useState<{
    [key: string]: { [key: string]: string }
  }>({})
  const [platforms, setPlatforms] = useState<Platform[]>([])
  const [availableEquipment, setAvailableEquipment] = useState<
    AvailableEquipment[]
  >([])

  const [showAddProductForm, setShowAddProductForm] = useState(false)
  const [showEditProductForm, setShowEditProductForm] = useState(false)

  // This function iterates over the products and validates them against the Yup schema
  const validateProducts = async (
    template: ApplicationTemplate,
    availableEquipment: AvailableEquipment[],
    products: Equipment[]
  ) => {
    const results = await Promise.allSettled(
      products.map((product) => {
        const equipment = availableEquipment.find(
          (equipment) =>
            equipment.equipment_type_id === product.equipment_type_id
        )

        const model = equipment?.models?.find(
          (model) => model.model_id === product.model_id
        ) as SelectedModel

        const schema = buildSchema(t, model, template)

        return schema.validate(product, { abortEarly: false })
      })
    )

    const errors = results
      .filter((result) => result.status === 'rejected')
      .map((result: any) => result.reason as ValidationError)

    const productsErrors = errors.reduce((acc, error) => {
      const equipmentId = error.value.equipment_id
      acc[equipmentId] = error.inner.reduce((acc, error) => {
        acc[error.path] = error.errors[0]
        return acc
      }, {})
      return acc
    }, {})

    setProductsErrors(productsErrors)
    return productsErrors
  }

  const getApplicationTemplate = async () => {
    try {
      setIsLoading(true)
      const template = await api.service('templates').get(templateId)
      const [platforms, products, available] = await Promise.all([
        api.service('platforms').find({
          query: {
            agent_id: template.agent_id,
          },
        }),
        api.service('templates').getEquipment(templateId, {
          query: {
            page: {
              size: 5000,
            },
          },
        }),
        api.service('templates').getAvailableEquipment(templateId),
      ])

      await validateProducts(template, available, products)

      publish(APPLICATION_TEMPLATE_CHANGE_EVENT, template)
      setApplicationTemplate(template)
      setPlatforms(platforms)
      setProducts(products)
      setAvailableEquipment(available)
    } catch (error) {
      console.error(error)
      setNotification({
        label: t('common.error-loading-data'),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  // State and Methods related to Wizard Stepper
  const wizardSteps = getWizardSteps({ t, templateId })
  const highestCompletedStep: AgentStep = applicationTemplate?.agent_steps

  const quit = () => {
    navigate('/partner/application-templates')
  }

  const navigateToNextStep = (updatedWizardSteps?: typeof wizardSteps) => {
    navigate(
      WizardStepperUtility.nextStepUrl(
        updatedWizardSteps ?? wizardSteps,
        CURRENT_WIZARD_STEP
      )
    )
  }

  const saveProducts = async (shouldQuit: boolean = false) => {
    try {
      const errors = await validateProducts(
        applicationTemplate,
        availableEquipment,
        products
      )

      if (Object.keys(errors).length > 0) {
        setNotification({
          type: 'error',
          label: t(
            'partner-portal.application-templates.missing-data-products'
          ),
        })
        return
      }

      if (products.length === 0) {
        setIsErrorDisplay(true)
        return
      }
      setIsSaving(true)
      await updateAgentSteps(AgentStep.FINISH_PRODUCTS)
      if (shouldQuit) {
        quit()
      } else {
        navigateToNextStep()
      }
    } catch (error) {
      setNotification({
        type: 'error',
        label: t('common.please-review-information'),
      })
    } finally {
      setIsSaving(false)
    }
  }

  const enabledFormButtons = WizardStepperUtility.enabledButtons({
    wizardSteps,
    currentStep: CURRENT_WIZARD_STEP,
    thisPageDirty:
      applicationTemplate?.agent_steps === AgentStep.CHANGING_PRODUCTS ||
      applicationTemplate?.agent_steps === AgentStep.PAYMENT_AND_VELOCITY,
    highestCompletedStep,
    finishStep: AgentStep.COMPLETED,
  }).filter(
    (wizardStepperButton) =>
      !excludedWizardStepperButtons.includes(wizardStepperButton)
  )

  const handleProductAdded = async (product: Equipment) => {
    setIsErrorDisplay(false)
    setProducts([...products, product])
    setShowAddProductForm(false)
    await updateAgentSteps(AgentStep.CHANGING_PRODUCTS)
  }

  const handleProductEdited = async (product: Equipment) => {
    setIsErrorDisplay(false)

    const updatedProducts = products.map((currentProduct) =>
      currentProduct.equipment_id === product.equipment_id
        ? product
        : currentProduct
    )

    setProducts(updatedProducts)
    setShowEditProductForm(false)
    await validateProducts(
      applicationTemplate,
      availableEquipment,
      updatedProducts
    )
    await updateAgentSteps(AgentStep.CHANGING_PRODUCTS)
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
    if (templateId) {
      getApplicationTemplate()
    }
  }, [templateId])

  const handleDeleteProduct = async (selectedProduct: Equipment) => {
    setIsConfirmationOpen(false)
    try {
      await Promise.all([
        api
          .service('templates')
          .deleteEquipment(templateId, selectedProduct.equipment_id.toString()),
        updateAgentSteps(AgentStep.CHANGING_PRODUCTS),
      ])

      setProducts((currentProducts) =>
        currentProducts.filter(
          (product) => product.equipment_id !== selectedProduct.equipment_id
        )
      )

      setNotification({
        type: 'success',
        label: t(
          'partner-portal.application-templates.product-deleted-successfully'
        ),
      })
    } catch (error: any) {
      setNotification({
        type: 'error',
        label: error.message,
      })
    }
  }

  const updateAgentSteps = async (agentStep: number) => {
    if (applicationTemplate?.agent_steps === agentStep) return

    await api.service('templates').updateProductAgentStep(templateId, {
      agent_steps: agentStep,
    })

    setApplicationTemplate((currentTemplate) => ({
      ...currentTemplate,
      agent_steps: agentStep,
    }))
  }

  const handleOpenDeleteConfirmation = (product: Equipment) => {
    if (applicationTemplate.agent_steps === AgentStep.COMPLETED) {
      setWarningData({
        isOpen: true,
        onConfirm: () => {
          setSelectedProduct(product)
          setIsConfirmationOpen(true)
        },
      })
    } else {
      setSelectedProduct(product)
      setIsConfirmationOpen(true)
    }
  }

  const handleEdit = (product: Equipment) => {
    if (applicationTemplate.agent_steps === AgentStep.COMPLETED) {
      setWarningData({
        isOpen: true,
        onConfirm: () => {
          setSelectedProduct(product)
          setShowEditProductForm(true)
        },
      })
    } else {
      setSelectedProduct(product)
      setShowEditProductForm(true)
    }
  }

  const handleCancelDelete = () => {
    setIsConfirmationOpen(false)
    setSelectedProduct(null)
  }
  const backendPlatform = platforms.find(
    (platform) => applicationTemplate.platform_code === platform.platform_code
  )

  return (
    <>
      <WarningEditTemplate
        isOpen={warningData.isOpen}
        onConfirm={warningData.onConfirm}
        onClose={() => setWarningData({ isOpen: false, onConfirm: () => {} })}
      />
      <Portal>
        <Backdrop
          sx={{ zIndex: 1000 }}
          open={showAddProductForm || showEditProductForm}
        >
          <Drawer
            data-testid="add-product-form-drawer"
            open={showAddProductForm || showEditProductForm}
            variant="persistent"
            anchor="right"
            PaperProps={{ sx: { width: '50%', maxWidth: '640px' } }}
            onClose={() => {
              setShowAddProductForm(false)
              setShowEditProductForm(false)
            }}
          >
            {showAddProductForm && (
              <AddProduct
                template={applicationTemplate}
                currentProducts={products}
                onSuccess={handleProductAdded}
                onClose={() => setShowAddProductForm(false)}
              />
            )}
            {showEditProductForm && (
              <EditProduct
                template={applicationTemplate}
                product={selectedProduct}
                currentProducts={products}
                onSuccess={handleProductEdited}
                onClose={() => setShowEditProductForm(false)}
                currentErrors={productsErrors[selectedProduct.equipment_id]}
              />
            )}
          </Drawer>
        </Backdrop>
      </Portal>

      <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
        <Grid item xs={12} md={2} className={classes.stepperContainer}>
          <ApplicationTemplateStepper activeStep={CURRENT_WIZARD_STEP} />
        </Grid>

        <Grid item xs={12} md={10}>
          {applicationTemplate?.agent_steps >= AgentStep.TEMPLATE_INFO && (
            <InactiveTemplateBanner />
          )}

          <PageLayoutContainerMain isFullWidth>
            <Box className={classes.pageLayoutMainContainer}>
              <Box sx={{ marginBottom: '32px' }}>
                <Typography variant="h6" sx={{ marginBottom: '8px' }}>
                  {t('partner-portal.application-templates.products')}
                </Typography>

                <Typography variant="body2">
                  {t(
                    'partner-portal.application-templates.after-selecting-a-product-you-will-be-able-to-specify-its-billing-and-platform-settings'
                  )}
                </Typography>
              </Box>
              {isLoading ? (
                <Loading
                  style={{
                    maxHeight: '200px',
                  }}
                />
              ) : (
                <>
                  {products.length > 0 &&
                    products.map((product) => (
                      <ProductItem
                        key={product.equipment_id}
                        product={product}
                        backendPlatform={backendPlatform}
                        frontendPlatform={platforms.find(
                          (platform) =>
                            product.fe_platform_code === platform.platform_code
                        )}
                        onDelete={handleOpenDeleteConfirmation}
                        onEdit={handleEdit}
                        errors={productsErrors[product.equipment_id]}
                      />
                    ))}
                </>
              )}
              <Button
                testId="add-product-button"
                label={t('partner-portal.application-templates.add-product')}
                icon={<Plus />}
                iconPosition="start"
                color="secondary"
                className={classes.addProductButton}
                onClick={() => {
                  if (
                    applicationTemplate?.agent_steps === AgentStep.COMPLETED
                  ) {
                    setWarningData({
                      isOpen: true,
                      onConfirm: () => {
                        setShowAddProductForm(true)
                      },
                    })
                  } else {
                    setShowAddProductForm(true)
                  }
                }}
              />
              {isErrorDisplay && (
                <CustomMessage
                  message={t(
                    'partner-portal.application-templates.you-need-to-add-at-least-one-product-to-proceed'
                  )}
                  IconComponent={ErrorOutlineRounded}
                  showIcon
                  iconStyle={{
                    color: appTheme.palette['negative'],
                  }}
                  iconSize="32px"
                  containerStyle={{
                    marginTop: '32px',
                    backgroundColor: appTheme.palette['light-red'],
                  }}
                />
              )}
            </Box>
          </PageLayoutContainerMain>
        </Grid>

        {!isLoading && (
          <AppBar className={classes.appBar}>
            <ButtonBar style={{ marginBottom: '0 !important' }}>
              <ButtonBarEnd>
                {enabledFormButtons.includes(WizardStepperButton.QUIT) && (
                  <QuitButton
                    containerClassName={classes.addProductButton}
                    onClick={() => {
                      quit()
                    }}
                  />
                )}

                {enabledFormButtons.includes(
                  WizardStepperButton.SAVE_AND_QUIT
                ) && (
                  <SaveAndQuitButton
                    containerClassName={classes.addProductButton}
                    onClick={() => saveProducts(true)}
                    isLoading={isSaving}
                  />
                )}

                {enabledFormButtons.includes(
                  WizardStepperButton.SAVE_AND_CONTINUE
                ) && (
                  <SaveAndContinueButton
                    onClick={() => saveProducts()}
                    isLoading={isSaving}
                  />
                )}

                {enabledFormButtons.includes(WizardStepperButton.CONTINUE) && (
                  <ContinueButton
                    onClick={() => {
                      navigateToNextStep()
                    }}
                  />
                )}
              </ButtonBarEnd>
            </ButtonBar>
          </AppBar>
        )}
      </PageLayoutContainer>
      <DeleteProductModal
        open={isConfirmationOpen}
        onClose={handleCancelDelete}
        onConfirm={() => handleDeleteProduct(selectedProduct)}
      />
    </>
  )
}

export default Products
