export const truncateToPrecision = (
  value: number,
  precision: number
): string => {
  const [integerPart, decimalPart] = value.toString().split('.')
  let truncatedDecimal = decimalPart?.slice(0, precision) || '0'
  if (truncatedDecimal.length < precision) {
    truncatedDecimal = truncatedDecimal.padEnd(precision, '0')
  }
  return truncatedDecimal?.length > 0
    ? `${integerPart}.${truncatedDecimal}`
    : integerPart
}
