import { TFunction } from 'i18next'
import * as yup from 'yup'

import { ApplicationTemplate, Equipment } from '@shared/api/src'

import { canDisplayField } from './canDisplayField'
import { ArrayFieldDirty } from '../constants/fieldsOptions'
import { SelectedModel } from '../ProductForm'
import {
  IntegratedSystem,
  OwnershipType,
  ProductProvider,
  CardType,
} from '../types/enums'

export const buildSchema = (
  t: TFunction,
  selectedModel: SelectedModel,
  template: ApplicationTemplate,
  customDirty?: ArrayFieldDirty
) => {
  const conditionalFields = selectedModel?.conditional_fields || []
  const displayFields = canDisplayField(undefined, conditionalFields)

  return yup.object().shape({
    equipment_type_id: yup
      .number()
      .required(
        t('partner-portal.application-templates.you-must-select-a-product-type')
      ),
    model_id: yup
      .number()
      .required(
        t('partner-portal.application-templates.you-must-select-a-model')
      ),
    fe_platform_code: yup
      .number()
      .nullable()
      .when('model_id', {
        is: (model_id: number) =>
          !!model_id && template.application_type_code !== 2,
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-front-end-platform'
            )
          ),
      }),
    conditional_settings: yup.object<Equipment['conditional_settings']>({
      var_pos_name: yup
        .string()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.var_pos_name,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-enter-a-product-name'
              )
            ),
        }),
      var_pos_system: yup.string().nullable(),
      software: yup.string().nullable(),
      transaction_type_code: yup
        .number()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.transaction_type_code,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-the-transaction-types-accepted-for-mobile-pawn-and-normal-pawn'
              )
            ),
        }),
      is_ordering: yup
        .boolean()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.is_ordering,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-if-ordering-fluidpay-bluedog-product'
              )
            ),
        }),
      is_integrated: yup
        .boolean()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.is_integrated,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-the-integrated-setup'
              )
            ),
        }),
      integrated_system: yup
        .number()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.integrated_system,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-an-integrated-system'
              )
            ),
        }),

      is_bravo_pos: yup
        .boolean()
        .nullable()
        .when([], {
          is: () => displayFields.conditional_settings.is_bravo_pos,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-if-is-this-a-bravo-pos-setup'
              )
            ),
        }),
      accepted_cards: yup.array(
        yup
          .string()
          .nullable()
          .oneOf([
            CardType.VISA,
            CardType.MASTERCARD,
            CardType.AMEX,
            CardType.DISCOVER,
          ])
      ),
      fluidpay_settings: yup.array(yup.string()).when([], {
        is: () => displayFields.conditional_settings.fluidpay_settings,
        then: (schema) => {
          const requiredMessage = t(
            'partner-portal.application-templates.you-must-select-a-value-for-this-fluidpay-setting'
          )

          // Make sure all fluidpay setting are touched.
          return schema.test('required', requiredMessage, () =>
            Object.keys(customDirty?.fluidpaySettings || {}).every(
              (key) => !!customDirty?.fluidpaySettings[key]
            )
          )
        },
        otherwise: (schema) => schema.nullable(),
      }),
      simple_swipe_setup_type_code: yup
        .number()
        .nullable()
        .when([], {
          is: () =>
            displayFields.conditional_settings.simple_swipe_setup_type_code,
          then: (schema) =>
            schema.required(
              t(
                'partner-portal.application-templates.you-must-select-a-setup-type'
              )
            ),
        }),
      additional_settings: yup.array(yup.string()).when([], {
        is: () =>
          displayFields.conditional_settings.additional_settings &&
          selectedModel?.label === 'Payment Cloud', // Temporary fix on https://zeamster.atlassian.net/browse/PPW-899
        then: (schema) => {
          const requiredMessage = t(
            'partner-portal.application-templates.you-must-select-additional-settings'
          )

          // This is only used for FortisGateway options. If the field was not touched its undefined and we trigger the required message.
          // If the value was touched, it will be either an array with values or an empty array.
          return schema.min(0, requiredMessage).required(requiredMessage)
        },
        otherwise: (schema) => schema.nullable(),
      }),
    }),
    supplier: yup
      .number()
      .oneOf([
        ProductProvider.FORTIS,
        ProductProvider.MERCHANT,
        ProductProvider.SALES_PARTNER,
      ])
      .when('model_id', {
        is: (model_id) =>
          canDisplayField({ model_id }, conditionalFields).supplier,
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-a-product-provider'
            )
          ),
        otherwise: (schema) => schema.nullable(),
      }),

    ownership_code: yup
      .number()
      .oneOf([OwnershipType.SALE, OwnershipType.LOANER])
      .when(['supplier'], {
        is: (supplier) =>
          canDisplayField({ supplier }, conditionalFields).ownership_code,
        then: (schema) =>
          schema.required(
            t(
              'partner-portal.application-templates.you-must-select-an-ownership-type'
            )
          ),
        otherwise: (schema) => schema.nullable(),
      }),
  })
}
