import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonProps } from '@shared/components'

interface ContinueButtonProps extends ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
}

export const ContinueButton: FC<ContinueButtonProps> = ({
  onClick,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Button
      testId="continue-button"
      label={t('common.continue')}
      containerClassName={props.containerClassName}
      onClick={onClick}
    />
  )
}
