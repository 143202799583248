import {
  CardReaderIcon,
  CubeIcon,
  GatewayIcon,
  PinPadIcon,
  SoftwareIcon,
  TerminalIcon,
} from '@/assets/icons'

export const getProductTypeIcon = (productTypeId: number | undefined) => {
  switch (productTypeId) {
    case 1:
      return <TerminalIcon />
    case 2:
      return <GatewayIcon />
    case 4:
      return <PinPadIcon />
    case 5:
      return <CardReaderIcon />
    case 16:
      return <SoftwareIcon />
    case 6:
    case 7:
    case 8:
      return <CubeIcon />
    default:
      return null
  }
}
