import { FC, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar, Grid } from '@mui/material'

import { ApplicationTemplate } from '@shared/api'
import { ButtonBar, ButtonBarEnd } from '@shared/components'
import { useNotification, useSub } from '@shared/hooks'
import {
  WizardStep,
  WizardStepperButton,
  WizardStepperUtility,
} from '@shared/utils'

import { getWizardSteps } from '@/utils/application-templates/getWizardSteps'

import { excludedWizardStepperButtons } from '../../constants/excludedWizardStepperButtons'
import { AgentStep } from '../../enums'
import {
  APPLICATION_TEMPLATE_CHANGE_EVENT,
  ApplicationTemplateStepper,
} from '../application-template-stepper/ApplicationTemplateStepper'
import { ContinueButton } from '../buttons/ContinueButton'
import { QuitButton } from '../buttons/QuitButton'
import { SaveAndContinueButton } from '../buttons/SaveAndContinueButton'
import { SaveAndQuitButton } from '../buttons/SaveAndQuitButton'
import { templatesFormSchema } from '../templates-form/TemplatesForm'
import { WarningEditTemplate } from '../warning-edit-template/WarningEditTemplate'

const useStyles = tss
  .withName('ApplicationTemplateWrapper')
  .create(({ theme }) => ({
    appBar: {
      position: 'fixed',
      top: 'auto',
      bottom: 0,
      boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
    },
    secondaryButtonContainer: { marginRight: '16px' },
  }))

interface ApplicationTemplateWrapperProps {
  isEdit?: boolean
  onSubmit: (data: Partial<ApplicationTemplate>) => Promise<ApplicationTemplate>
}

// TODO (not an actual TODO, just explaining things) |
//
// Explanation / Purpose about the use of the WizardStepperUtility on each of the Steps:
//
// The Purpose of it is to tell to each step:
//
// - The URL of the previous and next steps when they need it, so that they don't have to hardcode that.
//
// - Tell at every given moment which buttons to display on the bottom
// of the Form, to determine that it will receive some data each Step needs to provide.
//
// At every WizardStepperUtility method you will always have to give pass to it:
//
// - wizardSteps (the Array that represents the whole steps of the process)
//
// - The currentStep (it means who step you are on currently), as you can see
// below on the CURRENT_WIZARD_STEP global variable.
const CURRENT_WIZARD_STEP: AgentStep = AgentStep.TEMPLATE_INFO

export const ApplicationTemplateWrapper: FC<
  ApplicationTemplateWrapperProps
> = ({ isEdit = false, onSubmit, children }) => {
  const { classes } = useStyles()
  const { templateId } = useParams()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { setNotification } = useNotification()

  const [warningData, setWarningData] = useState<{
    isOpen: boolean
    onConfirm: () => void
  }>({
    isOpen: false,
    onConfirm: () => {},
  })
  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const schema = templatesFormSchema(t)
  type Data = yup.InferType<typeof schema>
  const {
    watch,
    formState: { isSubmitting, isDirty },
    handleSubmit,
  } = useFormContext<Data>()

  const agentStep = watch('agent_steps')

  const [wizardSteps, setWizardSteps] = useState<WizardStep[]>(
    getWizardSteps({ t, templateId })
  )

  const updateWizardSteps = (templateId: string) => {
    const updatedWizardSteps = getWizardSteps({ t, templateId })
    setWizardSteps(updatedWizardSteps)

    return updatedWizardSteps
  }

  useSub<typeof APPLICATION_TEMPLATE_CHANGE_EVENT>(
    APPLICATION_TEMPLATE_CHANGE_EVENT.type,
    ({ data: template }) => setApplicationTemplate(template)
  )

  const quit = () => {
    navigate('/partner/application-templates')
  }

  const navigateToNextStep = (updatedWizardSteps?: typeof wizardSteps) => {
    navigate(
      WizardStepperUtility.nextStepUrl(
        updatedWizardSteps ?? wizardSteps,
        CURRENT_WIZARD_STEP
      )
    )
  }

  const enabledFormButtons = WizardStepperUtility.enabledButtons({
    wizardSteps,
    currentStep: CURRENT_WIZARD_STEP,
    thisPageDirty:
      isDirty ||
      (isEdit &&
        ((agentStep < AgentStep.TEMPLATE_INFO &&
          agentStep > AgentStep.COMPLETED) ||
          !!applicationTemplate?.is_legacy)),
    highestCompletedStep: agentStep,
    finishStep: AgentStep.COMPLETED,
  }).filter(
    (wizardStepperButton) =>
      !excludedWizardStepperButtons.includes(wizardStepperButton)
  )

  const submitWrapper = async (data: Data, shouldQuit: boolean = false) => {
    try {
      const { template_id } = await onSubmit(data as ApplicationTemplate)
      const updatedWizardSteps = updateWizardSteps(template_id)

      setWizardSteps(updatedWizardSteps)

      if (shouldQuit) {
        quit()
      } else {
        navigateToNextStep(updatedWizardSteps)
      }
    } catch (error) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })
    }
  }

  return (
    <>
      <WarningEditTemplate
        isOpen={warningData.isOpen}
        onClose={() => setWarningData({ ...warningData, isOpen: false })}
        onConfirm={warningData.onConfirm}
      />

      <Grid container>
        <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
          <ApplicationTemplateStepper activeStep={CURRENT_WIZARD_STEP} />
        </Grid>

        <Grid item xs={12} md={10} marginBottom={15}>
          {children}
        </Grid>
      </Grid>

      {((isEdit && agentStep >= AgentStep.COMPLETED) || !isEdit) && (
        <AppBar className={classes.appBar}>
          <ButtonBar style={{ marginBottom: '0 !important' }}>
            <ButtonBarEnd>
              {/* Don't map through the Array, use includes instead, Gabriel and I (David) say its cleaner, 
                also mapping was giving an error :).
                */}
              {enabledFormButtons.includes(WizardStepperButton.QUIT) && (
                <QuitButton
                  containerClassName={classes.secondaryButtonContainer}
                  onClick={() => {
                    quit()
                  }}
                />
              )}

              {enabledFormButtons.includes(WizardStepperButton.CONTINUE) && (
                <ContinueButton
                  onClick={() => {
                    navigateToNextStep()
                  }}
                  containerClassName={classes.secondaryButtonContainer}
                />
              )}

              {enabledFormButtons.includes(
                WizardStepperButton.SAVE_AND_QUIT
              ) && (
                <SaveAndQuitButton
                  containerClassName={classes.secondaryButtonContainer}
                  onClick={() => {
                    if (
                      agentStep === AgentStep.COMPLETED ||
                      applicationTemplate?.is_legacy
                    ) {
                      setWarningData({
                        isOpen: true,
                        onConfirm: handleSubmit((data) =>
                          submitWrapper(data, true)
                        ),
                      })
                    } else {
                      handleSubmit((data) => submitWrapper(data, true))()
                    }
                  }}
                  isLoading={isSubmitting}
                />
              )}

              {enabledFormButtons.includes(
                WizardStepperButton.SAVE_AND_CONTINUE
              ) && (
                <SaveAndContinueButton
                  onClick={() => {
                    if (
                      agentStep === AgentStep.COMPLETED ||
                      applicationTemplate?.is_legacy
                    ) {
                      setWarningData({
                        isOpen: true,
                        onConfirm: handleSubmit((data) => submitWrapper(data)),
                      })
                    } else {
                      handleSubmit((data) => submitWrapper(data))()
                    }
                  }}
                  isLoading={isSubmitting}
                />
              )}
            </ButtonBarEnd>
          </ButtonBar>
        </AppBar>
      )}
    </>
  )
}
