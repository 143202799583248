import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { api, ApplicationTemplate } from '@shared/api/src'
import { UnsavedWarning } from '@shared/components'
import { useFtpPortalHubCommunication } from '@shared/hooks'
import { clearBlankFields } from '@shared/utils'

import { ApplicationTemplateWrapper } from '../components/application-template-wrapper/ApplicationTemplateWrapper'
import {
  TemplatesForm,
  templatesFormSchema,
} from '../components/templates-form/TemplatesForm'
import { AgentStep } from '../enums'

const CURRENT_WIZARD_STEP: AgentStep = AgentStep.TEMPLATE_INFO

export const ApplicationTemplatesTemplateInfo: FC = () => {
  const { t } = useTranslation()

  const { setAppBarTitle } = useFtpPortalHubCommunication()

  const schema = templatesFormSchema(t)
  type Data = yup.InferType<typeof schema>

  const methods = useForm<Data>({
    defaultValues: {
      template_name: '',
      office_name: '',
      agent_id: '',
      application_type_code: undefined,
      ach_platform_code: undefined,
      ach_bank_code: undefined,
      business_category: '',
      business_type: '',
      business_description: '',
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const onSubmit: (data: Data) => Promise<ApplicationTemplate> = async (
    data
  ) => {
    const payload = clearBlankFields(data)

    const agentId = payload.agent_id || payload.office_name
    delete payload.office_name
    delete payload.agent_steps

    return await api.service('templates').create({
      ...payload,
      ach_bank_code: 1,
      agent_steps: CURRENT_WIZARD_STEP,
      agent_id: agentId,
    } as ApplicationTemplate)
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  return (
    <FormProvider {...methods}>
      <UnsavedWarning />
      <form noValidate>
        <ApplicationTemplateWrapper onSubmit={onSubmit}>
          <TemplatesForm />
        </ApplicationTemplateWrapper>
      </form>
    </FormProvider>
  )
}
