import { capitalize } from 'lodash'
import { FC, useCallback, useEffect, useMemo, useState } from 'react'
import { FormProvider, useForm, useWatch } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { AppBar, Box, Grid } from '@mui/material'

import {
  api,
  ApplicationTemplate,
  PartnerTemplateBasePricePlans,
  PricingPlanRequest,
} from '@shared/api'
import {
  ButtonBar,
  ButtonBarEnd,
  FieldGroupContainer,
  FieldGroupRow,
  Loading,
  PageLayoutContainer,
  UnsavedWarning,
} from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useNotification,
  usePub,
} from '@shared/hooks'
import {
  toArrayFieldErrors,
  WizardStepperButton,
  WizardStepperUtility,
} from '@shared/utils'

import { getWizardSteps } from '@/utils/application-templates/getWizardSteps'

import { CURRENT_PLAN_ID, PricingPlanSelector } from './PricingPlanSelector'
import { InputTypes, PricingPlanGroup } from './types'
import { formatFieldName, truncateToPrecision } from './utils'
import { formatFieldNameTerm } from './utils/formatFieldName'
import {
  APPLICATION_TEMPLATE_CHANGE_EVENT,
  ApplicationTemplateStepper,
} from '../components/application-template-stepper/ApplicationTemplateStepper'
import { DoneButton } from '../components/buttons/DoneButton'
import { QuitButton } from '../components/buttons/QuitButton'
import { InactiveTemplateBanner } from '../components/inactive-template-banner/InactiveTemplateBanner'
import { PricingPlanField } from '../components/pricing-plan-field/PricingPlanField'
import { excludedWizardStepperButtons } from '../constants/excludedWizardStepperButtons'
import { AgentStep } from '../enums'

const useStyles = tss.withName('PricingPlan').create(() => ({
  container: {
    background: 'unset',
    paddingRight: 0,
  },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
    width: '100%',
  },
  fieldGroupContainer: {
    padding: '1em',
    background: 'white',
    borderRadius: '.5em',
  },
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
  secondaryButtonContainer: { marginRight: '16px' },
}))

const CURRENT_WIZARD_STEP = AgentStep.PRICING_PLAN

export const PricingPlan: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const navigate = useNavigate()
  const { templateId } = useParams()

  const publish = usePub()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [isLoadingGroups, setIsLoadingGroups] = useState<boolean>(false)
  const [groups, setGroups] = useState<PricingPlanGroup[]>([])

  const formMethods = useForm()

  const {
    getValues,
    setError,
    setValue,
    formState: { isDirty },
  } = formMethods

  const pricing_plan = useWatch({
    control: formMethods.control,
    name: 'pricing_plan',
  })

  // State and Methods related to Wizard Stepper
  const wizardSteps = useMemo(
    () => getWizardSteps({ t, templateId }),
    [templateId]
  )
  const highestCompletedStep: AgentStep = applicationTemplate?.agent_steps

  const quit = () => {
    navigate('/partner/application-templates')
  }

  const enabledFormButtons = WizardStepperUtility.enabledButtons({
    wizardSteps,
    currentStep: CURRENT_WIZARD_STEP,
    thisPageDirty: isDirty, // Might cause lag, investigate on https://zeamster.atlassian.net/browse/PPW-907
    highestCompletedStep,
    finishStep: AgentStep.COMPLETED,
  }).filter(
    (wizardStepperButton) =>
      !excludedWizardStepperButtons.includes(wizardStepperButton)
  )

  const getApplicationTemplate = async () => {
    try {
      const applicationTemplate = await api.service('templates').get(templateId)

      publish(APPLICATION_TEMPLATE_CHANGE_EVENT, applicationTemplate)
      setApplicationTemplate(applicationTemplate)
    } catch (error) {
      setNotification({ type: 'error', label: error })
    }
  }

  const generateFieldGroups = useCallback(
    (billItems: PartnerTemplateBasePricePlans['bill_items']) => {
      let groupsMap = []

      billItems.forEach((item) => {
        if (
          groupsMap.filter((group) => group.group_id === item.group_id)
            .length === 0
        ) {
          groupsMap.push({
            group_name: item.group_name,
            group_id: item.group_id,
            group_sort_order: item.group_sort_order,
            inputs: billItems
              .filter((input) => input.group_id === item.group_id)
              .map((input) => ({
                item_id: input.item_id,
                item_term: input.item_term,
                index: input.item_sort_order,
                label: input.item_name,
                type: input.display_type_code,
                value: input.item_value,
                options: input.valid_values.map((value) => ({
                  value: value.value,
                  label: value.description,
                })),
                terms_options: input.valid_terms.map((term) => ({
                  value: term.value,
                  label: term.description,
                })),
                editable: input.editable,
                precision: input.precision,
              }))
              .sort((a, b) => a.index - b.index),
          })
        }
      })

      groupsMap.sort((a, b) => a.group_sort_order - b.group_sort_order)

      let overallIndexCounter = 0
      groupsMap = groupsMap.map((group) => ({
        ...group,
        inputs: group.inputs.map((input) => ({
          ...input,
          overallIndex: overallIndexCounter++,
        })),
      }))

      setGroups(groupsMap)
    },
    []
  )

  const setFieldValues = () => {
    groups.map((group) => {
      group.inputs.map((input) => {
        let value = input.value
        if (input.precision && input.options.length === 0 && input.editable) {
          value = truncateToPrecision(input.value as number, input.precision)
        } else {
          value = value.toString()
        }
        setValue(formatFieldName(input.item_id), value)
        if (input.item_term) {
          setValue(
            formatFieldNameTerm(input.item_id),
            input.item_term.toString()
          )
        }
      })
    })
  }

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)
      let bill_items = []

      groups.forEach((group) => {
        group.inputs.forEach((input) => {
          let value: string | number = getValues(
            formatFieldName(input.item_id)
          ) as string

          if (typeof value === 'string') {
            value = value.replace(/,/g, '')
            value = parseFloat(value)
          }

          bill_items.push({
            item_id: input.item_id,
            item_term: getValues(formatFieldNameTerm(input.item_id)),
            item_value: value,
          })
        })
      })

      let data: PricingPlanRequest = {
        bill_items,
      }

      if (pricing_plan !== CURRENT_PLAN_ID) {
        data = {
          ...data,
          plan_id: pricing_plan,
        }
      }

      await api.service('templates').putPricingPlan(templateId, data)
      await api.service('templates').activate(templateId)

      if (!!applicationTemplate?.pricing_plan_name) {
        setNotification({
          label: t(
            'partner-portal.application-templates.template-successfully-updated'
          ),
        })
      } else {
        setNotification({
          label: t(
            'partner-portal.application-templates.template-successfully-created'
          ),
        })
      }
      quit()
    } catch (e) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })

      const errors = toArrayFieldErrors(e)

      errors.forEach((error) => {
        if (error.field !== 'item_value' && error.field !== 'item_term') {
          return
        }

        const regex = /bill_items\[(.*?)\]/

        const billItemErrorId =
          error.message.match(regex)?.[1] ||
          error.context?.label.match(regex)?.[1]

        // Need to check this because the API returns different identifiers for the input depending on the error
        const itemId = billItemErrorId.includes('item_id')
          ? billItemErrorId.split('=')[1].trim()
          : groups
              .flatMap((group) => group.inputs)
              .find((input) => input.overallIndex === parseInt(billItemErrorId))
              .item_id.toString()

        const fieldName =
          error.field === 'item_value'
            ? formatFieldName(parseInt(itemId))
            : formatFieldNameTerm(parseInt(itemId))

        const errorMessage = error.message.split('item_value')

        setError(fieldName, {
          message: capitalize(errorMessage[1]?.trim() || errorMessage[0]),
        })
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))

    getApplicationTemplate()
  }, [])

  useEffect(() => {
    setFieldValues()
  }, [groups])

  // Rendering all the inputs can be expensive and affects heavily on performance. Avoid it by using useMemo.
  const inputs = useMemo(
    () =>
      groups?.map((group, index) => (
        <FieldGroupContainer
          key={index}
          title={group.group_name}
          className={classes.fieldGroupContainer}
          titleTestID={`group-${group.group_id}`}
        >
          <FieldGroupRow spacing={2}>
            {group?.inputs?.map((input, index2) => (
              <PricingPlanField
                key={index2}
                groupInput={input}
                testId={`input-${input.index}-group-${group.group_id}`}
              />
            ))}
          </FieldGroupRow>
        </FieldGroupContainer>
      )),
    [groups]
  )

  return (
    <>
      <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
        <Grid container>
          <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
            <ApplicationTemplateStepper activeStep={CURRENT_WIZARD_STEP} />
          </Grid>

          {Boolean(applicationTemplate?.application_type_code) && (
            <Grid item xs={12} md={10}>
              {applicationTemplate?.agent_steps >= AgentStep.TEMPLATE_INFO && (
                <InactiveTemplateBanner />
              )}

              <FormProvider {...formMethods}>
                <UnsavedWarning />
                <form noValidate>
                  <PricingPlanSelector
                    applicationTemplate={applicationTemplate}
                    generateFieldGroups={generateFieldGroups}
                    setIsLoadingGroups={setIsLoadingGroups}
                  />

                  {!isLoadingGroups ? (
                    inputs
                  ) : (
                    <Box className={classes.loadingContainer}>
                      <Loading />
                    </Box>
                  )}

                  <AppBar className={classes.appBar}>
                    <ButtonBar style={{ marginBottom: '0 !important' }}>
                      <ButtonBarEnd>
                        {enabledFormButtons.includes(
                          WizardStepperButton.QUIT
                        ) && (
                          <QuitButton
                            containerClassName={
                              classes.secondaryButtonContainer
                            }
                            onClick={() => {
                              quit()
                            }}
                          />
                        )}

                        {enabledFormButtons.includes(
                          WizardStepperButton.DONE
                        ) && (
                          <DoneButton
                            onClick={formMethods.handleSubmit(onSubmit)}
                            isLoading={isSubmitting}
                            isDisabled={!pricing_plan || isLoadingGroups}
                          />
                        )}
                      </ButtonBarEnd>
                    </ButtonBar>
                  </AppBar>
                </form>
              </FormProvider>
            </Grid>
          )}
        </Grid>
      </PageLayoutContainer>
    </>
  )
}
