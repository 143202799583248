import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { api, ApplicationTemplate } from '@shared/api'
import { ActionModal, UnsavedWarning } from '@shared/components'
import { useFtpPortalHubCommunication, useSub } from '@shared/hooks'
import { nullifyEmptyStrings } from '@shared/utils'

import { APPLICATION_TEMPLATE_CHANGE_EVENT } from '../components/application-template-stepper/ApplicationTemplateStepper'
import { ApplicationTemplateWrapper } from '../components/application-template-wrapper/ApplicationTemplateWrapper'
import {
  TemplatesForm,
  templatesFormSchema,
} from '../components/templates-form/TemplatesForm'
import { AgentStep } from '../enums'

const useStyles = tss.withName('ApplicationTemplatesEdit').create(() => ({
  buttonContainer: {
    width: '100%',
  },
}))

export const ApplicationTemplatesEdit: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { templateId } = useParams()
  const navigate = useNavigate()

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)

  const schema = templatesFormSchema(t)
  type Data = yup.InferType<typeof schema>
  const methods = useForm<Data>({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  useSub<typeof APPLICATION_TEMPLATE_CHANGE_EVENT>(
    APPLICATION_TEMPLATE_CHANGE_EVENT.type,
    ({ data: template }) => setApplicationTemplate(template)
  )

  useEffect(() => {
    setAppBarTitle(
      t('partner-portal.application-templates.edit-template'),
      undefined,
      undefined,
      '/partner/application-templates'
    )
  }, [])

  const onSubmit = async (data: Data) => {
    const agentId = data.agent_id || data.office_name
    delete data.template_name
    delete data.office_name

    let payload = {
      ...data,
      agent_steps: AgentStep.TEMPLATE_INFO,
      agent_id: agentId,
    } as ApplicationTemplate

    try {
      payload = nullifyEmptyStrings(payload)

      return await api.service('templates').patch(templateId, payload)
    } catch (error) {
      // Only display modal if the patch was rejected due to the template being legacy
      // This means the migration failed. Otherwise, throw the error to be handled by ApplicationTemplateWrapper.tsx
      if (applicationTemplate.is_legacy) {
        setIsModalOpen(true)
      } else {
        throw error
      }
    }
  }

  return (
    <FormProvider {...methods}>
      <ActionModal
        open={isModalOpen}
        title={t(
          'partner-portal.application-templates.legacy-template-migration-failed'
        )}
        buttons={[
          {
            label: t('common.ok'),
            onClick: () => {
              setIsModalOpen(false)
              navigate('/partner/application-templates')
            },
            containerClassName: classes.buttonContainer,
          },
        ]}
      >
        {t(
          'partner-portal.application-templates.legacy-template-migration-failed-description'
        )}
      </ActionModal>
      <UnsavedWarning />
      <form noValidate>
        <ApplicationTemplateWrapper isEdit onSubmit={onSubmit}>
          <TemplatesForm isEdit />
        </ApplicationTemplateWrapper>
      </form>
    </FormProvider>
  )
}
