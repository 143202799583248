import { yupResolver } from '@hookform/resolvers/yup'
import { TFunction } from 'i18next'
import { FC, useEffect, useState } from 'react'
import { FormProvider, FormState, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { AppBar } from '@mui/material'

import { api, FeathersError, PaymentAndVelocity } from '@shared/api'
import { valueToNumber } from '@shared/api/src/utils/valueToNumber'
import {
  ButtonBar,
  ButtonBarEnd,
  PageLayoutContainerMain,
  UnsavedWarning,
} from '@shared/components'
import { useNotification } from '@shared/hooks'
import {
  toArrayFieldErrors,
  WizardStepperButton,
  WizardStepperUtility,
} from '@shared/utils'

import { getCCPaymentAndVelocityTotalPercent } from '@/utils/application-templates/getCCPaymentAndVelocityTotalPercent'
import { getWizardSteps } from '@/utils/application-templates/getWizardSteps'

import { excludedWizardStepperButtons } from '../../constants/excludedWizardStepperButtons'
import { AgentStep, ApplicationTypeCode } from '../../enums'
import { CURRENT_WIZARD_STEP } from '../../payment-and-velocity/PaymentAndVelocity'
import { ACHPaymentAndVelocityFields } from '../ach-payment-and-velocity-fields/ACHPaymentAndVelocityFields'
import { ContinueButton } from '../buttons/ContinueButton'
import { QuitButton } from '../buttons/QuitButton'
import { SaveAndContinueButton } from '../buttons/SaveAndContinueButton'
import { SaveAndQuitButton } from '../buttons/SaveAndQuitButton'
import { CCPaymentAndVelocityFields } from '../cc-payment-and-velocity-fields/CCPaymentAndVelocityFields'
import { InactiveTemplateBanner } from '../inactive-template-banner/InactiveTemplateBanner'
import { WarningEditTemplate } from '../warning-edit-template/WarningEditTemplate'

const showCCFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.CC ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const showACHFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.ACH ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const buildCCPaymentAndVelocitySchema = (t: TFunction) => {
  const isValidPercent = (percent) =>
    percent !== '' && Number(percent) >= 0 && Number(percent) <= 100

  const whenPercentFieldChangesOptions = {
    is: (value) => !isValidPercent(value),
    then: (schema: yup.NumberSchema) => {
      const validationMessage = t(
        'partner-portal.application-templates.you-must-enter-a-value-between-0-and-100'
      )

      return schema
        .test({
          message: validationMessage,
          test: isValidPercent,
        })
        .required(validationMessage)
    },
  }

  // See: https://github.com/jquense/yup/issues/193#issuecomment-437878160
  const schemaCyclicDependenciesKeys: [string, string][] = [
    ['swiped_percent', 'swiped_percent'],
    ['keyed_percent', 'keyed_percent'],
    ['ecommerce_percent', 'ecommerce_percent'],
  ]

  return yup.object().shape(
    {
      swiped_percent: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue))
        .when('swiped_percent', whenPercentFieldChangesOptions),
      keyed_percent: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue))
        .when('keyed_percent', whenPercentFieldChangesOptions),
      ecommerce_percent: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue))
        .when('ecommerce_percent', whenPercentFieldChangesOptions),

      cc_monthly_volume: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue)),
      cc_high_ticket: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue)),
      cc_average_ticket: yup
        .number()
        .transform((_, originalValue) => valueToNumber(originalValue)),

      addon_amex_direct: yup.boolean(),
      addon_discover_direct: yup.boolean(),
      addon_pin_debit: yup.boolean(),
      addon_wireless: yup.boolean(),
      addon_gateway: yup.boolean(),
      addon_clover: yup.boolean(),
      addon_insights: yup.boolean(),
      addon_payeezy: yup.boolean(),
    },
    schemaCyclicDependenciesKeys
  )
}

const buildACHPaymentAndVelocitySchema = (_: TFunction) =>
  yup.object().shape({
    ec_monthly_volume: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_average_ticket: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_high_ticket: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),

    ec_debit_max_per_ticket: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_debit_max_daily_count: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_debit_max_daily_amount: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_debit_max_14day_count: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_debit_max_14day_amount: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),

    ec_credit_max_per_ticket: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_credit_max_daily_count: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_credit_max_daily_amount: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_credit_max_14day_count: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),
    ec_credit_max_14day_amount: yup
      .number()
      .transform((_, originalValue) => valueToNumber(originalValue)),

    ccd: yup.boolean(),
    ppd: yup.boolean(),
    web: yup.boolean(),
    tel: yup.boolean(),
  })

const buildSchema = (
  t: TFunction,
  applicationTypeCode: ApplicationTypeCode
) => {
  let schema = yup.object()

  if (showCCFields(applicationTypeCode)) {
    schema = schema.concat(buildCCPaymentAndVelocitySchema(t))
  }

  if (showACHFields(applicationTypeCode)) {
    schema = schema.concat(buildACHPaymentAndVelocitySchema(t))
  }

  return schema
}

export type CCPaymentAndVelocityData = yup.InferType<
  ReturnType<typeof buildCCPaymentAndVelocitySchema>
>

export type ACHPaymentAndVelocityData = yup.InferType<
  ReturnType<typeof buildACHPaymentAndVelocitySchema>
>

export type Payload = CCPaymentAndVelocityData &
  ACHPaymentAndVelocityData & {
    sec_codes?: string[]
    service_types?: string[]
  }

const getDefaultValues = (
  paymentAndVelocity: PaymentAndVelocity
): Partial<Payload> => {
  let ccPaymentAndVelocityDefaultValues: Partial<CCPaymentAndVelocityData> = {}
  let achPaymentAndVelocityDefaultValues: Partial<ACHPaymentAndVelocityData> =
    {}

  ccPaymentAndVelocityDefaultValues = showCCFields(
    paymentAndVelocity.application_type_code
  )
    ? {
        swiped_percent: paymentAndVelocity.swiped_percent,
        keyed_percent: paymentAndVelocity.keyed_percent,
        ecommerce_percent: paymentAndVelocity.ecommerce_percent,

        cc_monthly_volume: paymentAndVelocity.cc_monthly_volume
          ? paymentAndVelocity.cc_monthly_volume / 100
          : undefined,
        cc_high_ticket: paymentAndVelocity.cc_high_ticket
          ? paymentAndVelocity.cc_high_ticket / 100
          : undefined,
        cc_average_ticket: paymentAndVelocity.cc_average_ticket
          ? paymentAndVelocity.cc_average_ticket / 100
          : undefined,

        addon_amex_direct: paymentAndVelocity.service_types?.includes('Amex'),
        addon_discover_direct:
          paymentAndVelocity.service_types?.includes('eCheck'),
        addon_pin_debit: paymentAndVelocity.service_types?.includes('PINDebit'),
        addon_wireless: paymentAndVelocity.service_types?.includes('Wireless'),
        addon_gateway: paymentAndVelocity.service_types?.includes('Gateway'),
        addon_clover: paymentAndVelocity.service_types?.includes('Clover'),
        addon_insights:
          paymentAndVelocity.service_types?.includes('Insightics'),
        addon_payeezy: paymentAndVelocity.service_types?.includes('Payeezy'),
      }
    : {}

  achPaymentAndVelocityDefaultValues = showACHFields(
    paymentAndVelocity.application_type_code
  )
    ? {
        ec_monthly_volume: paymentAndVelocity.ec_monthly_volume
          ? paymentAndVelocity.ec_monthly_volume / 100
          : undefined,
        ec_average_ticket: paymentAndVelocity.ec_average_ticket
          ? paymentAndVelocity.ec_average_ticket / 100
          : undefined,
        ec_high_ticket: paymentAndVelocity.ec_high_ticket
          ? paymentAndVelocity.ec_high_ticket / 100
          : undefined,

        ec_debit_max_per_ticket: paymentAndVelocity.ec_debit_max_per_ticket
          ? paymentAndVelocity.ec_debit_max_per_ticket / 100
          : undefined,
        ec_debit_max_daily_count: paymentAndVelocity.ec_debit_max_daily_count,
        ec_debit_max_14day_amount: paymentAndVelocity.ec_debit_max_14day_amount
          ? paymentAndVelocity.ec_debit_max_14day_amount / 100
          : undefined,
        ec_debit_max_14day_count: paymentAndVelocity.ec_debit_max_14day_count,
        ec_debit_max_daily_amount: paymentAndVelocity.ec_debit_max_daily_amount
          ? paymentAndVelocity.ec_debit_max_daily_amount / 100
          : undefined,

        ec_credit_max_per_ticket: paymentAndVelocity.ec_credit_max_per_ticket
          ? paymentAndVelocity.ec_credit_max_per_ticket / 100
          : undefined,
        ec_credit_max_daily_count: paymentAndVelocity.ec_credit_max_daily_count,
        ec_credit_max_daily_amount:
          paymentAndVelocity.ec_credit_max_daily_amount
            ? paymentAndVelocity.ec_credit_max_daily_amount / 100
            : undefined,
        ec_credit_max_14day_count: paymentAndVelocity.ec_credit_max_14day_count,
        ec_credit_max_14day_amount:
          paymentAndVelocity.ec_credit_max_14day_amount
            ? paymentAndVelocity.ec_credit_max_14day_amount / 100
            : undefined,

        ccd:
          paymentAndVelocity.sec_codes === null
            ? true
            : paymentAndVelocity.sec_codes?.includes('CCD'),
        ppd:
          paymentAndVelocity.sec_codes === null
            ? true
            : paymentAndVelocity.sec_codes?.includes('PPD'),
        web:
          paymentAndVelocity.sec_codes === null
            ? true
            : paymentAndVelocity.sec_codes?.includes('WEB'),
        tel: paymentAndVelocity.sec_codes?.includes('TEL'),
      }
    : {}

  return {
    ...ccPaymentAndVelocityDefaultValues,
    ...achPaymentAndVelocityDefaultValues,
  }
}

const getDirtyFields = (
  payload: Payload,
  dirtyFields: FormState<Payload>['dirtyFields']
): Partial<Payload> => {
  return Object.fromEntries(
    Object.entries(payload).filter(([key, value]) =>
      typeof value !== 'boolean' ? dirtyFields[key] : [key, value]
    )
  ) as Partial<Payload>
}

const formatDollarFields = (data: Payload): Partial<Payload> => {
  const multipleOfHundredFields = [
    'cc_monthly_volume',
    'cc_high_ticket',
    'cc_average_ticket',
    'ec_monthly_volume',
    'ec_average_ticket',
    'ec_high_ticket',
    'ec_debit_max_per_ticket',
    'ec_debit_max_14day_amount',
    'ec_debit_max_daily_amount',
    'ec_credit_max_per_ticket',
    'ec_credit_max_daily_amount',
    'ec_credit_max_14day_amount',
  ]

  const formattedFields = Object.entries(data).map(([key, value]) => {
    if (multipleOfHundredFields.includes(key)) {
      return [key, parseInt(value.toString().replace(/,/g, '')) * 100]
    } else {
      return [key, value]
    }
  })

  return Object.fromEntries(formattedFields)
}

const formatCheckboxes = (data: Payload): Partial<Payload> => {
  const serviceTypes = {
    addon_amex_direct: 'Amex',
    addon_gateway: 'Gateway',
    addon_discover_direct: 'eCheck',
    addon_clover: 'Clover',
    addon_pin_debit: 'PINDebit',
    addon_insights: 'Insightics',
    addon_wireless: 'Wireless',
    addon_payeezy: 'Payeezy',
  }

  const secCodes = {
    ccd: 'CCD',
    ppd: 'PPD',
    web: 'WEB',
    tel: 'TEL',
  }

  const serviceTypesArray: string[] = []
  const secCodesArray: string[] = []

  Object.entries(data).map(([key, value]) => {
    if (key in serviceTypes) {
      delete data[key]

      if (value) {
        serviceTypesArray.push(serviceTypes[key])
      }
    } else if (key in secCodes) {
      delete data[key]

      if (value) {
        secCodesArray.push(secCodes[key])
      }
    }
  })

  let formattedFields = data

  if (serviceTypesArray.length > 0) {
    formattedFields = { ...formattedFields, service_types: serviceTypesArray }
  }

  if (secCodesArray.length > 0) {
    formattedFields = { ...formattedFields, sec_codes: secCodesArray }
  }

  return formattedFields
}

const useStyles = tss.withName('CCACHPaymentAndVelocityForm').create(() => ({
  appBar: {
    position: 'fixed',
    top: 'auto',
    bottom: 0,
    boxShadow: '0px -12px 79.9px 0px rgba(0, 0, 0, 0.10)',
  },
  quitButtonContainer: { marginRight: '16px' },
}))

export interface CCACHPaymentAndVelocityFormProps {
  paymentAndVelocity: PaymentAndVelocity | null
}

export const CCACHPaymentAndVelocityForm: FC<
  CCACHPaymentAndVelocityFormProps
> = ({ paymentAndVelocity }) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { templateId } = useParams()
  const navigate = useNavigate()
  const { setNotification } = useNotification()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

  const [warningData, setWarningData] = useState<{
    isOpen: boolean
    onConfirm: () => void
  }>({
    isOpen: false,
    onConfirm: () => {},
  })

  const schema = buildSchema(t, paymentAndVelocity.application_type_code)
  type Data = yup.InferType<typeof schema>

  const formMethods = useForm<Payload>({
    defaultValues: getDefaultValues(paymentAndVelocity),
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  const {
    watch,
    formState: { dirtyFields, isDirty },
  } = formMethods

  const { swiped_percent, keyed_percent, ecommerce_percent } = watch()

  const [isInvalidTotalPercent, setIsInvalidTotalPercent] = useState(false)

  // State and Methods related to Wizard Stepper
  const wizardSteps = getWizardSteps({ t, templateId })
  const highestCompletedStep: AgentStep = paymentAndVelocity?.agent_steps

  const quit = () => {
    navigate('/partner/application-templates')
  }

  const navigateToNextStep = (updatedWizardSteps?: typeof wizardSteps) => {
    navigate(
      // `/partner/application-templates/${result.template_id}/payment-and-velocity`
      WizardStepperUtility.nextStepUrl(
        updatedWizardSteps ?? wizardSteps,
        CURRENT_WIZARD_STEP
      )
    )
  }

  const enabledFormButtons = WizardStepperUtility.enabledButtons({
    wizardSteps,
    currentStep: CURRENT_WIZARD_STEP,
    thisPageDirty: isDirty,
    highestCompletedStep,
    finishStep: AgentStep.COMPLETED,
  }).filter(
    (wizardStepperButton) =>
      !excludedWizardStepperButtons.includes(wizardStepperButton)
  )

  const onSubmit: (
    payload: Payload,
    shouldQuit?: boolean
  ) => Promise<void> = async (payload, shouldQuit = false) => {
    try {
      setIsSubmitting(true)
      let data = getDirtyFields(payload, dirtyFields)
      data = formatDollarFields(data)
      data = formatCheckboxes(data)

      const isInvalidTotalPercent = Boolean(
        (data['swiped_percent'] ||
          data['keyed_percent'] ||
          data['ecommerce_percent']) &&
          getCCPaymentAndVelocityTotalPercent({
            swiped_percent,
            keyed_percent,
            ecommerce_percent,
          }) !== '100'
      )

      setIsInvalidTotalPercent(isInvalidTotalPercent)

      if (isInvalidTotalPercent) {
        return
      }

      const entries = Object.entries(data)

      await api.service('templates').patchPaymentVelocity({
        templateId,
        body: {
          ...(entries.length ? data : {}),
          agent_steps: AgentStep.PAYMENT_AND_VELOCITY,
        } as unknown as PaymentAndVelocity,
      })

      if (shouldQuit) {
        quit()
      } else {
        navigate(
          WizardStepperUtility.nextStepUrl(
            wizardSteps,
            AgentStep.PAYMENT_AND_VELOCITY
          )
        )
      }
    } catch (e) {
      setNotification({
        label: t('common.please-review-information'),
        type: 'error',
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  useEffect(() => {
    const onAPIError = (error: FeathersError) => {
      if (!error.data?.error) return
      switch (error.data.error.statusCode) {
        case 412:
          error.data.error.meta?.details?.forEach((errorDetail) => {
            const fieldPath = errorDetail.context.label
            const fieldName = errorDetail.context.key
            const message = errorDetail.message.replace(
              `"${fieldPath}"`,
              fieldName
            )
            formMethods.setError(fieldName as keyof Payload, {
              message,
            })
          })
          break
        case 422:
          const details = error.data.error.meta?.errors || {}
          Object.entries(details).forEach(([key, messagesArray]) => {
            const [message] = messagesArray as string[]
            formMethods.setError(key as keyof Payload, {
              message,
            })
          })
          break
        default:
          setNotification({
            label: error.data.error.detail,
            type: 'error',
          })
      }
    }
    api.on('apiError', onAPIError)
    return () => {
      api.off('apiError', onAPIError)
    }
  }, [formMethods, setNotification])

  useEffect(() => {
    const newTotalPercent = getCCPaymentAndVelocityTotalPercent({
      swiped_percent,
      keyed_percent,
      ecommerce_percent,
    })
    if (isInvalidTotalPercent && newTotalPercent === '100') {
      setIsInvalidTotalPercent(false)
    }
  }, [swiped_percent, keyed_percent, ecommerce_percent])

  return (
    <>
      <WarningEditTemplate
        isOpen={warningData.isOpen}
        onConfirm={warningData.onConfirm}
        onClose={() => setWarningData({ ...warningData, isOpen: false })}
      />
      {paymentAndVelocity.agent_steps >= AgentStep.TEMPLATE_INFO && (
        <InactiveTemplateBanner />
      )}
      <PageLayoutContainerMain isFullWidth>
        <FormProvider {...formMethods}>
          <UnsavedWarning />
          <form>
            {showCCFields(paymentAndVelocity.application_type_code) && (
              <CCPaymentAndVelocityFields
                isInvalidTotalPercent={isInvalidTotalPercent}
              />
            )}

            {showACHFields(paymentAndVelocity.application_type_code) && (
              <ACHPaymentAndVelocityFields />
            )}

            <AppBar className={classes.appBar}>
              <ButtonBar style={{ marginBottom: '0 !important' }}>
                <ButtonBarEnd>
                  {enabledFormButtons.includes(WizardStepperButton.QUIT) && (
                    <QuitButton
                      containerClassName={classes.quitButtonContainer}
                      onClick={() => {
                        quit()
                      }}
                    />
                  )}

                  {enabledFormButtons.includes(
                    WizardStepperButton.CONTINUE
                  ) && (
                    <ContinueButton
                      containerClassName={classes.quitButtonContainer}
                      onClick={() => {
                        navigateToNextStep()
                      }}
                    />
                  )}

                  {enabledFormButtons.includes(
                    WizardStepperButton.SAVE_AND_QUIT
                  ) && (
                    <SaveAndQuitButton
                      containerClassName={classes.quitButtonContainer}
                      onClick={() => {
                        if (
                          paymentAndVelocity?.agent_steps ===
                          AgentStep.COMPLETED
                        ) {
                          setWarningData({
                            isOpen: true,
                            onConfirm: formMethods.handleSubmit((data) =>
                              onSubmit(data, true)
                            ),
                          })
                        } else {
                          formMethods.handleSubmit((data) =>
                            onSubmit(data, true)
                          )()
                        }
                      }}
                      isLoading={isSubmitting}
                    />
                  )}

                  {enabledFormButtons.includes(
                    WizardStepperButton.SAVE_AND_CONTINUE
                  ) && (
                    <SaveAndContinueButton
                      onClick={() => {
                        if (
                          paymentAndVelocity?.agent_steps ===
                          AgentStep.COMPLETED
                        ) {
                          setWarningData({
                            isOpen: true,
                            onConfirm: formMethods.handleSubmit((data) =>
                              onSubmit(data)
                            ),
                          })
                        } else {
                          formMethods.handleSubmit((data) => onSubmit(data))()
                        }
                      }}
                      isLoading={isSubmitting}
                    />
                  )}
                </ButtonBarEnd>
              </ButtonBar>
            </AppBar>
          </form>
        </FormProvider>
      </PageLayoutContainerMain>
    </>
  )
}
