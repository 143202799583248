import { FC, useEffect, useState } from 'react'
import {
  Controller,
  useFormContext,
  useFormState,
  useWatch,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { SelectChangeEvent } from '@mui/material'

import {
  api,
  ApplicationTemplate,
  PartnerTemplateBasePricePlans,
  PartnerTemplatePricingPlan,
} from '@shared/api'
import {
  FieldGroupContainer,
  Loading,
  SelectComponent,
  SelectOption,
} from '@shared/components'
import { useNotification } from '@shared/hooks'

import { ConfirmChangePricingPlan } from '../components/confirm-change-pricing-plan/ConfirmChangePricingPlan'

interface PricingPlanSelectorProps {
  applicationTemplate: ApplicationTemplate
  generateFieldGroups: (
    billItems: PartnerTemplateBasePricePlans['bill_items']
  ) => void
  setIsLoadingGroups: (isLoading: boolean) => void
}

const useStyles = tss.withName('PricingPlanSelector').create(() => ({
  fieldGroupContainer: {
    padding: '1em',
    background: 'white',
    borderRadius: '.5em',
  },
}))

// The current plan the template has assigned won't return a plan_id, so we can safely use '0' as the current plan id.
// All real plan ids will be greater than 0.
export const CURRENT_PLAN_ID = '0'

export const PricingPlanSelector: FC<PricingPlanSelectorProps> = ({
  applicationTemplate,
  generateFieldGroups,
  setIsLoadingGroups,
}) => {
  const {
    clearErrors,
    setValue,
    control,
    formState: { errors },
  } = useFormContext()

  const { isDirty } = useFormState({
    control,
  })

  const { t } = useTranslation()
  const { classes } = useStyles()
  const { templateId } = useParams()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [showChangePlanConfirmation, setShowChangePlanConfirmation] =
    useState(false)
  const [pendingPricingPlanChange, setPendingPricingPlanChange] = useState<
    string | null
  >(null)
  const [pricingPlans, setPricingPlans] = useState<SelectOption<string>[]>([])
  const [pricePlanAssigned, setPricePlanAssigned] =
    useState<PartnerTemplatePricingPlan>(null)

  const handlePricingPlanChange = (newValue: string) => {
    if (isDirty || pricePlanAssigned) {
      setPendingPricingPlanChange(newValue)
      setShowChangePlanConfirmation(true)
    } else {
      clearErrors()
      getPricingPlanDetails(newValue)
      setValue('pricing_plan', newValue, { shouldDirty: true })
    }
  }

  const getPricingPlans = async () => {
    try {
      setIsLoading(true)

      const pricingPlans = await api
        .service('templates')
        .getPricingPlans(templateId)

      let arrayPricingPlans: SelectOption<string>[] = []

      pricingPlans.forEach((pricingPlan) => {
        arrayPricingPlans.push({
          value: pricingPlan.plan_id,
          label: `${pricingPlan.model_description}: ${pricingPlan.plan_name}`,
        })
      })

      arrayPricingPlans = arrayPricingPlans.sort((a, b) =>
        a.label.localeCompare(b.label)
      )

      if (applicationTemplate.pricing_plan_name) {
        const currentPlan = await getPricePlanStepData()

        arrayPricingPlans.unshift({
          value: CURRENT_PLAN_ID,
          label: `${currentPlan.model_description}: ${currentPlan.plan_name}`,
        })

        setValue('pricing_plan', CURRENT_PLAN_ID)
        generateFieldGroups(currentPlan.bill_items)
      }

      setPricingPlans(arrayPricingPlans)
    } catch (error) {
      setNotification({
        label: t(
          'partner-portal.application-templates.error-fetching-pricing-plans'
        ),
        type: 'error',
      })
    } finally {
      setIsLoading(false)
    }
  }

  const getPricePlanStepData = async () => {
    try {
      const pricePlanData = await api
        .service('templates')
        .getPricingPlan(templateId)

      setPricePlanAssigned(pricePlanData)

      return pricePlanData
    } catch (error) {
      setNotification({
        label: t('common.error-loading-data'),
        type: 'error',
      })
    }
  }

  const getPricingPlanDetails = async (planId: string) => {
    try {
      setIsLoadingGroups(true)

      const pricingPlanDetails =
        planId === CURRENT_PLAN_ID
          ? pricePlanAssigned
          : await api
              .service('templates')
              .getPricingPlanDetails({ templateId, planId })

      generateFieldGroups(pricingPlanDetails.bill_items)

      setIsLoadingGroups(false)
    } catch (error) {
      setNotification({
        label: t(
          'partner-portal.application-templates.error-fetching-pricing-plans'
        ),
        type: 'error',
      })
    }
  }

  const confirmPlanChange = () => {
    if (pendingPricingPlanChange) {
      setValue('pricing_plan', pendingPricingPlanChange, { shouldDirty: true })
      clearErrors()
      getPricingPlanDetails(pendingPricingPlanChange)
    }
    setPendingPricingPlanChange(null)
    setShowChangePlanConfirmation(false)
  }

  const cancelPlanChange = () => {
    setPendingPricingPlanChange(null)
    setShowChangePlanConfirmation(false)
  }

  useEffect(() => {
    getPricingPlans()
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <>
      <FieldGroupContainer
        title={t('partner-portal.application-templates.pricing-plan')}
        className={classes.fieldGroupContainer}
      >
        <Controller
          name="pricing_plan"
          control={control}
          render={({ field }) => (
            <SelectComponent
              {...field}
              placeholder={t(
                'partner-portal.application-templates.select-pricing-plan'
              )}
              options={pricingPlans}
              style={{ width: '50%' }}
              testId="select-pricing-plan"
              onChange={(event: SelectChangeEvent) =>
                handlePricingPlanChange(event.target.value)
              }
              error={!!errors.pricing_plan}
              helperText={
                errors.pricing_plan
                  ? (errors.pricing_plan?.message as string)
                  : t(
                      'partner-portal.application-templates.you-must-select-a-pricing-plan'
                    )
              }
            />
          )}
        />
      </FieldGroupContainer>

      <ConfirmChangePricingPlan
        open={showChangePlanConfirmation}
        onClose={cancelPlanChange}
        onConfirm={confirmPlanChange}
      />
    </>
  )
}
