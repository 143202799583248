import { TFunction } from 'i18next'

import { Equipment } from '@shared/api'
import { RadioButtonsArray } from '@shared/components'
import { CheckboxGroupItem } from '@shared/components/form-fields/checkbox/CheckboxGroup'
import { SelectOption } from '@shared/types'

import {
  AdditionalSetting,
  IntegratedSystem,
  OwnershipType,
  ProductProvider,
  SetupType,
  MobileNormalPawnTransactionType,
  CardType,
  FluipaySetting,
} from '../types/enums'
import { onChangeFieldFn } from '../types/onChangeFieldFn'

export interface ArrayFieldDirty {
  fluidpaySettings: {
    receiptPrinting: boolean
    signatureCapture: boolean
    mobilePawn: boolean
    debitOnlyPawn: boolean
  }
  additionalSettings: {
    fortisGateway: boolean
  }
}

interface GetFieldsOptionsData {
  t: TFunction
  onChangeField: onChangeFieldFn
  formData: Partial<Equipment>
  customDirty?: ArrayFieldDirty
  setCustomDirty?: (data: Record<string, any>) => void
}

// Get array and return unique values in another array
const uniqueSettings = (values: string[]) => {
  const uniqueValues = new Set(values)
  return Array.from(uniqueValues)
}

type FieldsOptions = {
  integratedSystemsSelectOptions: SelectOption<IntegratedSystem>[]

  cardTypesAcceptedCheckboxItems: CheckboxGroupItem<CardType>[]
  additionalSettingsCheckboxItems: CheckboxGroupItem<AdditionalSetting>[]
} & {
  [K in
    | 'productProviderRadioButtons'
    | 'ownershipTypeRadioButtons'
    | 'settingUpFortisGatewayRadioButtons'
    | 'orderingFpayBdogEquipmentRadioButtons'
    | 'isBravoPOSSetupRadioButtons'
    | 'receiptPrintingFromBravoRadioButtons'
    | 'signatureCaptureOnTerminalRadioButtons'
    | 'mobilePawnAndOrBuyRadioButtons'
    | 'debitOnlyOnLoansInMobilePawnRadioButtons'
    | 'mobileNormalPawnTransactionTypesRadioButtons'
    | 'integratedSetupRadioButtons'
    | 'setupTypeRadioButtons']: RadioButtonsArray
}

export const getFieldsOptions = (data: GetFieldsOptionsData): FieldsOptions => {
  const {
    t,
    onChangeField,
    formData: {
      ownership_code,
      supplier,
      conditional_settings: {
        is_integrated,
        fluidpay_settings = [],
        is_bravo_pos,
        is_ordering,
        transaction_type_code,
        simple_swipe_setup_type_code,
        additional_settings = [],
      } = {},
    },
    customDirty,
    setCustomDirty,
  } = data

  return {
    integratedSystemsSelectOptions: [
      { label: 'Integrated System A', value: IntegratedSystem.SYSTEM_A },
      { label: 'Integrated System B', value: IntegratedSystem.SYSTEM_B },
      { label: 'Integrated System C', value: IntegratedSystem.SYSTEM_C },
      { label: 'Integrated System D', value: IntegratedSystem.SYSTEM_D },
    ],
    //

    // Checkbox Items
    additionalSettingsCheckboxItems: [
      {
        key: 'card_vault_as',
        label: 'Card Vault',
        value: AdditionalSetting.CARD_VAULT,
        testId: 'additional-settings-card-vault-checkbox',
      },
      {
        key: 'recurring_as',
        label: 'Recurring',
        value: AdditionalSetting.RECURRING,
      },
      {
        key: 'pay_links_as',
        label: 'Pay Links',
        value: AdditionalSetting.PAY_LINKS,
      },
      {
        key: 'quick_invoice_as',
        label: 'Quick Invoice',
        value: AdditionalSetting.QUICK_INVOICE,
      },
      {
        key: 'enable_sms_as',
        label: 'Enable SMS',
        value: AdditionalSetting.ENABLE_SMS,
      },
      {
        key: 'hosted_payment_page_as',
        label: 'Hosted Payment Page',
        value: AdditionalSetting.HOSTED_PAYMENT_PAGE,
      },
      {
        key: 'level_3_data_as',
        label: 'Level 3 Data',
        value: AdditionalSetting.LEVEL_3_DATA,
      },
      {
        key: 'card_account_updater_as',
        label: 'Card Account Updater',
        value: AdditionalSetting.CARD_ACCOUNT_UPDATER,
      },
      {
        key: 'split_payments_as',
        label: 'Split Payments',
        value: AdditionalSetting.SPLIT_PAYMENTS,
      },
      {
        key: 'multi_deposit_as',
        label: 'Multi-Deposit',
        value: AdditionalSetting.MULTI_DEPOSIT,
      },
      {
        key: 'multi_location_as',
        label: 'Multi-Location',
        value: AdditionalSetting.MULTI_LOCATION,
      },
    ],

    cardTypesAcceptedCheckboxItems: [
      {
        key: 'card_type_visa',
        label: 'Visa',
        value: CardType.VISA,
        testId: 'card-types-accepted-visa-checkbox',
      },
      {
        key: 'card_type_mastercard',
        label: 'Mastercard',
        value: CardType.MASTERCARD,
      },
      {
        key: 'card_type_amex',
        label: 'Amex',
        value: CardType.AMEX,
      },
      {
        key: 'card_type_discover',
        label: 'Discover',
        value: CardType.DISCOVER,
      },
    ],
    //

    // Radio Buttons
    productProviderRadioButtons: [
      {
        testId: 'product-provider-fortis-button',
        title: t('partner-portal.application-templates.fortis'),
        color: 'secondary',
        defaultSelected: supplier === ProductProvider.FORTIS,
        onClick: () => onChangeField('supplier', ProductProvider.FORTIS),
      },
      {
        testId: 'product-provider-merchant-button',
        title: t('partner-portal.application-templates.merchant'),
        color: 'secondary',
        defaultSelected: supplier === ProductProvider.MERCHANT,
        onClick: () => onChangeField('supplier', ProductProvider.MERCHANT),
      },
      {
        testId: 'product-provider-sales-partner-button',
        title: t('partner-portal.application-templates.sales-partner'),
        color: 'secondary',
        defaultSelected: supplier === ProductProvider.SALES_PARTNER,
        onClick: () => onChangeField('supplier', ProductProvider.SALES_PARTNER),
      },
    ],

    ownershipTypeRadioButtons: [
      {
        testId: 'ownership-type-sale-button',
        title: t('partner-portal.application-templates.sale'),
        color: 'secondary',
        defaultSelected: ownership_code === OwnershipType.SALE,
        onClick: () => onChangeField('ownership_code', OwnershipType.SALE),
      },
      {
        testId: 'ownership-type-loaner-button',
        title: t('partner-portal.application-templates.loaner'),
        color: 'secondary',
        defaultSelected: ownership_code === OwnershipType.LOANER,
        onClick: () => onChangeField('ownership_code', OwnershipType.LOANER),
      },
    ],

    settingUpFortisGatewayRadioButtons: [
      {
        testId: 'setting-up-fortis-gateway-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected:
          customDirty.additionalSettings.fortisGateway &&
          additional_settings?.includes(
            AdditionalSetting.FORTIS_GW_PAYMENT_CLOUD
          ),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            additionalSettings: {
              ...customDirty.additionalSettings,
              fortisGateway: true,
            },
          })
          onChangeField(
            'conditional_settings.additional_settings',
            uniqueSettings([
              ...additional_settings,
              AdditionalSetting.FORTIS_GW_PAYMENT_CLOUD,
            ]) as Equipment['conditional_settings']['additional_settings']
          )
        },
      },
      {
        testId: 'setting-up-fortis-gateway-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected:
          customDirty.additionalSettings.fortisGateway &&
          !additional_settings?.includes(
            AdditionalSetting.FORTIS_GW_PAYMENT_CLOUD
          ),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            additionalSettings: {
              ...customDirty.additionalSettings,
              fortisGateway: true,
            },
          })
          onChangeField(
            'conditional_settings.additional_settings',
            additional_settings.filter(
              (setting) => setting !== AdditionalSetting.FORTIS_GW_PAYMENT_CLOUD
            )
          )
        },
      },
    ],

    integratedSetupRadioButtons: [
      {
        testId: 'integrated-setup-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected: Boolean(is_integrated),
        onClick: () =>
          onChangeField('conditional_settings.is_integrated', true),
      },
      {
        testId: 'integrated-setup-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected: is_integrated !== undefined && !is_integrated,
        onClick: () =>
          onChangeField('conditional_settings.is_integrated', false),
      },
    ],

    orderingFpayBdogEquipmentRadioButtons: [
      {
        testId: 'ordering-fluidpay-bluedog-equipment-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected: Boolean(is_ordering),
        onClick: () => onChangeField('conditional_settings.is_ordering', true),
      },
      {
        testId: 'ordering-fluidpay-bluedog-equipment-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected: is_ordering !== undefined && !is_ordering,
        onClick: () => onChangeField('conditional_settings.is_ordering', false),
      },
    ],

    isBravoPOSSetupRadioButtons: [
      {
        testId: 'is-bravo-pos-setup-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected: Boolean(is_bravo_pos),
        onClick: () => onChangeField('conditional_settings.is_bravo_pos', true),
      },
      {
        testId: 'is-bravo-pos-setup-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected: is_bravo_pos !== undefined && !is_bravo_pos,
        onClick: () =>
          onChangeField('conditional_settings.is_bravo_pos', false),
      },
    ],

    receiptPrintingFromBravoRadioButtons: [
      {
        testId: 'receipt-printing-from-bravo-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.receiptPrinting &&
          fluidpay_settings?.includes(FluipaySetting.RECEIPT_PRINTING),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              receiptPrinting: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            uniqueSettings([
              ...fluidpay_settings,
              FluipaySetting.RECEIPT_PRINTING,
            ]) as Equipment['conditional_settings']['fluidpay_settings']
          )
        },
      },
      {
        testId: 'receipt-printing-from-bravo-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.receiptPrinting &&
          !fluidpay_settings?.includes(FluipaySetting.RECEIPT_PRINTING),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              receiptPrinting: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            fluidpay_settings.filter(
              (setting) => setting !== FluipaySetting.RECEIPT_PRINTING
            )
          )
        },
      },
    ],

    signatureCaptureOnTerminalRadioButtons: [
      {
        testId: 'signature-capture-on-terminal-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.signatureCapture &&
          fluidpay_settings?.includes(FluipaySetting.SIGNATURE_CAPTURED),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              signatureCapture: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            uniqueSettings([
              ...fluidpay_settings,
              FluipaySetting.SIGNATURE_CAPTURED,
            ]) as Equipment['conditional_settings']['fluidpay_settings']
          )
        },
      },
      {
        testId: 'signature-capture-on-terminal-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.signatureCapture &&
          !fluidpay_settings?.includes(FluipaySetting.SIGNATURE_CAPTURED),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              signatureCapture: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            fluidpay_settings.filter(
              (setting) => setting !== FluipaySetting.SIGNATURE_CAPTURED
            )
          )
        },
      },
    ],

    mobilePawnAndOrBuyRadioButtons: [
      {
        testId: 'mobile-pawn-and-or-buy-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.mobilePawn &&
          fluidpay_settings?.includes(FluipaySetting.MOBILE_PAWN),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              mobilePawn: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            uniqueSettings([
              ...fluidpay_settings,
              FluipaySetting.MOBILE_PAWN,
            ]) as Equipment['conditional_settings']['fluidpay_settings']
          )
        },
      },
      {
        testId: 'mobile-pawn-and-or-buy-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.mobilePawn &&
          !fluidpay_settings?.includes(FluipaySetting.MOBILE_PAWN),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              mobilePawn: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            fluidpay_settings.filter(
              (setting) => setting !== FluipaySetting.MOBILE_PAWN
            )
          )
        },
      },
    ],

    debitOnlyOnLoansInMobilePawnRadioButtons: [
      {
        testId: 'debit-only-on-loans-in-mobile-pawn-yes-button',
        title: t('common.yes'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.debitOnlyPawn &&
          fluidpay_settings?.includes(FluipaySetting.DEBIT_ONLY_PAWN),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              debitOnlyPawn: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            uniqueSettings([
              ...fluidpay_settings,
              FluipaySetting.DEBIT_ONLY_PAWN,
            ]) as Equipment['conditional_settings']['fluidpay_settings']
          )
        },
      },
      {
        testId: 'debit-only-on-loans-in-mobile-pawn-no-button',
        title: t('common.no'),
        color: 'secondary',
        defaultSelected:
          customDirty.fluidpaySettings.debitOnlyPawn &&
          !fluidpay_settings?.includes(FluipaySetting.DEBIT_ONLY_PAWN),
        onClick: () => {
          setCustomDirty({
            ...customDirty,
            fluidpaySettings: {
              ...customDirty.fluidpaySettings,
              debitOnlyPawn: true,
            },
          })
          onChangeField(
            'conditional_settings.fluidpay_settings',
            fluidpay_settings.filter(
              (setting) => setting !== FluipaySetting.DEBIT_ONLY_PAWN
            )
          )
        },
      },
    ],

    mobileNormalPawnTransactionTypesRadioButtons: [
      {
        testId: 'mobile-normal-pawn-transaction-types-credit-debit-button',
        title: t('partner-portal.application-templates.credit-and-debit'),
        color: 'secondary',
        defaultSelected:
          transaction_type_code ===
          MobileNormalPawnTransactionType.CREDIT_AND_DEBIT,
        onClick: () =>
          onChangeField(
            'conditional_settings.transaction_type_code',
            MobileNormalPawnTransactionType.CREDIT_AND_DEBIT
          ),
      },
      {
        testId: 'mobile-normal-pawn-transaction-types-debit-only-button',
        title: t('partner-portal.application-templates.debit-only'),
        color: 'secondary',
        defaultSelected:
          transaction_type_code === MobileNormalPawnTransactionType.DEBIT_ONLY,
        onClick: () =>
          onChangeField(
            'conditional_settings.transaction_type_code',
            MobileNormalPawnTransactionType.DEBIT_ONLY
          ),
      },
    ],

    setupTypeRadioButtons: [
      {
        testId: 'setup-type-payments-button',
        title: t('common.payments'),
        color: 'secondary',
        defaultSelected: simple_swipe_setup_type_code === SetupType.PAYMENTS,
        onClick: () =>
          onChangeField(
            'conditional_settings.simple_swipe_setup_type_code',
            SetupType.PAYMENTS
          ),
      },
      {
        testId: 'setup-type-terminal-button',
        title: t('partner-portal.application-templates.terminal'),
        color: 'secondary',
        defaultSelected: simple_swipe_setup_type_code === SetupType.TERMINAL,
        onClick: () =>
          onChangeField(
            'conditional_settings.simple_swipe_setup_type_code',
            SetupType.TERMINAL
          ),
      },
      {
        testId: 'setup-type-virtual-terminal-app-only-button',
        title: t(
          'partner-portal.application-templates.virtual-terminal-app-only'
        ),
        color: 'secondary',
        defaultSelected:
          simple_swipe_setup_type_code === SetupType.VIRTUAL_TERMINAL_APP_ONLY,
        onClick: () =>
          onChangeField(
            'conditional_settings.simple_swipe_setup_type_code',
            SetupType.VIRTUAL_TERMINAL_APP_ONLY
          ),
      },
    ],
  }
}
