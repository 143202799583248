export enum ApplicationTypeCode {
  CC = 1,
  ACH = 2,
  'ACH-CC' = 3,
}

export enum CCPlatformCode {
  PAYFAC_FIS = 1,
  PAYFAC_FIS_CORE = 2,
  PAYFAC_FISERV = 3,
  WHOLESALE_FDR_OMAHA = 11,
  WHOLESALE_FDR_NORTH = 12,
}

export enum ACHPlatformCode {
  FORTIS = 1,
}

export enum ACHBankCode {
  FIFTH_THIRD_BANK = 1,
  KEY_BANK = 2,
}

export enum AgentStep {
  TEMPLATE_INFO = 50,
  PAYMENT_AND_VELOCITY = 60,
  CHANGING_PRODUCTS = 65,
  FINISH_PRODUCTS = 70,
  PRICING_PLAN = 80,
  COMPLETED = 0,
}
