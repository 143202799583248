import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { ActionModal } from '@shared/components'

interface DeleteProductModalProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

const DeleteProductModal: FC<DeleteProductModalProps> = ({
  open,
  onClose,
  onConfirm,
}) => {
  const { t } = useTranslation()

  return (
    <ActionModal
      open={open}
      title={t('partner-portal.application-templates.delete-product')}
      onClose={onClose}
      buttons={[
        {
          label: t('common.no'),
          color: 'secondary',
          onClick: onClose,
        },
        {
          label: t('common.yes'),
          color: 'primary',
          onClick: onConfirm,
        },
      ]}
    >
      <Typography sx={{ fontSize: '14px' }}>
        {t('partner-portal.application-templates.confirm-delete-description')}
      </Typography>
    </ActionModal>
  )
}

export default DeleteProductModal
