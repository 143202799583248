import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button } from '@shared/components'

interface SaveAndContinueButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isLoading: boolean
}

export const SaveAndContinueButton: FC<SaveAndContinueButtonProps> = ({
  onClick,
  isLoading,
}) => {
  const { t } = useTranslation()

  return (
    <Button
      testId="save-and-continue-button"
      label={t('partner-portal.application-templates.save-and-continue')}
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}
