import SvgIcon from '@mui/material/SvgIcon'

const CardReaderIcon = ({ size = 32, color = '#D64123', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="4"
          y="16"
          width="24"
          height="12"
          rx="2"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M24 16V6.66667C24 5.19391 22.8061 4 21.3333 4H10.6667C9.19391 4 8 5.19391 8 6.66667V16"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.0007 4V16"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default CardReaderIcon
