import { FC, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import { api, ApplicationTemplate } from '@shared/api'
import { ActionModal } from '@shared/components'
import { useNotification } from '@shared/hooks'

interface DeleteApplicationTemplateProps {
  open: boolean
  applicationTemplate: ApplicationTemplate
  onSuccess: () => void
  onClose: () => void
}

export const DeleteApplicationTemplate: FC<DeleteApplicationTemplateProps> = ({
  open,
  applicationTemplate,
  onSuccess,
  onClose,
}) => {
  const { t } = useTranslation()
  const { setNotification } = useNotification()

  const [isLoading, setIsLoading] = useState(false)

  const onDelete = async () => {
    try {
      setIsLoading(true)
      await api.service('templates').remove(applicationTemplate.template_id)
      setNotification({
        label: t(
          'partner-portal.application-templates.template-deleted-successfully'
        ),
        type: 'success',
      })
      onSuccess()
    } catch (error) {
      setNotification({
        label: error.message,
        type: 'error',
      })
    } finally {
      setIsLoading(false)
      onClose()
    }
  }

  return (
    <ActionModal
      open={open}
      title={t(
        'partner-portal.application-templates.delete-application-template'
      )}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: onClose,
          color: 'secondary',
          guidingId: 'applicationtemplates-deletetemplate-cancel',
        },
        {
          label: t('partner-portal.application-templates.yes-delete-template'),
          onClick: onDelete,
          color: 'primary',
          isLoading: isLoading,
          guidingId: 'applicationtemplates-deletetemplate-yes',
        },
      ]}
      onClose={onClose}
      guidingId="applicationtemplates-deletetemplate"
    >
      <Typography sx={{ fontSize: '14px' }} variant="body2">
        {`${t('common.modal.are-you-sure')} `}
        <strong>
          {t('partner-portal.application-templates.delete-this-template')}?
        </strong>
        {` ${t('common.modal.this-cannot-be-undone')}`}
      </Typography>
    </ActionModal>
  )
}
