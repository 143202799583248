import SvgIcon from '@mui/material/SvgIcon'

const GatewayIcon = ({ size = 32, color = '#D64123', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23.9993 22.6665H26.666C27.402 22.6665 27.9993 23.2638 27.9993 23.9998V26.6665C27.9993 27.4025 27.402 27.9998 26.666 27.9998H23.9993C23.2633 27.9998 22.666 27.4025 22.666 26.6665V23.9998C22.666 23.2638 23.2633 22.6665 23.9993 22.6665Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.33333 4H8C8.736 4 9.33333 4.59733 9.33333 5.33333V8C9.33333 8.736 8.736 9.33333 8 9.33333H5.33333C4.59733 9.33333 4 8.736 4 8V5.33333C4 4.59733 4.59733 4 5.33333 4Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M14.6673 13.3335H17.334C18.07 13.3335 18.6673 13.9308 18.6673 14.6668V17.3335C18.6673 18.0695 18.07 18.6668 17.334 18.6668H14.6673C13.9313 18.6668 13.334 18.0695 13.334 17.3335V14.6668C13.334 13.9308 13.9313 13.3335 14.6673 13.3335Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.666 6.6665H25.3327C26.806 6.6665 27.9993 7.85984 27.9993 9.33317V13.3332C27.9993 14.8065 26.806 15.9998 25.3327 15.9998H22.666"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.3333 25.3333H6.66667C5.19333 25.3333 4 24.14 4 22.6667V18.6667C4 17.1933 5.19333 16 6.66667 16H9.33333"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default GatewayIcon
