import { ApplicationTemplate } from '@shared/api'

import {
  ApplicationTypeCode,
  CCPlatformCode,
} from '@/pages/application-templates/enums'

// TODO: still to be updated when the new endpoint is ready
export const mapPlatformCode = (
  platformCode: ApplicationTemplate['platform_code']
) => {
  switch (platformCode) {
    case CCPlatformCode.PAYFAC_FIS:
      return 'Payfac FIS'
    case CCPlatformCode.PAYFAC_FIS_CORE:
      return 'Payfac FIS Core'
    case CCPlatformCode.PAYFAC_FISERV:
      return 'Payfac FISERV'
    case CCPlatformCode.WHOLESALE_FDR_OMAHA:
      return 'Payfac Wholesale FDR Omaha'
    case CCPlatformCode.WHOLESALE_FDR_NORTH:
      return 'Payfac FDR North'
    default:
      return undefined
  }
}

// TODO: still to be updated when the new endpoint is ready
export const mapAppTypeCode = (appTypeCode: ApplicationTypeCode) => {
  switch (appTypeCode) {
    case ApplicationTypeCode.CC:
      return 'CC'
    case ApplicationTypeCode.ACH:
      return 'ACH'
    case ApplicationTypeCode['ACH-CC']:
      return 'CC & ACH'
    default:
      return undefined
  }
}
