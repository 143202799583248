import { FC, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Box, Grid } from '@mui/material'

import {
  api,
  ApplicationTemplate,
  PaymentAndVelocity as PaymentAndVelocityType,
} from '@shared/api'
import { Loading, PageLayoutContainer } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useNotification,
  usePub,
} from '@shared/hooks'

import {
  APPLICATION_TEMPLATE_CHANGE_EVENT,
  ApplicationTemplateStepper,
} from '../components/application-template-stepper/ApplicationTemplateStepper'
import { CCACHPaymentAndVelocityForm } from '../components/cc-ach-payment-and-velocity-form/CCACHPaymentAndVelocityForm'
import { AgentStep } from '../enums'

const useStyles = tss.withName('PaymentAndVelocity').create(() => ({
  container: {
    background: 'unset',
    paddingRight: 0,
  },
  loadingContainer: {
    display: 'grid',
    placeContent: 'center',
    height: '80vh',
    width: '100%',
  },
}))

export const CURRENT_WIZARD_STEP = AgentStep.PAYMENT_AND_VELOCITY

export const PaymentAndVelocity: FC = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { setNotification } = useNotification()
  const { templateId } = useParams()

  const publish = usePub()

  const [applicationTemplate, setApplicationTemplate] =
    useState<ApplicationTemplate | null>(null)
  const [paymentAndVelocity, setPaymentAndVelocity] =
    useState<PaymentAndVelocityType | null>(null)

  const [isLoading, setIsLoading] = useState(false)

  const getApplicationTemplate = async () => {
    if (!templateId) return

    setIsLoading(true)

    try {
      const [paymentVelocity, template] = await Promise.all([
        api.service('templates').getPaymentVelocity(templateId),
        api.service('templates').get(templateId),
      ])

      setPaymentAndVelocity(paymentVelocity)
      setApplicationTemplate(template)
      publish(APPLICATION_TEMPLATE_CHANGE_EVENT, template)
      setIsLoading(false)
    } catch (error) {
      setNotification({ type: 'error', label: error })
    }
  }

  useEffect(() => {
    setAppBarTitle(t('partner-portal.application-templates.create-template'))
  }, [])

  useEffect(() => {
    getApplicationTemplate()
  }, [])

  return (
    <PageLayoutContainer className={classes.container} isButtonBarAtBottom>
      <Grid container>
        <Grid item xs={12} md={2} sx={{ padding: '24px' }}>
          <ApplicationTemplateStepper activeStep={CURRENT_WIZARD_STEP} />
        </Grid>

        <Grid item xs={12} md={10}>
          {isLoading || !paymentAndVelocity ? (
            <Box className={classes.loadingContainer}>
              <Loading />
            </Box>
          ) : (
            <CCACHPaymentAndVelocityForm
              paymentAndVelocity={paymentAndVelocity}
            />
          )}
        </Grid>
      </Grid>
    </PageLayoutContainer>
  )
}
