import {
  WizardInvalidStepError,
  WizardStepsCantBeEmptyError,
} from './wizardStepperUtilityErrors'

export type WizardStep = {
  label: string
  number: number
  url: string
}

export type WizardStepName = Exclude<
  keyof EnabledButtonsData,
  'wizardSteps' | 'thisPageDirty'
>

export type StepperStep = {
  label: string
  number: number
}

export type CheckIsValidStepData = {
  wizardSteps: WizardStep[]
  step: number
  stepName: WizardStepName
}

export type EnabledButtonsData = {
  wizardSteps: WizardStep[]
  currentStep: number
  highestCompletedStep?: number | undefined
  thisPageDirty: boolean
  finishStep?: number
}

export enum WizardStepperButton {
  SAVE_AND_CONTINUE = 1,
  SAVE_AND_QUIT = 2,
  // This displays when Editing and you haven't changed anything,
  // so you just can continue to the next step without saving anything,
  // because there isn't anything save.
  CONTINUE = 3,
  QUIT = 4,
  BACK = 5, // TODO: to think about in the future, focus on others.
  REVERT = 6, // TODO: to think about in the future, focus on others.
  DONE = 7,
}

export class WizardStepperUtility {
  /**
   *
   * Sort wizard steps by their number.
   * @param {WizardStep[]} wizardSteps The wizard steps to sort.
   * @returns {WizardStep[]} An array of sorted wizard steps.
   */
  private static sortWizardSteps(wizardSteps: WizardStep[]): WizardStep[] {
    return [...wizardSteps].sort((a, b) => a.number - b.number)
  }

  /**
   *
   * Check if a given step is valid.
   *
   * For a step to be valid it has to be found within the provided
   * wizard steps.
   *
   * @param {CheckIsValidStepData} data The data to check if the step is valid.
   */
  private static checkIsValidStep(data: CheckIsValidStepData) {
    const { wizardSteps, step, stepName } = data

    if (!wizardSteps.length) throw new WizardStepsCantBeEmptyError()
    if (step === undefined) return

    const validSteps = WizardStepperUtility.sortWizardSteps(wizardSteps).map(
      ({ number }) => number
    )

    if (!validSteps.includes(step)) {
      throw new WizardInvalidStepError({
        step,
        stepName,
        validSteps,
      })
    }
  }

  /**
   *
   * From an array of wizard steps generate the ones to be provided to a `MUI Stepper Component`.
   * @param {WizardStep[]} wizardSteps The wizard steps being used.
   * @returns {StepperStep[]} An array of steps that are compatible with a `MUI Stepper Component`.
   */
  static generateStepperSteps(wizardSteps: WizardStep[]): StepperStep[] {
    if (!wizardSteps.length) throw new WizardStepsCantBeEmptyError()

    return WizardStepperUtility.sortWizardSteps(wizardSteps).map(
      ({ label, number }) => ({
        label,
        number,
      })
    )
  }

  /**
   *
   * Get the url of the next step.
   * @param {WizardStep[]} wizardSteps The wizard steps being used.
   * @param {number} currentStep The current step that the user is located at.
   * @returns {string | undefined} The url of the next step or `undefined`.
   */
  static nextStepUrl(
    wizardSteps: WizardStep[],
    currentStep: number
  ): string | undefined {
    WizardStepperUtility.checkIsValidStep({
      wizardSteps,
      step: currentStep,
      stepName: 'currentStep',
    })

    const sortedWizardSteps = WizardStepperUtility.sortWizardSteps(wizardSteps)

    const currentStepIndex = sortedWizardSteps.findIndex(
      ({ number }) => currentStep === number
    )

    return sortedWizardSteps[currentStepIndex + 1]?.url
  }

  /**
   *
   * Get the url of the previous step.
   * @param {WizardStep[]} wizardSteps The wizard steps being used.
   * @param {number} currentStep The current step that the user is located at.
   * @returns {string | undefined} The url of the previous step or `undefined`.
   */
  static previousStepUrl(
    wizardSteps: WizardStep[],
    currentStep: number
  ): string | undefined {
    WizardStepperUtility.checkIsValidStep({
      wizardSteps,
      step: currentStep,
      stepName: 'currentStep',
    })

    const sortedWizardSteps = WizardStepperUtility.sortWizardSteps(wizardSteps)

    const currentStepIndex = sortedWizardSteps.findIndex(
      ({ number }) => currentStep === number
    )

    return sortedWizardSteps[currentStepIndex - 1]?.url
  }

  /**
   *
   * Get the enabled wizard stepper buttons.
   * @param {EnabledButtonsData} data The data to determine enabled buttons.
   * @returns {WizardStepperButton[]} An array of wizard stepper buttons.
   */
  static enabledButtons(data: EnabledButtonsData): WizardStepperButton[] {
    const {
      wizardSteps,
      currentStep,
      highestCompletedStep,
      thisPageDirty,
      finishStep,
    } = data

    if (!wizardSteps || !wizardSteps.length) {
      throw new WizardStepsCantBeEmptyError()
    }

    WizardStepperUtility.checkIsValidStep({
      wizardSteps,
      step: currentStep,
      stepName: 'currentStep',
    })

    const buttons: WizardStepperButton[] = [WizardStepperButton.QUIT]

    const sortedWizardSteps = WizardStepperUtility.sortWizardSteps(wizardSteps)

    const isOnFirstStep = sortedWizardSteps[0].number === currentStep

    const isOnLastStep =
      currentStep === sortedWizardSteps[sortedWizardSteps.length - 1].number

    const isOnUncompletedStep =
      highestCompletedStep === undefined ||
      (currentStep > highestCompletedStep &&
        highestCompletedStep !== finishStep)

    if (highestCompletedStep !== undefined && !isOnFirstStep) {
      buttons.push(WizardStepperButton.BACK)
    }

    if (
      isOnLastStep &&
      (finishStep !== highestCompletedStep || thisPageDirty)
    ) {
      buttons.push(WizardStepperButton.DONE)
    } else if (isOnUncompletedStep || thisPageDirty) {
      buttons.push(
        WizardStepperButton.SAVE_AND_QUIT,
        WizardStepperButton.SAVE_AND_CONTINUE
      )
    }

    if (
      sortedWizardSteps.length > 1 &&
      !isOnLastStep &&
      !thisPageDirty &&
      (!isOnUncompletedStep || finishStep === highestCompletedStep)
    ) {
      buttons.push(WizardStepperButton.CONTINUE)
    }

    if (thisPageDirty) {
      buttons.push(WizardStepperButton.REVERT)
    }

    return buttons
  }
}
