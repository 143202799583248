import { FC } from 'react'
import { useTranslation } from 'react-i18next'

import { Button, ButtonProps } from '@shared/components'

interface QuitButtonProps extends ButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>
  isLoading?: boolean
}

export const QuitButton: FC<QuitButtonProps> = ({
  onClick,
  isLoading,
  ...props
}) => {
  const { t } = useTranslation()

  return (
    <Button
      testId="quit-button"
      label={t('partner-portal.application-templates.quit')}
      color="secondary"
      containerClassName={props.containerClassName}
      onClick={onClick}
      isLoading={isLoading}
    />
  )
}
