export enum ProductProvider {
  FORTIS = 1,
  SALES_PARTNER = 2,
  MERCHANT = 3,
}

export enum OwnershipType {
  SALE = 1,
  LOANER = 2,
}

// This needs to be changed, API uses number for this value. But we don't have a mapping available for the options.
export enum IntegratedSystem {
  SYSTEM_A = 1,
  SYSTEM_B = 2,
  SYSTEM_C = 3,
  SYSTEM_D = 4,
}

export enum AdditionalSetting {
  CARD_VAULT = 'CardVault',
  RECURRING = 'Recurring',
  PAY_LINKS = 'PayLinks',
  QUICK_INVOICE = 'QuickInvoice',
  ENABLE_SMS = 'SMS',
  HOSTED_PAYMENT_PAGE = 'HostedPaymentPage',
  LEVEL_3_DATA = 'Level3Data',
  CARD_ACCOUNT_UPDATER = 'CardAccountUpdater',
  SPLIT_PAYMENTS = 'SplitPayments',
  MULTI_DEPOSIT = 'MultiDeposit',
  MULTI_LOCATION = 'MultiLocation',
  FORTIS_GW_PAYMENT_CLOUD = 'FortisGWPaymentCloud',
}

export enum FluipaySetting {
  RECEIPT_PRINTING = 'ReceiptPrinting',
  SIGNATURE_CAPTURED = 'SignatureCaptured',
  MOBILE_PAWN = 'MobilePawn',
  DEBIT_ONLY_PAWN = 'DebitOnlyPawn',
}

export enum SetupType {
  PAYMENTS = 1,
  TERMINAL = 2,
  VIRTUAL_TERMINAL_APP_ONLY = 3,
}

export enum MobileNormalPawnTransactionType {
  CREDIT_AND_DEBIT = 1,
  DEBIT_ONLY = 2,
}

export enum CardType {
  VISA = 'Visa',
  MASTERCARD = 'Mastercard',
  AMEX = 'Amex',
  DISCOVER = 'Discover',
}
