import SvgIcon from '@mui/material/SvgIcon'

const PinPadIcon = ({ size = 32, color = '#D64123', ...props }) => {
  return (
    <SvgIcon {...props} style={{ width: `${size}px`, height: `${size}px` }}>
      <svg viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect
          x="4"
          y="4"
          width="17.3333"
          height="24"
          rx="3"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <rect
          x="8"
          y="8"
          width="9.33333"
          height="8"
          rx="1"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M21.334 21.3332H26.6673C28.1401 21.3332 29.334 20.1393 29.334 18.6665V13.3332C29.334 11.8604 28.1401 10.6665 26.6673 10.6665H21.334"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0007 19.9935L16.0073 20.0002L16.0007 20.0068L15.994 20.0002L16.0007 19.9935"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M12.6667 19.9935L12.6733 20.0002L12.6667 20.0068L12.66 20.0002L12.6667 19.9935"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33268 19.9935L9.33935 20.0002L9.33268 20.0068L9.32602 20.0002L9.33268 19.9935"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M16.0007 23.3265L16.0073 23.3332L16.0007 23.3398L15.994 23.3332L16.0007 23.3265"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6668 23.3267L12.6735 23.3333L12.6668 23.34L12.6602 23.3333L12.6668 23.3267Z"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M9.33268 23.3265L9.33935 23.3332L9.33268 23.3398L9.32602 23.3332L9.33268 23.3265"
          stroke={color}
          strokeWidth="1.7"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </SvgIcon>
  )
}

export default PinPadIcon
