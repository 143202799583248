import { FC, useEffect, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { TFunction, Trans, useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import { tss } from 'tss-react/mui'
import * as yup from 'yup'

import { Box, Grid, Typography } from '@mui/material'

import { api, ApplicationTemplate, Equipment } from '@shared/api/src'
import {
  PageLayoutContainer,
  Input,
  SelectComponent,
  SelectOption,
  RadioButtons,
  RadioButtonsArray,
  Loading,
  ActionModal,
} from '@shared/components'
import { useEnforceLogin, useNotification, usePub } from '@shared/hooks'

import {
  ACHBankCode,
  ACHPlatformCode,
  AgentStep,
  ApplicationTypeCode,
  CCPlatformCode,
} from '../../enums'
import { APPLICATION_TEMPLATE_CHANGE_EVENT } from '../application-template-stepper/ApplicationTemplateStepper'
import { InactiveTemplateBanner } from '../inactive-template-banner/InactiveTemplateBanner'

const useStyles = tss
  .withName('ApplicationTemplatesTemplateInfo')
  .create(({ theme }) => ({
    title: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '20px',
      fontWeight: '500',
      lineHeight: '28px',
    },
    buttonContainer: {
      width: '100%',
    },
    modal: {
      minWidth: '600px',
    },
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px',
    },
  }))

// Utils
const showCCFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.CC ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

const showACHFields = (applicationTypeCode: ApplicationTypeCode) =>
  applicationTypeCode === ApplicationTypeCode.ACH ||
  applicationTypeCode === ApplicationTypeCode['ACH-CC']

export const templatesFormSchema = (t: TFunction) =>
  yup.object().shape({
    template_name: yup
      .string()
      .required(
        t('partner-portal.application-templates.you-must-enter-a-template-name')
      )
      .matches(
        /^[a-zA-Z0-9]+$/,
        t(
          'partner-portal.application-templates.template-name-alphanumeric-only'
        )
      ),

    office_name: yup
      .string()
      .required(
        t('partner-portal.application-templates.you-must-select-an-office-name')
      ),

    agent_id: yup.string(),
    application_type_code: yup
      .number()
      .oneOf([
        ApplicationTypeCode.CC,
        ApplicationTypeCode.ACH,
        ApplicationTypeCode['ACH-CC'],
      ])
      .required(
        t(
          'partner-portal.application-templates.you-must-select-an-application-type'
        )
      ),

    platform_code: yup.number().when(['application_type_code'], {
      is: showCCFields,
      then: (schema) =>
        schema
          .required(
            t(
              'partner-portal.application-templates.you-must-select-a-cc-processor-platform'
            )
          )
          .oneOf(
            [
              CCPlatformCode.PAYFAC_FIS,
              CCPlatformCode.PAYFAC_FIS_CORE,
              CCPlatformCode.PAYFAC_FISERV,
              CCPlatformCode.WHOLESALE_FDR_OMAHA,
              CCPlatformCode.WHOLESALE_FDR_NORTH,
            ],
            t(
              'partner-portal.application-templates.you-must-select-a-cc-processor-platform'
            )
          ),
    }),

    ach_platform_code: yup.number().when(['application_type_code'], {
      is: showACHFields,
      then: (schema) => schema.required().oneOf([ACHPlatformCode.FORTIS]),
    }),

    ach_bank_code: yup.number().oneOf([ACHBankCode.FIFTH_THIRD_BANK]),

    business_category: yup
      .string()
      .required(
        t(
          'partner-portal.application-templates.you-must-select-a-business-category'
        )
      ),
    business_type: yup
      .string()
      .required(
        t(
          'partner-portal.application-templates.you-must-select-a-business-type'
        )
      ),
    business_description: yup.string(),
    agent_steps: yup.number(),
  })

interface TemplatesFormProps {
  isEdit?: boolean
}

const achProcessorPlatforms: SelectOption<string>[] = [
  {
    value: ACHPlatformCode.FORTIS,
    label: 'Fortis',
  },
]

const achProcessingBanks: SelectOption<string>[] = [
  { value: ACHBankCode.FIFTH_THIRD_BANK, label: 'Fifth Third Bank' },
]

export const TemplatesForm: FC<TemplatesFormProps> = ({ isEdit = false }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const { user } = useEnforceLogin()
  const { templateId } = useParams()
  const { setNotification } = useNotification()

  const [preventFieldEditing, setPreventFieldEditing] = useState<{
    isOpen: boolean
    field: 'application_type_code' | 'platform_code'
  }>({
    isOpen: false,
    field: 'application_type_code',
  })

  const [isLoadingCCProcessorPlatforms, setIsLoadingCCProcessorPlatforms] =
    useState<boolean>(false)
  const [isLoadingAgents, setIsLoadingAgents] = useState<boolean>(false)
  const [isLoadingBusinessTypes, setIsLoadingBusinessTypes] =
    useState<boolean>(false)
  const [isLoadingOffices, setIsLoadingOffices] = useState<boolean>(false)

  const [ccProcessorPlatforms, setCcProcessorPlatforms] = useState<
    SelectOption<string>[]
  >([])
  const [officeNames, setOfficeNames] = useState<SelectOption<string>[]>([])
  const [agentNames, setAgentNames] = useState<SelectOption<string>[]>([])
  const [businessCategories, setBusinessCategories] = useState<
    SelectOption<string>[]
  >([])
  const [businessTypes, setBusinessTypes] = useState<
    (SelectOption<string> & { category_code: string; platforms: number[] })[]
  >([])
  const [businessTypesList, setBusinessTypesList] = useState<
    (SelectOption<string> & { category_code: string; platforms: number[] })[]
  >([])

  const [equipments, setEquipments] = useState<Equipment[]>([])

  const locationIdMap = useRef<Map<string, string>>(new Map())

  const [isEditLoading, setIsEditLoading] = useState<boolean>(false)
  const schema = templatesFormSchema(t)
  type Data = yup.InferType<typeof schema>
  const {
    control,
    formState: { errors, isDirty },
    watch,
    setFocus,
    setValue,
    setError,
    clearErrors,
    reset,
  } = useFormContext<Data>()

  const publish = usePub()

  // Utils
  const showCCFields = (applicationTypeCode: ApplicationTypeCode) =>
    applicationTypeCode === ApplicationTypeCode.CC ||
    applicationTypeCode === ApplicationTypeCode['ACH-CC']

  const showACHFields = (applicationTypeCode: ApplicationTypeCode) =>
    applicationTypeCode === ApplicationTypeCode.ACH ||
    applicationTypeCode === ApplicationTypeCode['ACH-CC']

  const applicationTypeCode = watch('application_type_code')
  const selectedCCProcessorPlatform = watch('platform_code')
  const selectedOfficeName = watch('office_name')
  const selectedAgentName = watch('agent_id')
  const selectedBusinessCategory = watch('business_category')
  const selectedBusinessType = watch('business_type')
  const agentSteps = watch('agent_steps')

  const applicationTypeOptions: RadioButtonsArray = [
    {
      title: 'ACH',
      color: 'secondary',
      onClick: () => {
        if (isEdit && equipments.length > 0) {
          setPreventFieldEditing({
            isOpen: true,
            field: 'application_type_code',
          })
          return
        } else {
          setValue('application_type_code', ApplicationTypeCode.ACH, {
            shouldDirty: true,
          })
        }
      },
      defaultSelected: applicationTypeCode === ApplicationTypeCode.ACH,
      testId: 'application-type-ach-button',
    },
    {
      title: 'CC',
      color: 'secondary',
      onClick: () => {
        if (isEdit && equipments.length > 0) {
          setPreventFieldEditing({
            isOpen: true,
            field: 'application_type_code',
          })
          return
        } else {
          setValue('application_type_code', ApplicationTypeCode.CC, {
            shouldDirty: true,
          })
        }
      },
      defaultSelected: applicationTypeCode === ApplicationTypeCode.CC,
      testId: 'application-type-cc-button',
    },
    {
      title: 'CC & ACH',
      color: 'secondary',
      onClick: () => {
        if (isEdit && equipments.length > 0) {
          setPreventFieldEditing({
            isOpen: true,
            field: 'application_type_code',
          })
          return
        } else {
          setValue('application_type_code', ApplicationTypeCode['ACH-CC'], {
            shouldDirty: true,
          })
        }
      },
      defaultSelected: applicationTypeCode === ApplicationTypeCode['ACH-CC'],
      testId: 'application-type-ach-cc-button',
    },
  ]

  const getCCProcessorPlatforms = async (agentId: string = '') => {
    setIsLoadingCCProcessorPlatforms(true)

    const platforms = await api.service('platforms').find(
      agentId
        ? {
            query: {
              agent_id: agentId,
            },
          }
        : null
    )

    let arrayPlatformNames = []

    platforms
      .filter((platform) => platform.is_backend)
      .forEach((platform) => {
        arrayPlatformNames.push({
          value: platform.platform_code,
          label: platform.description,
        })
      })

    setCcProcessorPlatforms(arrayPlatformNames)

    if (
      arrayPlatformNames.findIndex(
        (item) => item.value === selectedCCProcessorPlatform
      ) === -1
    ) {
      setValue('platform_code', undefined)
    }
    setIsLoadingCCProcessorPlatforms(false)

    return arrayPlatformNames
  }

  const getBusinessCategoriesAndTypes = async (
    platformCode: number = undefined,
    businessCategory: string = selectedBusinessCategory
  ) => {
    setIsLoadingBusinessTypes(true)
    const businessTypes = await api.service('business-types').find({
      query: {
        platform_code: platformCode || undefined,
        page: {
          size: 500, // For now let's return a big number to avoid missing any business type. Later we can implement a typeahead search
        },
      },
    })

    let categories = []

    businessTypes.forEach((businessType) => {
      if (
        categories.filter(
          (category) => category.value === businessType.category_code
        ).length === 0
      ) {
        categories.push({
          value: businessType.category_code,
          label: businessType.category_name,
        })
      }
    })

    categories.sort((a, b) => a.label.localeCompare(b.label))

    const types = businessTypes
      .map((businessType) => ({
        value: businessType.type_code,
        label: businessType.type_name,
        platforms: businessType.platforms as number[],
        category_code: businessType.category_code,
      }))
      .sort((a, b) => a.label.localeCompare(b.label))

    // If there is no selected business category or the previous one is not available anymore after the request,
    // we unset the Business Category dropdown and set all the options to the Business Type dropdown.
    // Otherwise, we will keep the Business Category selected and will display only the Business Types available for that category
    if (
      categories.filter((item) => item.value === businessCategory).length === 0
    ) {
      setValue('business_category', '')
      setValue('business_type', '')
      setBusinessTypes(types)
    } else {
      if (
        !types.some(
          (item) =>
            item.value === selectedBusinessType &&
            item.platforms.includes(platformCode)
        )
      ) {
        setValue('business_type', '')
      }
      setBusinessTypes(
        types.filter((item) => item.category_code === businessCategory)
      )
    }

    setBusinessCategories(categories)
    setBusinessTypesList(types)

    setIsLoadingBusinessTypes(false)
  }

  const getOfficeNames = async () => {
    setIsLoadingOffices(true)
    const offices = await api.service('offices').find({
      query: {
        sort: {
          name: 'asc',
        },
      },
    })

    const officeList = offices.map(({ name, location_id, office_id }) => {
      locationIdMap.current.set(office_id, location_id)
      return {
        value: office_id,
        label: name,
      }
    })

    setOfficeNames(officeList)
    setIsLoadingOffices(false)
    return officeList
  }

  const getAgents = async (
    office?: string,
    offices?: SelectOption<string>[]
  ) => {
    setIsLoadingAgents(true)
    const currentOffice = office || selectedOfficeName
    const allOffices = offices || officeNames

    if (!currentOffice) {
      return []
    }

    const location_id = locationIdMap.current.get(currentOffice)
    const agents = await api.service('agents').get(location_id)

    let arrayAgentNames = [
      {
        value: '',
        label: t('partner-portal.application-templates.no-agent'),
      },
    ]

    agents
      .filter(
        ({ agent_id }) => !allOffices.some(({ value }) => value === agent_id)
      ) // This can be removed one https://zeamster.atlassian.net/browse/OCP-1783 is completed
      .forEach((agent) => {
        arrayAgentNames.push({
          value: agent.agent_id,
          label: agent.name,
        })
      })

    setAgentNames(arrayAgentNames)

    setIsLoadingAgents(false)
    return arrayAgentNames
  }

  const getEquipments = async () => {
    const equipments = await api.service('templates').getEquipment(templateId)

    setEquipments(equipments)
  }

  const loadFullTemplate = async () => {
    try {
      setIsEditLoading(true)

      const template = await api.service('templates').get(templateId)
      publish(APPLICATION_TEMPLATE_CHANGE_EVENT, template)
      const offices = await getOfficeNames()
      const currentOffice = offices.find(
        (office) => office.label === template.office_name
      )

      const [agents] = await Promise.all([
        getAgents(currentOffice?.value || template.agent_id, offices),
        getEquipments(),
        getCCProcessorPlatforms(template.agent_id),
        getBusinessCategoriesAndTypes(
          template.platform_code,
          template.business_category
        ),
      ])

      const isAgent = agents.some((agent) => agent.value === template.agent_id)

      let templateToForm: Partial<ApplicationTemplate> = {
        template_name: template.template_name,
        application_type_code: template.application_type_code,
        office_name: currentOffice?.value,
        agent_id: isAgent ? template.agent_id : undefined,
        business_category: template.business_category || undefined,
        business_type: template.business_type || undefined,
        business_description: template.business_description || undefined,
        agent_steps: template.agent_steps,
      }

      if (
        template.application_type_code === ApplicationTypeCode.CC ||
        template.application_type_code === ApplicationTypeCode['ACH-CC']
      ) {
        templateToForm = {
          ...templateToForm,
          platform_code: template.platform_code,
        }
      }

      if (
        template.application_type_code === ApplicationTypeCode.ACH ||
        template.application_type_code === ApplicationTypeCode['ACH-CC']
      ) {
        templateToForm = {
          ...templateToForm,
          ach_platform_code: ACHPlatformCode.FORTIS,
          ach_bank_code: template.ach_bank_code,
        }
      }

      reset(templateToForm)
    } catch (error) {
      console.error(error)
    } finally {
      setIsEditLoading(false)
    }
  }

  useEffect(() => {
    if (isEdit && user) {
      loadFullTemplate()
    }
  }, [isEdit, user])

  useEffect(() => {
    if (!selectedBusinessCategory || isEditLoading) {
      return
    }

    const businessTypesFiltered = businessTypesList.filter(
      (item) => item.category_code === selectedBusinessCategory
    )

    if (
      businessTypesFiltered.filter(
        (item) => item.value === selectedBusinessType
      ).length === 0
    ) {
      setValue('business_type', '')
    }

    setBusinessTypes(businessTypesFiltered)
  }, [selectedBusinessCategory])

  useEffect(() => {
    if (!selectedBusinessType) {
      return
    }

    const businessType = businessTypes.find(
      (item) => item.value === selectedBusinessType
    )

    setValue('business_category', businessType?.category_code)
  }, [selectedBusinessType])

  const handleFetch = async function <T>(
    promise: Promise<T>,
    errorMessage: string
  ): Promise<T | null> {
    try {
      return await promise
    } catch (error) {
      setNotification({ label: errorMessage, type: 'error' })
    }
  }

  useEffect(() => {
    if (isEdit) {
      return
    }

    requestAnimationFrame(() => setFocus('template_name'))

    handleFetch(
      getCCProcessorPlatforms(),
      t(
        'partner-portal.application-templates.error-fetching-cc-processor-platforms'
      )
    )
    handleFetch(
      getOfficeNames(),
      t('partner-portal.application-templates.error-fetching-offices')
    )
  }, [])

  useEffect(() => {
    if (isEditLoading) {
      return
    }
    if (errors.application_type_code) {
      setError('application_type_code', null)
    }

    if (applicationTypeCode === ApplicationTypeCode.CC) {
      setValue('ach_platform_code', undefined)
      setValue('ach_bank_code', undefined)
    }

    if (applicationTypeCode === ApplicationTypeCode.ACH) {
      setValue('platform_code', undefined)
      setValue('ach_bank_code', ACHBankCode.FIFTH_THIRD_BANK)
    }

    if (showACHFields(applicationTypeCode)) {
      setValue('ach_platform_code', ACHPlatformCode.FORTIS)
      setValue('ach_bank_code', ACHBankCode.FIFTH_THIRD_BANK)
    }
  }, [applicationTypeCode])

  useEffect(() => {
    if (isEditLoading || !selectedCCProcessorPlatform) {
      return
    }
    handleFetch(
      getBusinessCategoriesAndTypes(selectedCCProcessorPlatform),
      t('partner-portal.application-templates.error-fetching-business-types')
    )
  }, [selectedCCProcessorPlatform])

  useEffect(() => {
    if (isEditLoading || !selectedOfficeName) {
      return
    }

    setAgentNames([])
    setValue('agent_id', '')

    handleFetch(
      getBusinessCategoriesAndTypes(selectedCCProcessorPlatform),
      t('partner-portal.application-templates.error-fetching-business-types')
    )

    handleFetch(
      getAgents(),
      t('partner-portal.application-templates.error-fetching-agents')
    )
  }, [selectedOfficeName])

  useEffect(() => {
    if (isEditLoading) {
      return
    }

    if (selectedOfficeName && !selectedAgentName) {
      // TO DO: This scenario with just the Office selected will be implemented on another ticket
      // const officeData = user?.locations.find((location) => {
      //   if (location.location_type === 'agent') {
      //     return location.parent_id === selectedOfficeName
      //   } else {
      //     return location.id === selectedOfficeName
      //   }
      // })
      // getCCProcessorPlatforms(officeData?.parent_id)

      // Get the default CC Processor Platforms. It will be replaced by the call above once possible
      getCCProcessorPlatforms()
    } else if (selectedOfficeName && selectedAgentName) {
      handleFetch(
        getCCProcessorPlatforms(selectedAgentName),
        t(
          'partner-portal.application-templates.error-fetching-cc-processor-platforms'
        )
      )
    }
  }, [selectedOfficeName, selectedAgentName])

  if (isEditLoading) {
    return <Loading />
  }

  return (
    <>
      {agentSteps >= AgentStep.TEMPLATE_INFO && <InactiveTemplateBanner />}
      <PageLayoutContainer>
        <Box sx={{ padding: '24px' }} width="100%">
          <Typography className={classes.title}>
            {t('partner-portal.application-templates.template-info')}
          </Typography>

          <Box sx={{ marginTop: '32px' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Controller
                  name="template_name"
                  control={control}
                  render={({ field }) => (
                    <Input
                      {...field}
                      onChange={(e) => {
                        field.onChange(e)
                        // Validate template name pattern in real-time
                        const value = e.target.value
                        if (value) {
                          const isValid = /^[a-zA-Z0-9]+$/.test(value)
                          if (!isValid) {
                            setError('template_name', {
                              type: 'pattern',
                              message: t(
                                'partner-portal.application-templates.template-name-alphanumeric-only'
                              ),
                            })
                          } else {
                            // Clear error when valid
                            if (errors.template_name?.type === 'pattern') {
                              clearErrors('template_name')
                            }
                          }
                        }
                      }}
                      testId="template-name-input"
                      label={t(
                        'partner-portal.application-templates.template-name'
                      )}
                      placeholder={t(
                        'partner-portal.application-templates.template-name-placeholder'
                      )}
                      required
                      error={Boolean(errors.template_name)}
                      helperText={
                        errors.template_name
                          ? errors.template_name.message?.toString()
                          : t(
                              'partner-portal.application-templates.template-name-alphanumeric-only'
                            )
                      }
                      disabled={isEdit}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <RadioButtons
                  testId="application-type-radio-buttons"
                  label={t(
                    'partner-portal.application-templates.application-type'
                  )}
                  buttons={applicationTypeOptions}
                  required
                  error={Boolean(errors.application_type_code)}
                  helperText={errors.application_type_code?.message?.toString()}
                />
              </Grid>

              {showCCFields(applicationTypeCode) && (
                <Grid item xs={12} md={6}>
                  <Controller
                    name="platform_code"
                    control={control}
                    render={({ field }) => (
                      <SelectComponent
                        {...field}
                        sort
                        onChange={(e) => {
                          if (isEdit && equipments.length > 0) {
                            setPreventFieldEditing({
                              isOpen: true,
                              field: 'platform_code',
                            })
                            return
                          }
                          field.onChange(e)
                        }}
                        value={selectedCCProcessorPlatform}
                        testId="cc-processor-platform-input"
                        label={t(
                          'partner-portal.application-templates.cc-processor-platform'
                        )}
                        placeholder={t(
                          'partner-portal.application-templates.cc-processor-platform-placeholder'
                        )}
                        options={ccProcessorPlatforms}
                        style={{
                          height: '45px',
                        }}
                        required
                        isLoading={isLoadingCCProcessorPlatforms}
                        error={Boolean(errors.platform_code)}
                        helperText={errors.platform_code?.message.toString()}
                      />
                    )}
                  />
                </Grid>
              )}

              {showACHFields(applicationTypeCode) && (
                <>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="ach_platform_code"
                      control={control}
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          testId="ach-processor-platform-input"
                          label={t(
                            'partner-portal.application-templates.ach-processor-platform'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.ach-processor-platform-placeholder'
                          )}
                          options={achProcessorPlatforms}
                          style={{
                            height: '45px',
                          }}
                          required
                          disabled
                          error={Boolean(errors.ach_platform_code)}
                          helperText={errors.ach_platform_code?.message.toString()}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="ach_bank_code"
                      control={control}
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          testId="ach-processing-bank-input"
                          label={t(
                            'partner-portal.application-templates.ach-processing-bank'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.ach-processing-bank-placeholder'
                          )}
                          options={achProcessingBanks}
                          style={{
                            height: '45px',
                          }}
                          error={Boolean(errors.ach_bank_code)}
                          helperText={errors.ach_bank_code?.message.toString()}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} md={6}>
                <Controller
                  name="office_name"
                  control={control}
                  render={({ field }) => (
                    <SelectComponent
                      {...field}
                      testId="office-name-input"
                      label={t(
                        'partner-portal.application-templates.office-name'
                      )}
                      placeholder={t(
                        'partner-portal.application-templates.office-name-placeholder'
                      )}
                      options={officeNames}
                      required
                      style={{
                        height: '45px',
                      }}
                      error={Boolean(errors.office_name)}
                      helperText={errors.office_name?.message.toString()}
                      isLoading={isLoadingOffices}
                    />
                  )}
                />
              </Grid>

              {selectedOfficeName && (
                <>
                  <Grid item xs={12} md={6}>
                    <Controller
                      name="agent_id"
                      control={control}
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          testId="agent-name-input"
                          label={t(
                            'partner-portal.application-templates.agent-name'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.agent-name-placeholder'
                          )}
                          options={agentNames}
                          style={{
                            height: '45px',
                          }}
                          infoText={t(
                            'partner-portal.application-templates.agent-name-tooltip'
                          )}
                          error={Boolean(errors.agent_id)}
                          helperText={errors.agent_id?.message.toString()}
                          isLoading={isLoadingAgents}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="business_category"
                      control={control}
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          testId="business-category-select-input"
                          label={t(
                            'partner-portal.application-templates.business-category'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.business-category-placeholder'
                          )}
                          options={businessCategories}
                          required
                          style={{
                            height: '45px',
                          }}
                          error={Boolean(errors.business_category)}
                          helperText={errors.business_category?.message.toString()}
                          isLoading={isLoadingBusinessTypes}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="business_type"
                      control={control}
                      render={({ field }) => (
                        <SelectComponent
                          {...field}
                          testId="business-type-select-input"
                          label={t(
                            'partner-portal.application-templates.business-type'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.business-type-placeholder'
                          )}
                          options={businessTypes}
                          required
                          style={{
                            height: '45px',
                          }}
                          error={Boolean(errors.business_type)}
                          helperText={errors.business_type?.message.toString()}
                          isLoading={isLoadingBusinessTypes}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={12} md={6}>
                    <Controller
                      name="business_description"
                      control={control}
                      render={({ field }) => (
                        <Input
                          {...field}
                          testId="products-or-services-description-input"
                          isTextArea
                          inputProps={{
                            style: { height: '132px' },
                            maxLength: 200,
                          }}
                          label={t(
                            'partner-portal.application-templates.products-or-services-description'
                          )}
                          placeholder={t(
                            'partner-portal.application-templates.products-or-services-description-placeholder'
                          )}
                          error={Boolean(errors.business_description)}
                          helperText={errors.business_description?.message.toString()}
                        />
                      )}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
        </Box>
      </PageLayoutContainer>

      <ActionModal
        open={preventFieldEditing?.isOpen}
        title={t(
          'partner-portal.application-template.template-has-products-attached'
        )}
        onClose={() =>
          setPreventFieldEditing({
            ...preventFieldEditing,
            isOpen: false,
          })
        }
        buttons={[
          {
            label: t('common.ok'),
            onClick: () =>
              setPreventFieldEditing({
                ...preventFieldEditing,
                isOpen: false,
              }),
            containerClassName: classes.buttonContainer,
          },
        ]}
        className={classes.modal}
      >
        <Box className={classes.textContainer}>
          <Typography>
            <Trans
              t={t}
              i18nKey="partner-portal.application-template.you-must-remove-attached-products"
              values={{
                field: t(
                  `partner-portal.application-templates.${
                    preventFieldEditing?.field === 'application_type_code'
                      ? 'application-type'
                      : 'cc-processor-platform'
                  }`
                ),
              }}
            />
          </Typography>
          <Typography>
            <Trans
              t={t}
              i18nKey="partner-portal.application-template.if-wish-to-proceed"
              values={{
                field: t(
                  `partner-portal.application-templates.${
                    preventFieldEditing?.field === 'application_type_code'
                      ? 'application-type'
                      : 'cc-processor-platform'
                  }`
                ),
              }}
            />
          </Typography>
        </Box>
      </ActionModal>
    </>
  )
}
