import { yupResolver } from '@hookform/resolvers/yup'
import { FC, useEffect, useState } from 'react'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Close } from '@mui/icons-material'
import { Box, Typography } from '@mui/material'

import { api, ApplicationTemplate, Equipment } from '@shared/api/src'
import { Button, UnsavedWarning } from '@shared/components'
import { PubSubEvent, useNotification, useSub } from '@shared/hooks'
import { nullifyEmptyStrings } from '@shared/utils'

import { ArrayFieldDirty } from '../product-form/constants/fieldsOptions'
import {
  CUSTOM_DIRTY_CHANGE,
  PRODUCT_FORM_MODEL_CHANGE,
  ProductForm,
  SelectedModel,
} from '../product-form/ProductForm'
import { AddProductData } from '../product-form/types/addProductData'
import { buildSchema } from '../product-form/utils/buildSchema'

const useStyles = tss.withName('AddProduct').create(({ theme }) => ({
  container: {
    position: 'relative',
    height: '100%',
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '24px',
    borderBottom: `2px solid #E5E7EB`,
  },
  closeIcon: {
    fontSize: '28px',
    fill: '#9CA3AF',
    ':hover': {
      cursor: 'pointer',
    },
  },
  footerContainer: {
    position: 'absolute',
    bottom: '0',
    right: '0',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    background: theme.palette['background'].paper,
    padding: '24px',
    borderTop: `2px solid #E5E7EB`,
  },
}))

interface EditProductProps {
  template: ApplicationTemplate
  product: Equipment
  currentProducts: Equipment[]
  onSuccess: (product: Equipment) => void
  onClose?: () => void
  currentErrors?: {
    [key: string]: string
  }
}

const CLOSE_EVENT_NAME = 'closeEditProductForm'

export const EditProduct: FC<EditProductProps> = ({
  template,
  product,
  currentProducts,
  onSuccess,
  onClose,
  currentErrors,
}) => {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { setNotification } = useNotification()

  const [selectedModel, setSelectedModel] = useState<SelectedModel | null>(null)
  const [customDirty, setCustomDirty] = useState<ArrayFieldDirty | null>(null)

  const [isErrorsSetted, setIsErrorsSetted] = useState(false)

  useSub<typeof PRODUCT_FORM_MODEL_CHANGE>(
    PRODUCT_FORM_MODEL_CHANGE.type,
    ({ data: model }: PubSubEvent<SelectedModel | null>) =>
      setSelectedModel(model)
  )

  useSub<typeof CUSTOM_DIRTY_CHANGE>(
    CUSTOM_DIRTY_CHANGE.type,
    ({ data: customDirty }: PubSubEvent<ArrayFieldDirty | null>) =>
      setCustomDirty(customDirty)
  )

  const schema = buildSchema(t, selectedModel, template, customDirty)

  const [isLoading, setIsLoading] = useState(false)

  const onSubmit: SubmitHandler<Equipment> = async (data) => {
    try {
      setIsLoading(true)
      const payload = nullifyEmptyStrings(data)
      delete payload.equipment_type_id

      const updatedProduct = await api.service('templates').updateEquipment(
        {
          templateId: template.template_id,
          equipmentId: product.equipment_id,
        },
        payload
      )

      setNotification({
        type: 'success',
        label: t(
          'partner-portal.application-templates.product-edited-successfully'
        ),
      })

      onSuccess(updatedProduct)
      methods.reset()
    } catch (error) {
      setNotification({
        type: 'error',
        label: error.message,
      })

      console.error(error)
    } finally {
      setIsLoading(false)
    }
  }

  const methods = useForm<AddProductData>({
    defaultValues: {
      conditional_settings: {},
    },
    resolver: yupResolver(schema),
    mode: 'onSubmit',
  })

  useEffect(() => {
    if (!product) {
      return
    }

    //API returns null for conditional settings that are not set, even if they are not valid for this product. We need to filter out the null values.
    const conditionalSettings = Object.entries(
      product.conditional_settings
    ).reduce((acc, [key, value]) => {
      if (value !== null) {
        acc[key] = value
      }

      return acc
    }, {})

    const data = {
      model_id: product.model_id,
      equipment_type_id: product.equipment_type_id,
      fe_platform_code: product.fe_platform_code,
      ownership_code: product.ownership_code,
      supplier: product.supplier,
      conditional_settings: conditionalSettings,
    }

    methods.reset(data)
  }, [product])

  useEffect(() => {
    if (currentErrors && selectedModel && !isErrorsSetted) {
      Object.keys(currentErrors).forEach((key) => {
        methods.setError(key as keyof AddProductData, {
          type: 'manual',
          message: currentErrors[key],
        })
      })
      setIsErrorsSetted(true)
    }
  }, [currentErrors, selectedModel])

  const handleClose = () => {
    onClose()
    methods.reset()
  }

  const closeEvent = () => {
    window.dispatchEvent(new Event(CLOSE_EVENT_NAME))
  }

  return (
    <FormProvider {...methods}>
      <UnsavedWarning
        customBlocker={{
          triggerEvent: CLOSE_EVENT_NAME,
          action: handleClose,
        }}
      />
      <Box className={classes.container}>
        <Box className={classes.headerContainer}>
          <Typography
            data-testid="edit-product-form-title"
            variant="h6"
            fontWeight={'bold'}
          >
            {t('partner-portal.application-templates.edit-product')}
          </Typography>

          <Close
            data-testid="edit-product-form-close-icon"
            className={classes.closeIcon}
            onClick={() => closeEvent()}
          />
        </Box>

        <ProductForm
          template={template}
          currentFrontend={
            currentProducts?.length > 1
              ? currentProducts[0].fe_platform_code
              : undefined
          }
          isEdit
        />

        <Box className={classes.footerContainer}>
          <Button
            testId="submit-edit-product-button"
            label={t('common.save-changes')}
            onClick={methods.handleSubmit(onSubmit)}
            isLoading={isLoading}
          />
        </Box>
      </Box>
    </FormProvider>
  )
}
