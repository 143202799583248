import { ApplicationTemplate, Equipment } from '@shared/api'

import { ProductProvider } from '../types/enums'

// Purpose
// -------------------------------
// Methods used to determine whether a Field should display on the
// Form or not, it helps to keep the UI, Form Schema, and Form Data
// to be up-to-date together about what to do with Fields.
//
// Roles
// -------------------------------
// UI                            =>  To conditionally render Fields.
// Form Schema                   =>  To setup Fields as Required / Not Required.
// Form Data                     =>  To clear Values / Errors of Fields when they unmount.
//
// Example when Rendering a Field
// -------------------------------
// canDisplayField.equipment_type_id  =>  Returns true.
// UI                            =>  Renders the equipment_type_id field.
// Form Schema                   =>  Sets the equipment_type_id Field as Required.
// Form Data                     =>  Saves updated equipment_type_id value.
//
// Example when Unmounting a Field
// -------------------------------
// canDisplayField.equipment_type_id  =>  Returns false.
// UI                            =>  Unmounts the equipment_type_id field.
// Form Schema                   =>  Sets the equipment_type_id Field as Not Required.
// Form Data                     =>  Clears Values / Errors of the equipment_type_id Field.

export const canDisplayField = (
  formData?: Partial<Equipment>,
  conditionalFields?: string[],
  applicationTemplate?: ApplicationTemplate
) => {
  const isVarSheetOnly =
    conditionalFields?.includes('var_pos_system') &&
    conditionalFields?.includes('software') &&
    conditionalFields?.includes('var_pos_name')

  return {
    equipment_type_id: true,
    model_id: Boolean(formData?.equipment_type_id),
    fe_platform_code:
      Boolean(formData?.model_id) &&
      applicationTemplate?.application_type_code !== 2,
    supplier: !isVarSheetOnly,
    ownership_code:
      !isVarSheetOnly &&
      Boolean(formData?.supplier) &&
      formData?.supplier !== ProductProvider.MERCHANT,
    conditional_settings: {
      var_pos_system: conditionalFields?.includes('var_pos_system'),
      var_pos_name:
        conditionalFields?.includes('var_pos_system') &&
        conditionalFields?.includes('var_pos_name'),
      software:
        conditionalFields?.includes('var_pos_system') &&
        conditionalFields?.includes('software'),
      is_integrated: conditionalFields?.includes('is_integrated'),
      integrated_system:
        conditionalFields?.includes('is_integrated') &&
        conditionalFields?.includes('integrated_system'),
      additional_settings: conditionalFields?.includes('additional_settings'),
      is_ordering: conditionalFields?.includes('is_ordering'),
      is_bravo_pos: conditionalFields?.includes('is_bravo_pos'),
      fluidpay_settings: conditionalFields?.includes('fluidpay_settings'),
      transaction_type_code: conditionalFields?.includes(
        'transaction_type_code'
      ),
      accepted_cards: conditionalFields?.includes('accepted_cards'),
      simple_swipe_setup_type_code: conditionalFields?.includes(
        'simple_swipe_setup_type_code'
      ),
    },
  }
}
